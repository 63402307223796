
export default function oddsboardReducer(state = {
	mode: "login",
	demo: false,
	gameData: undefined,
	oddsData: undefined,
	showPromo: false,

}, {type, payload}) {

	switch(type) {
		case 'SET_MODE': return payload.subscriptionType;
		default: return state;
	}
}