import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { setModalType } from '../../actions';
import './Contact.css';
import axios from 'axios';
import titanLogo from '../../images/odds-titan-logo.png';
// import utils from '../../utils';

export default props => {

  console.log(props)

  function getValue(id) {
    try {
      return document.getElementById(id).value;
    } catch(err) {
      return "";
    }
  }

  function clearForm() {
    const formIds = ["name", "email", "business", "message"];
    for (let id of formIds) {
      try {
        document.getElementById("oddsboard-contact-" + id).value = "";
      } catch(err) {

      }
    }
  }

  function showMessage() {
    try {
      document.getElementById("contact-form-response").classList.remove("hide")
    } catch(err) {

    }
  }

  function sendInquiry(e) {
    e.preventDefault();
    const values = {
      name: getValue("oddsboard-contact-name"),
      email: getValue("oddsboard-contact-email"),
      business: getValue("oddsboard-contact-business"),
      message: getValue("oddsboard-contact-message")
    }

    if (!values.name || !values.email || !values.message) {
      document.getElementById("contact-form-response").innerHTML = "Name, email, and a message are required";
      return showMessage();
    }

    console.log(values)
    // return
    axios.post('/contactSupport', {
      ...values
    }).then(async response => {
      console.log(response)
      // document.getElementById("oddsboard-contact-message").reset();
      document.getElementById("oddsboard-contact-message").value = "";
      document.getElementById("contact-form-response").innerHTML = "Success!";
      showMessage();
      setTimeout(() => props.history.push("/"), 5000);
    })
    .catch(err => {
      console.log(err)
      document.getElementById("contact-form").innerHTML = "Success!";
      showMessage();
    });
  }


  return <div className="contact-form">
    <div className="contact-form-header questions">{props.title}</div>
    <form id="contact-form">
      <label for="rapid-key">Your name</label><br></br>
      <input id="oddsboard-contact-name" type="text"></input>
      <label for="rapid-key">Email address</label><br></br>
      <input id="oddsboard-contact-email" type="text"></input>
      {!props.hideBusiness && <Fragment>
        <label for="rapid-key">Business/Organization (Optional)</label><br></br>
        <input id="oddsboard-contact-business" type="text"></input>
      </Fragment>}
      <label for="rapid-key">Message</label><br></br>
      <textarea id="oddsboard-contact-message"></textarea>
      <input type="submit" onClick={sendInquiry} value="Contact"></input>
      <div id="contact-form-response" className="hide">Success!</div>
    </form>
  </div>
}