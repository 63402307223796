const gameData = {
   "NBA":[
      {
         "schedule":{
            "date":"2021-05-08T01:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Denver Nuggets @ Utah Jazz",
         "details":{
            "league":"NBA",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":true,
            "divisionGame":true
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Denver Nuggets",
               "location":"Denver",
               "mascot":"Nuggets",
               "abbreviation":"DEN",
               "conference":"Western",
               "division":"Northwest",
               "teamId":1179
            },
            "home":{
               "team":"Utah Jazz",
               "location":"Utah",
               "mascot":"Jazz",
               "abbreviation":"UTA",
               "conference":"Western",
               "division":"Northwest",
               "teamId":1340
            }
         },
         "lastUpdated":"2021-05-08T00:54:26.891Z",
         "gameId":263425,
         "odds":{
            "composite":{
               "source":"Composite",
               "spread":{
                  "open":{
                     "away":3,
                     "home":-3,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":4,
                     "home":-4,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":128,
                     "homeOdds":-148
                  },
                  "current":{
                     "awayOdds":155,
                     "homeOdds":-175
                  }
               },
               "total":{
                  "open":{
                     "total":218.5,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":215,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T12:31:30.590Z",
               "lastUpdated":"2021-05-08T00:42:16.212Z"
            },
            "bovada":{
               "source":"Bovada",
               "spread":{
                  "open":{
                     "away":3,
                     "home":-3,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":4,
                     "home":-4,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":130,
                     "homeOdds":-150
                  },
                  "current":{
                     "awayOdds":155,
                     "homeOdds":-175
                  }
               },
               "total":{
                  "open":{
                     "total":218.5,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":217,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T12:31:30.584Z",
               "lastUpdated":"2021-05-08T00:42:16.212Z"
            },
            "intertops":{
               "source":"Intertops",
               "spread":{
                  "open":{
                     "away":3.5,
                     "home":-3.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":4,
                     "home":-4,
                     "awayOdds":-105,
                     "homeOdds":-115
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":160,
                     "homeOdds":-180
                  },
                  "current":{
                     "awayOdds":155,
                     "homeOdds":-175
                  }
               },
               "total":{
                  "open":{
                     "total":216.5,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":214.5,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T13:34:54.554Z",
               "lastUpdated":"2021-05-08T00:42:16.212Z"
            },
            "betonline":{
               "source":"BetOnline",
               "spread":{
                  "open":{
                     "away":3,
                     "home":-3,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":4.5,
                     "home":-4.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":155,
                     "homeOdds":-175
                  },
                  "current":{
                     "awayOdds":155,
                     "homeOdds":-175
                  }
               },
               "total":{
                  "open":{
                     "total":217.5,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":215,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T11:24:39.907Z",
               "lastUpdated":"2021-05-08T00:42:16.212Z"
            },
            "betnow":{
               "source":"BetNow",
               "spread":{
                  "open":{
                     "away":3,
                     "home":-3,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":4,
                     "home":-4,
                     "awayOdds":-105,
                     "homeOdds":-115
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":128,
                     "homeOdds":-148
                  },
                  "current":{
                     "awayOdds":155,
                     "homeOdds":-175
                  }
               },
               "total":{
                  "open":{
                     "total":218.5,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":214.5,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T12:31:30.584Z",
               "lastUpdated":"2021-05-08T00:42:16.212Z"
            },
            "sportsbetting":{
               "source":"SportsBetting",
               "spread":{
                  "open":{
                     "away":3,
                     "home":-3,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":4.5,
                     "home":-4.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":155,
                     "homeOdds":-175
                  },
                  "current":{
                     "awayOdds":155,
                     "homeOdds":-175
                  }
               },
               "total":{
                  "open":{
                     "total":217.5,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":215,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T11:24:39.907Z",
               "lastUpdated":"2021-05-08T00:42:16.212Z"
            }
         },
         "bestOdds":{
            "spread":{
               "away":{
                  "away":4.5,
                  "home":-4.5,
                  "awayOdds":-110,
                  "homeOdds":-110
               },
               "home":{
                  "away":4,
                  "home":-4,
                  "awayOdds":-110,
                  "homeOdds":-110
               }
            },
            "moneyline":{
               "away":{
                  "awayOdds":155,
                  "homeOdds":-175
               },
               "home":{
                  "awayOdds":155,
                  "homeOdds":-175
               }
            },
            "total":{
               "over":{
                  "total":214.5,
                  "overOdds":-110,
                  "underOdds":-110
               },
               "under":{
                  "total":217,
                  "overOdds":-110,
                  "underOdds":-110
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-08T02:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"New York Knicks @ Phoenix Suns",
         "details":{
            "league":"NBA",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":false,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"New York Knicks",
               "location":"New York",
               "mascot":"Knicks",
               "abbreviation":"NYK",
               "conference":"Eastern",
               "division":"Atlantic",
               "teamId":1255
            },
            "home":{
               "team":"Phoenix Suns",
               "location":"Phoenix",
               "mascot":"Suns",
               "abbreviation":"PHX",
               "conference":"Western",
               "division":"Pacific",
               "teamId":1102
            }
         },
         "lastUpdated":"2021-05-08T00:54:27.480Z",
         "gameId":263427,
         "odds":{
            "composite":{
               "source":"Composite",
               "spread":{
                  "open":{
                     "away":6,
                     "home":-6,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":6.5,
                     "home":-6.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":210,
                     "homeOdds":-250
                  },
                  "current":{
                     "awayOdds":223,
                     "homeOdds":-267
                  }
               },
               "total":{
                  "open":{
                     "total":218,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":217,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-06T20:03:25.665Z",
               "lastUpdated":"2021-05-08T00:42:17.114Z"
            },
            "bovada":{
               "source":"Bovada",
               "spread":{
                  "open":{
                     "away":6,
                     "home":-6,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":6.5,
                     "home":-6.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":210,
                     "homeOdds":-250
                  },
                  "current":{
                     "awayOdds":220,
                     "homeOdds":-270
                  }
               },
               "total":{
                  "open":{
                     "total":217.5,
                     "overOdds":-115,
                     "underOdds":-105
                  },
                  "current":{
                     "total":217,
                     "overOdds":-115,
                     "underOdds":-105
                  }
               },
               "openDate":"2021-05-06T20:28:47.899Z",
               "lastUpdated":"2021-05-08T00:42:17.114Z"
            },
            "intertops":{
               "source":"Intertops",
               "spread":{
                  "open":{
                     "away":7,
                     "home":-7,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":6.5,
                     "home":-6.5,
                     "awayOdds":-109,
                     "homeOdds":-111
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":245,
                     "homeOdds":-290
                  },
                  "current":{
                     "awayOdds":225,
                     "homeOdds":-265
                  }
               },
               "total":{
                  "open":{
                     "total":218,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":217,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T13:34:55.344Z",
               "lastUpdated":"2021-05-08T00:42:17.114Z"
            },
            "betonline":{
               "source":"BetOnline",
               "spread":{
                  "open":{
                     "away":8,
                     "home":-8,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":6.5,
                     "home":-6.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":240,
                     "homeOdds":-285
                  },
                  "current":{
                     "awayOdds":220,
                     "homeOdds":-270
                  }
               },
               "total":{
                  "open":{
                     "total":218,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":217,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-06T17:26:51.581Z",
               "lastUpdated":"2021-05-08T00:42:17.114Z"
            },
            "betnow":{
               "source":"BetNow",
               "spread":{
                  "open":{
                     "away":6,
                     "home":-6,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":6.5,
                     "home":-6.5,
                     "awayOdds":-109,
                     "homeOdds":-111
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":210,
                     "homeOdds":-250
                  },
                  "current":{
                     "awayOdds":225,
                     "homeOdds":-265
                  }
               },
               "total":{
                  "open":{
                     "total":217.5,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":217,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-06T20:03:25.660Z",
               "lastUpdated":"2021-05-08T00:42:17.114Z"
            },
            "sportsbetting":{
               "source":"SportsBetting",
               "spread":{
                  "open":{
                     "away":8,
                     "home":-8,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":6.5,
                     "home":-6.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":240,
                     "homeOdds":-285
                  },
                  "current":{
                     "awayOdds":225,
                     "homeOdds":-265
                  }
               },
               "total":{
                  "open":{
                     "total":218,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":217,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-06T17:26:51.581Z",
               "lastUpdated":"2021-05-08T00:42:17.114Z"
            }
         },
         "bestOdds":{
            "spread":{
               "away":{
                  "away":6.5,
                  "home":-6.5,
                  "awayOdds":-109,
                  "homeOdds":-111
               },
               "home":{
                  "away":6.5,
                  "home":-6.5,
                  "awayOdds":-110,
                  "homeOdds":-110
               }
            },
            "moneyline":{
               "away":{
                  "awayOdds":225,
                  "homeOdds":-265
               },
               "home":{
                  "awayOdds":225,
                  "homeOdds":-265
               }
            },
            "total":{
               "over":{
                  "total":217,
                  "overOdds":-110,
                  "underOdds":-110
               },
               "under":{
                  "total":217,
                  "overOdds":-115,
                  "underOdds":-105
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-08T02:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Los Angeles Lakers @ Portland Trail Blazers",
         "details":{
            "league":"NBA",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":true,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Los Angeles Lakers",
               "location":"Los Angeles",
               "mascot":"Lakers",
               "abbreviation":"LAL",
               "conference":"Western",
               "division":"Pacific",
               "teamId":1180
            },
            "home":{
               "team":"Portland Trail Blazers",
               "location":"Portland",
               "mascot":"Trail Blazers",
               "abbreviation":"POR",
               "conference":"Western",
               "division":"Northwest",
               "teamId":1256
            }
         },
         "lastUpdated":"2021-05-08T00:54:27.276Z",
         "gameId":263429,
         "odds":{
            "composite":{
               "source":"Composite",
               "spread":{
                  "open":{
                     "away":7,
                     "home":-7,
                     "awayOdds":-110,
                     "homeOdds":-115
                  },
                  "current":{
                     "away":8,
                     "home":-8,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":235,
                     "homeOdds":-275
                  },
                  "current":{
                     "awayOdds":284,
                     "homeOdds":-362
                  }
               },
               "total":{
                  "open":{
                     "total":222,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":223,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T12:31:30.854Z",
               "lastUpdated":"2021-05-08T00:42:16.531Z"
            },
            "bovada":{
               "source":"Bovada",
               "spread":{
                  "open":{
                     "away":7,
                     "home":-7,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":8.5,
                     "home":-8.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":230,
                     "homeOdds":-280
                  },
                  "current":{
                     "awayOdds":300,
                     "homeOdds":-400
                  }
               },
               "total":{
                  "open":{
                     "total":221.5,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":221.5,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T12:31:30.846Z",
               "lastUpdated":"2021-05-08T00:42:16.531Z"
            },
            "intertops":{
               "source":"Intertops",
               "spread":{
                  "open":{
                     "away":8,
                     "home":-8,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":8,
                     "home":-8,
                     "awayOdds":-108,
                     "homeOdds":-112
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":315,
                     "homeOdds":-400
                  },
                  "current":{
                     "awayOdds":280,
                     "homeOdds":-350
                  }
               },
               "total":{
                  "open":{
                     "total":222,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":223,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T13:48:08.561Z",
               "lastUpdated":"2021-05-08T00:42:16.531Z"
            },
            "betonline":{
               "source":"BetOnline",
               "spread":{
                  "open":{
                     "away":7,
                     "home":-7,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":8,
                     "home":-8,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":320,
                     "homeOdds":-400
                  },
                  "current":{
                     "awayOdds":280,
                     "homeOdds":-360
                  }
               },
               "total":{
                  "open":{
                     "total":222,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":223,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T11:33:52.038Z",
               "lastUpdated":"2021-05-08T00:42:16.531Z"
            },
            "betnow":{
               "source":"BetNow",
               "spread":{
                  "open":{
                     "away":7,
                     "home":-7,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":8,
                     "home":-8,
                     "awayOdds":-108,
                     "homeOdds":-112
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":235,
                     "homeOdds":-275
                  },
                  "current":{
                     "awayOdds":280,
                     "homeOdds":-350
                  }
               },
               "total":{
                  "open":{
                     "total":222,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":223,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T12:31:30.846Z",
               "lastUpdated":"2021-05-08T00:42:16.531Z"
            },
            "sportsbetting":{
               "source":"SportsBetting",
               "spread":{
                  "open":{
                     "away":7,
                     "home":-7,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":8,
                     "home":-8,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":320,
                     "homeOdds":-400
                  },
                  "current":{
                     "awayOdds":280,
                     "homeOdds":-350
                  }
               },
               "total":{
                  "open":{
                     "total":222,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":223,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T11:33:52.038Z",
               "lastUpdated":"2021-05-08T00:42:16.531Z"
            }
         },
         "bestOdds":{
            "spread":{
               "away":{
                  "away":8.5,
                  "home":-8.5,
                  "awayOdds":-110,
                  "homeOdds":-110
               },
               "home":{
                  "away":8,
                  "home":-8,
                  "awayOdds":-110,
                  "homeOdds":-110
               }
            },
            "moneyline":{
               "away":{
                  "awayOdds":300,
                  "homeOdds":-400
               },
               "home":{
                  "awayOdds":280,
                  "homeOdds":-350
               }
            },
            "total":{
               "over":{
                  "total":221.5,
                  "overOdds":-110,
                  "underOdds":-110
               },
               "under":{
                  "total":223,
                  "overOdds":-110,
                  "underOdds":-110
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-08T02:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"San Antonio Spurs @ Sacramento Kings",
         "details":{
            "league":"NBA",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":true,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"San Antonio Spurs",
               "location":"San Antonio",
               "mascot":"Spurs",
               "abbreviation":"SAS",
               "conference":"Western",
               "division":"Southwest",
               "teamId":1103
            },
            "home":{
               "team":"Sacramento Kings",
               "location":"Sacramento",
               "mascot":"Kings",
               "abbreviation":"SAC",
               "conference":"Western",
               "division":"Pacific",
               "teamId":1026
            }
         },
         "lastUpdated":"2021-05-08T00:54:27.082Z",
         "gameId":263431,
         "odds":{
            "composite":{
               "source":"Composite",
               "spread":{
                  "open":{
                     "away":-3,
                     "home":3,
                     "awayOdds":-115,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":-4.5,
                     "home":4.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-148,
                     "homeOdds":128
                  },
                  "current":{
                     "awayOdds":-188,
                     "homeOdds":164
                  }
               },
               "total":{
                  "open":{
                     "total":227,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":223.5,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T12:31:31.124Z",
               "lastUpdated":"2021-05-08T00:42:16.823Z"
            },
            "bovada":{
               "source":"Bovada",
               "spread":{
                  "open":{
                     "away":-3,
                     "home":3,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":-4.5,
                     "home":4.5,
                     "awayOdds":-105,
                     "homeOdds":-115
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-160,
                     "homeOdds":140
                  },
                  "current":{
                     "awayOdds":-180,
                     "homeOdds":160
                  }
               },
               "total":{
                  "open":{
                     "total":227,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":224.5,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T12:31:31.115Z",
               "lastUpdated":"2021-05-08T00:42:16.823Z"
            },
            "intertops":{
               "source":"Intertops",
               "spread":{
                  "open":{
                     "away":-4.5,
                     "home":4.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":-4.5,
                     "home":4.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-200,
                     "homeOdds":170
                  },
                  "current":{
                     "awayOdds":-190,
                     "homeOdds":165
                  }
               },
               "total":{
                  "open":{
                     "total":226,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":223.5,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T13:48:08.849Z",
               "lastUpdated":"2021-05-08T00:42:16.823Z"
            },
            "betonline":{
               "source":"BetOnline",
               "spread":{
                  "open":{
                     "away":-2.5,
                     "home":2.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":-4.5,
                     "home":4.5,
                     "awayOdds":-115,
                     "homeOdds":-105
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-190,
                     "homeOdds":165
                  },
                  "current":{
                     "awayOdds":-190,
                     "homeOdds":165
                  }
               },
               "total":{
                  "open":{
                     "total":226.5,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":223.5,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T11:24:40.144Z",
               "lastUpdated":"2021-05-08T00:42:16.823Z"
            },
            "betnow":{
               "source":"BetNow",
               "spread":{
                  "open":{
                     "away":-3,
                     "home":3,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":-4.5,
                     "home":4.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-148,
                     "homeOdds":128
                  },
                  "current":{
                     "awayOdds":-190,
                     "homeOdds":165
                  }
               },
               "total":{
                  "open":{
                     "total":227,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":223.5,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T12:31:31.115Z",
               "lastUpdated":"2021-05-08T00:42:16.823Z"
            },
            "sportsbetting":{
               "source":"SportsBetting",
               "spread":{
                  "open":{
                     "away":-2.5,
                     "home":2.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":-4.5,
                     "home":4.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-190,
                     "homeOdds":165
                  },
                  "current":{
                     "awayOdds":-190,
                     "homeOdds":165
                  }
               },
               "total":{
                  "open":{
                     "total":226.5,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":223.5,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T11:24:40.144Z",
               "lastUpdated":"2021-05-08T00:42:16.823Z"
            }
         },
         "bestOdds":{
            "spread":{
               "away":{
                  "away":-4.5,
                  "home":4.5,
                  "awayOdds":-105,
                  "homeOdds":-115
               },
               "home":{
                  "away":-4.5,
                  "home":4.5,
                  "awayOdds":-115,
                  "homeOdds":-105
               }
            },
            "moneyline":{
               "away":{
                  "awayOdds":-180,
                  "homeOdds":160
               },
               "home":{
                  "awayOdds":-190,
                  "homeOdds":165
               }
            },
            "total":{
               "over":{
                  "total":223.5,
                  "overOdds":-110,
                  "underOdds":-110
               },
               "under":{
                  "total":224.5,
                  "overOdds":-110,
                  "underOdds":-110
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-08T23:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Washington Wizards @ Indiana Pacers",
         "details":{
            "league":"NBA",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":true,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Washington Wizards",
               "location":"Washington",
               "mascot":"Wizards",
               "abbreviation":"WAS",
               "conference":"Eastern",
               "division":"Southeast",
               "teamId":1257
            },
            "home":{
               "team":"Indiana Pacers",
               "location":"Indiana",
               "mascot":"Pacers",
               "abbreviation":"IND",
               "conference":"Eastern",
               "division":"Central",
               "teamId":1023
            }
         },
         "lastUpdated":"2021-05-08T00:10:53.802Z",
         "gameId":263426
      },
      {
         "schedule":{
            "date":"2021-05-08T23:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Detroit Pistons @ Philadelphia 76ers",
         "details":{
            "league":"NBA",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":true,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Detroit Pistons",
               "location":"Detroit",
               "mascot":"Pistons",
               "abbreviation":"DET",
               "conference":"Eastern",
               "division":"Central",
               "teamId":1099
            },
            "home":{
               "team":"Philadelphia 76ers",
               "location":"Philadelphia",
               "mascot":"76ers",
               "abbreviation":"PHI",
               "conference":"Eastern",
               "division":"Atlantic",
               "teamId":1182
            }
         },
         "lastUpdated":"2021-05-08T00:10:53.611Z",
         "gameId":263428
      },
      {
         "schedule":{
            "date":"2021-05-08T23:30:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Memphis Grizzlies @ Toronto Raptors",
         "details":{
            "league":"NBA",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":false,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Memphis Grizzlies",
               "location":"Memphis",
               "mascot":"Grizzlies",
               "abbreviation":"MEM",
               "conference":"Western",
               "division":"Southwest",
               "teamId":1254
            },
            "home":{
               "team":"Toronto Raptors",
               "location":"Toronto",
               "mascot":"Raptors",
               "abbreviation":"TOR",
               "conference":"Eastern",
               "division":"Atlantic",
               "teamId":1183
            }
         },
         "lastUpdated":"2021-05-08T00:10:53.993Z",
         "gameId":263424
      },
      {
         "schedule":{
            "date":"2021-05-09T02:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Brooklyn Nets @ Denver Nuggets",
         "details":{
            "league":"NBA",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":false,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Brooklyn Nets",
               "location":"Brooklyn",
               "mascot":"Nets",
               "abbreviation":"BKN",
               "conference":"Eastern",
               "division":"Atlantic",
               "teamId":1098
            },
            "home":{
               "team":"Denver Nuggets",
               "location":"Denver",
               "mascot":"Nuggets",
               "abbreviation":"DEN",
               "conference":"Western",
               "division":"Northwest",
               "teamId":1179
            }
         },
         "lastUpdated":"2021-05-08T00:10:54.783Z",
         "gameId":263430,
         "odds":{
            "composite":{
               "source":"Composite",
               "spread":{
                  "open":{
                     "away":-3,
                     "home":3,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":-3,
                     "home":3,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "total":{
                  "open":{
                     "total":230.5,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":230.5,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T22:36:14.609Z",
               "lastUpdated":"2021-05-08T00:42:17.661Z"
            },
            "intertops":{
               "source":"Intertops",
               "spread":{
                  "open":{
                     "away":-3.5,
                     "home":3.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":-3,
                     "home":3,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "total":{
                  "open":{
                     "total":232,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":230.5,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T17:42:19.609Z",
               "lastUpdated":"2021-05-08T00:42:17.661Z"
            },
            "betonline":{
               "source":"BetOnline",
               "spread":{
                  "open":{
                     "away":-3,
                     "home":3,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":-3,
                     "home":3,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "total":{
                  "open":{
                     "total":231,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":231,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T22:36:14.603Z",
               "lastUpdated":"2021-05-08T00:42:17.661Z"
            },
            "betnow":{
               "source":"BetNow",
               "spread":{
                  "open":{
                     "away":-3.5,
                     "home":3.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":-3,
                     "home":3,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "total":{
                  "open":{
                     "total":232,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":230.5,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T17:42:19.609Z",
               "lastUpdated":"2021-05-08T00:42:17.661Z"
            }
         },
         "bestOdds":{
            "spread":{
               "away":{
                  "away":-3,
                  "home":3,
                  "awayOdds":-110,
                  "homeOdds":-110
               },
               "home":{
                  "away":-3,
                  "home":3,
                  "awayOdds":-110,
                  "homeOdds":-110
               }
            },
            "moneyline":{
               
            },
            "total":{
               "over":{
                  "total":230.5,
                  "overOdds":-110,
                  "underOdds":-110
               },
               "under":{
                  "total":231,
                  "overOdds":-110,
                  "underOdds":-110
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-09T02:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Houston Rockets @ Utah Jazz",
         "details":{
            "league":"NBA",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":true,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Houston Rockets",
               "location":"Houston",
               "mascot":"Rockets",
               "abbreviation":"HOU",
               "conference":"Western",
               "division":"Southwest",
               "teamId":1337
            },
            "home":{
               "team":"Utah Jazz",
               "location":"Utah",
               "mascot":"Jazz",
               "abbreviation":"UTA",
               "conference":"Western",
               "division":"Northwest",
               "teamId":1340
            }
         },
         "lastUpdated":"2021-05-08T00:10:54.582Z",
         "gameId":263432
      },
      {
         "schedule":{
            "date":"2021-05-09T02:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Oklahoma City Thunder @ Golden State Warriors",
         "details":{
            "league":"NBA",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":true,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Oklahoma City Thunder",
               "location":"Oklahoma City",
               "mascot":"Thunder",
               "abbreviation":"OKC",
               "conference":"Western",
               "division":"Northwest",
               "teamId":1339
            },
            "home":{
               "team":"Golden State Warriors",
               "location":"Golden State",
               "mascot":"Warriors",
               "abbreviation":"GSW",
               "conference":"Western",
               "division":"Pacific",
               "teamId":1253
            }
         },
         "lastUpdated":"2021-05-08T00:10:54.385Z",
         "gameId":263433,
         "odds":{
            "composite":{
               "source":"Composite",
               "spread":{
                  "open":{
                     "away":15,
                     "home":-15,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":15,
                     "home":-15,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "total":{
                  "open":{
                     "total":224.5,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":223,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T22:36:14.345Z",
               "lastUpdated":"2021-05-08T00:42:17.397Z"
            },
            "intertops":{
               "source":"Intertops",
               "spread":{
                  "open":{
                     "away":15,
                     "home":-15,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":15,
                     "home":-15,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "total":{
                  "open":{
                     "total":224.5,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":223,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T17:42:19.363Z",
               "lastUpdated":"2021-05-08T00:42:17.397Z"
            },
            "betonline":{
               "source":"BetOnline",
               "spread":{
                  "open":{
                     "away":14.5,
                     "home":-14.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":14.5,
                     "home":-14.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "total":{
                  "open":{
                     "total":224,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":223.5,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T22:36:14.340Z",
               "lastUpdated":"2021-05-08T00:42:17.397Z"
            },
            "betnow":{
               "source":"BetNow",
               "spread":{
                  "open":{
                     "away":15,
                     "home":-15,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":15,
                     "home":-15,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "total":{
                  "open":{
                     "total":224.5,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":223,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T17:42:19.363Z",
               "lastUpdated":"2021-05-08T00:42:17.397Z"
            }
         },
         "bestOdds":{
            "spread":{
               "away":{
                  "away":15,
                  "home":-15,
                  "awayOdds":-110,
                  "homeOdds":-110
               },
               "home":{
                  "away":14.5,
                  "home":-14.5,
                  "awayOdds":-110,
                  "homeOdds":-110
               }
            },
            "moneyline":{
               
            },
            "total":{
               "over":{
                  "total":223,
                  "overOdds":-110,
                  "underOdds":-110
               },
               "under":{
                  "total":223.5,
                  "overOdds":-110,
                  "underOdds":-110
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-09T02:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"San Antonio Spurs @ Portland Trail Blazers",
         "details":{
            "league":"NBA",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":true,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"San Antonio Spurs",
               "location":"San Antonio",
               "mascot":"Spurs",
               "abbreviation":"SAS",
               "conference":"Western",
               "division":"Southwest",
               "teamId":1103
            },
            "home":{
               "team":"Portland Trail Blazers",
               "location":"Portland",
               "mascot":"Trail Blazers",
               "abbreviation":"POR",
               "conference":"Western",
               "division":"Northwest",
               "teamId":1256
            }
         },
         "lastUpdated":"2021-05-08T00:10:54.186Z",
         "gameId":263434
      },
      {
         "schedule":{
            "date":"2021-05-09T17:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Miami Heat @ Boston Celtics",
         "details":{
            "league":"NBA",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":true,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Miami Heat",
               "location":"Miami",
               "mascot":"Heat",
               "abbreviation":"MIA",
               "conference":"Eastern",
               "division":"Southeast",
               "teamId":1338
            },
            "home":{
               "team":"Boston Celtics",
               "location":"Boston",
               "mascot":"Celtics",
               "abbreviation":"BOS",
               "conference":"Eastern",
               "division":"Atlantic",
               "teamId":1021
            }
         },
         "lastUpdated":"2021-05-08T00:10:58.215Z",
         "gameId":263435
      },
      {
         "schedule":{
            "date":"2021-05-09T19:30:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"New York Knicks @ Los Angeles Clippers",
         "details":{
            "league":"NBA",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":false,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"New York Knicks",
               "location":"New York",
               "mascot":"Knicks",
               "abbreviation":"NYK",
               "conference":"Eastern",
               "division":"Atlantic",
               "teamId":1255
            },
            "home":{
               "team":"Los Angeles Clippers",
               "location":"Los Angeles",
               "mascot":"Clippers",
               "abbreviation":"LAC",
               "conference":"Western",
               "division":"Pacific",
               "teamId":1100
            }
         },
         "lastUpdated":"2021-05-08T00:10:58.425Z",
         "gameId":263436
      },
      {
         "schedule":{
            "date":"2021-05-09T23:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"New Orleans Pelicans @ Charlotte Hornets",
         "details":{
            "league":"NBA",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":false,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"New Orleans Pelicans",
               "location":"New Orleans",
               "mascot":"Pelicans",
               "abbreviation":"NOP",
               "conference":"Western",
               "division":"Southwest",
               "teamId":1181
            },
            "home":{
               "team":"Charlotte Hornets",
               "location":"Charlotte",
               "mascot":"Hornets",
               "abbreviation":"CHA",
               "conference":"Eastern",
               "division":"Southeast",
               "teamId":1178
            }
         },
         "lastUpdated":"2021-05-08T00:10:59.001Z",
         "gameId":263437
      },
      {
         "schedule":{
            "date":"2021-05-09T23:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Dallas Mavericks @ Cleveland Cavaliers",
         "details":{
            "league":"NBA",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":false,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Dallas Mavericks",
               "location":"Dallas",
               "mascot":"Mavericks",
               "abbreviation":"DAL",
               "conference":"Western",
               "division":"Southwest",
               "teamId":1022
            },
            "home":{
               "team":"Cleveland Cavaliers",
               "location":"Cleveland",
               "mascot":"Cavaliers",
               "abbreviation":"CLE",
               "conference":"Eastern",
               "division":"Central",
               "teamId":1336
            }
         },
         "lastUpdated":"2021-05-08T00:10:58.802Z",
         "gameId":263438
      },
      {
         "schedule":{
            "date":"2021-05-09T23:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Minnesota Timberwolves @ Orlando Magic",
         "details":{
            "league":"NBA",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":false,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Minnesota Timberwolves",
               "location":"Minnesota",
               "mascot":"Timberwolves",
               "abbreviation":"MIN",
               "conference":"Western",
               "division":"Northwest",
               "teamId":1101
            },
            "home":{
               "team":"Orlando Magic",
               "location":"Orlando",
               "mascot":"Magic",
               "abbreviation":"ORL",
               "conference":"Eastern",
               "division":"Southeast",
               "teamId":1025
            }
         },
         "lastUpdated":"2021-05-08T00:10:58.610Z",
         "gameId":263439
      },
      {
         "schedule":{
            "date":"2021-05-10T00:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Chicago Bulls @ Detroit Pistons",
         "details":{
            "league":"NBA",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":true,
            "divisionGame":true
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Chicago Bulls",
               "location":"Chicago",
               "mascot":"Bulls",
               "abbreviation":"CHI",
               "conference":"Eastern",
               "division":"Central",
               "teamId":1252
            },
            "home":{
               "team":"Detroit Pistons",
               "location":"Detroit",
               "mascot":"Pistons",
               "abbreviation":"DET",
               "conference":"Eastern",
               "division":"Central",
               "teamId":1099
            }
         },
         "lastUpdated":"2021-05-08T00:10:59.192Z",
         "gameId":263440
      }
   ],
   "MLB":[
      {
         "schedule":{
            "date":"2021-05-08T01:38:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Los Angeles Dodgers @ Los Angeles Angels",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":false,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Los Angeles Dodgers",
               "location":"Los Angeles",
               "mascot":"Dodgers",
               "abbreviation":"LAD",
               "conference":"National League",
               "division":"West",
               "teamId":1187
            },
            "home":{
               "team":"Los Angeles Angels",
               "location":"Los Angeles",
               "mascot":"Angels",
               "abbreviation":"LAA",
               "conference":"American League",
               "division":"West",
               "teamId":1107
            }
         },
         "lastUpdated":"2021-05-08T00:53:31.261Z",
         "gameId":260601,
         "odds":{
            "composite":{
               "source":"Composite",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-105,
                     "homeOdds":-115
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-153,
                     "homeOdds":139
                  },
                  "current":{
                     "awayOdds":-168,
                     "homeOdds":151
                  }
               },
               "total":{
                  "open":{
                     "total":8.5,
                     "overOdds":-125,
                     "underOdds":105
                  },
                  "current":{
                     "total":8.5,
                     "overOdds":-120,
                     "underOdds":100
                  }
               },
               "openDate":"2021-05-06T20:24:52.031Z",
               "lastUpdated":"2021-05-08T00:53:30.195Z"
            },
            "bovada":{
               "source":"Bovada",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-105,
                     "homeOdds":-115
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-160,
                     "homeOdds":140
                  },
                  "current":{
                     "awayOdds":-166,
                     "homeOdds":143
                  }
               },
               "total":{
                  "open":{
                     "total":8.5,
                     "overOdds":-120,
                     "underOdds":100
                  },
                  "current":{
                     "total":8.5,
                     "overOdds":-120,
                     "underOdds":100
                  }
               },
               "openDate":"2021-05-06T20:24:52.026Z",
               "lastUpdated":"2021-05-08T00:53:30.195Z"
            },
            "intertops":{
               "source":"Intertops",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-105,
                     "homeOdds":-115
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-160,
                     "homeOdds":145
                  },
                  "current":{
                     "awayOdds":-167,
                     "homeOdds":154
                  }
               },
               "total":{
                  "open":{
                     "total":8.5,
                     "overOdds":-115,
                     "underOdds":-105
                  },
                  "current":{
                     "total":8.5,
                     "overOdds":-121,
                     "underOdds":101
                  }
               },
               "openDate":"2021-05-07T05:01:01.364Z",
               "lastUpdated":"2021-05-08T00:53:30.195Z"
            },
            "betonline":{
               "source":"BetOnline",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-105,
                     "homeOdds":-115
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-115,
                     "homeOdds":-105
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-150,
                     "homeOdds":139
                  },
                  "current":{
                     "awayOdds":-175,
                     "homeOdds":150
                  }
               },
               "total":{
                  "open":{
                     "total":8.5,
                     "overOdds":-125,
                     "underOdds":105
                  },
                  "current":{
                     "total":8.5,
                     "overOdds":-125,
                     "underOdds":105
                  }
               },
               "openDate":"2021-05-06T20:24:52.026Z",
               "lastUpdated":"2021-05-08T00:53:30.195Z"
            },
            "betnow":{
               "source":"BetNow",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-105,
                     "homeOdds":-115
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-158,
                     "homeOdds":148
                  },
                  "current":{
                     "awayOdds":-167,
                     "homeOdds":154
                  }
               },
               "total":{
                  "open":{
                     "total":8.5,
                     "overOdds":-120,
                     "underOdds":100
                  },
                  "current":{
                     "total":8.5,
                     "overOdds":-121,
                     "underOdds":101
                  }
               },
               "openDate":"2021-05-06T21:04:11.216Z",
               "lastUpdated":"2021-05-08T00:53:30.195Z"
            },
            "sportsbetting":{
               "source":"SportsBetting",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-105,
                     "homeOdds":-115
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-150,
                     "homeOdds":139
                  },
                  "current":{
                     "awayOdds":-168,
                     "homeOdds":153
                  }
               },
               "total":{
                  "open":{
                     "total":8.5,
                     "overOdds":-125,
                     "underOdds":105
                  },
                  "current":{
                     "total":8.5,
                     "overOdds":-120,
                     "underOdds":100
                  }
               },
               "openDate":"2021-05-06T20:24:52.026Z",
               "lastUpdated":"2021-05-08T00:53:30.195Z"
            }
         },
         "bestOdds":{
            "spread":{
               "away":{
                  "away":-1.5,
                  "home":1.5,
                  "awayOdds":-110,
                  "homeOdds":-110
               },
               "home":{
                  "away":-1.5,
                  "home":1.5,
                  "awayOdds":-115,
                  "homeOdds":-105
               }
            },
            "moneyline":{
               "away":{
                  "awayOdds":-166,
                  "homeOdds":143
               },
               "home":{
                  "awayOdds":-167,
                  "homeOdds":154
               }
            },
            "total":{
               "over":{
                  "total":8.5,
                  "overOdds":-120,
                  "underOdds":100
               },
               "under":{
                  "total":8.5,
                  "overOdds":-125,
                  "underOdds":105
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-08T01:40:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Tampa Bay Rays @ Oakland Athletics",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":true,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Tampa Bay Rays",
               "location":"Tampa Bay",
               "mascot":"Rays",
               "abbreviation":"TB",
               "conference":"American League",
               "division":"East",
               "teamId":1033
            },
            "home":{
               "team":"Oakland Athletics",
               "location":"Oakland",
               "mascot":"Athletics",
               "abbreviation":"OAK",
               "conference":"American League",
               "division":"West",
               "teamId":1262
            }
         },
         "lastUpdated":"2021-05-08T00:53:31.475Z",
         "gameId":260603,
         "odds":{
            "composite":{
               "source":"Composite",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-160,
                     "homeOdds":140
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-185,
                     "homeOdds":160
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":125,
                     "homeOdds":-138
                  },
                  "current":{
                     "awayOdds":113,
                     "homeOdds":-128
                  }
               },
               "total":{
                  "open":{
                     "total":8,
                     "overOdds":-115,
                     "underOdds":-105
                  },
                  "current":{
                     "total":7.5,
                     "overOdds":-105,
                     "underOdds":-115
                  }
               },
               "openDate":"2021-05-06T20:14:36.160Z",
               "lastUpdated":"2021-05-08T00:53:30.492Z"
            },
            "bovada":{
               "source":"Bovada",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-160,
                     "homeOdds":140
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-170,
                     "homeOdds":150
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":120,
                     "homeOdds":-140
                  },
                  "current":{
                     "awayOdds":110,
                     "homeOdds":-130
                  }
               },
               "total":{
                  "open":{
                     "total":8,
                     "overOdds":-115,
                     "underOdds":-105
                  },
                  "current":{
                     "total":7.5,
                     "overOdds":-105,
                     "underOdds":-115
                  }
               },
               "openDate":"2021-05-06T20:14:36.155Z",
               "lastUpdated":"2021-05-08T00:53:30.492Z"
            },
            "intertops":{
               "source":"Intertops",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-165,
                     "homeOdds":145
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-177,
                     "homeOdds":157
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":125,
                     "homeOdds":-135
                  },
                  "current":{
                     "awayOdds":116,
                     "homeOdds":-126
                  }
               },
               "total":{
                  "open":{
                     "total":7.5,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":7.5,
                     "overOdds":102,
                     "underOdds":-122
                  }
               },
               "openDate":"2021-05-07T05:01:01.652Z",
               "lastUpdated":"2021-05-08T00:53:30.492Z"
            },
            "betonline":{
               "source":"BetOnline",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-160,
                     "homeOdds":140
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-190,
                     "homeOdds":165
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":127,
                     "homeOdds":-137
                  },
                  "current":{
                     "awayOdds":110,
                     "homeOdds":-130
                  }
               },
               "total":{
                  "open":{
                     "total":8,
                     "overOdds":-115,
                     "underOdds":-105
                  },
                  "current":{
                     "total":7.5,
                     "overOdds":-105,
                     "underOdds":-115
                  }
               },
               "openDate":"2021-05-06T20:14:36.155Z",
               "lastUpdated":"2021-05-08T00:53:30.492Z"
            },
            "betnow":{
               "source":"BetNow",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-160,
                     "homeOdds":140
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-177,
                     "homeOdds":157
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":127,
                     "homeOdds":-137
                  },
                  "current":{
                     "awayOdds":116,
                     "homeOdds":-126
                  }
               },
               "total":{
                  "open":{
                     "total":8,
                     "overOdds":-115,
                     "underOdds":-105
                  },
                  "current":{
                     "total":7.5,
                     "overOdds":102,
                     "underOdds":-122
                  }
               },
               "openDate":"2021-05-06T21:04:11.490Z",
               "lastUpdated":"2021-05-08T00:53:30.492Z"
            },
            "sportsbetting":{
               "source":"SportsBetting",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-160,
                     "homeOdds":140
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-190,
                     "homeOdds":165
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":127,
                     "homeOdds":-137
                  },
                  "current":{
                     "awayOdds":117,
                     "homeOdds":-127
                  }
               },
               "total":{
                  "open":{
                     "total":8,
                     "overOdds":-115,
                     "underOdds":-105
                  },
                  "current":{
                     "total":7.5,
                     "overOdds":-105,
                     "underOdds":-115
                  }
               },
               "openDate":"2021-05-06T20:14:36.155Z",
               "lastUpdated":"2021-05-08T00:53:30.492Z"
            }
         },
         "bestOdds":{
            "spread":{
               "away":{
                  "away":1.5,
                  "home":-1.5,
                  "awayOdds":-170,
                  "homeOdds":150
               },
               "home":{
                  "away":1.5,
                  "home":-1.5,
                  "awayOdds":-190,
                  "homeOdds":165
               }
            },
            "moneyline":{
               "away":{
                  "awayOdds":117,
                  "homeOdds":-127
               },
               "home":{
                  "awayOdds":116,
                  "homeOdds":-126
               }
            },
            "total":{
               "over":{
                  "total":7.5,
                  "overOdds":102,
                  "underOdds":-122
               },
               "under":{
                  "total":7.5,
                  "overOdds":-105,
                  "underOdds":-115
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-08T01:45:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"San Diego Padres @ San Francisco Giants",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":true,
            "divisionGame":true
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"San Diego Padres",
               "location":"San Diego",
               "mascot":"Padres",
               "abbreviation":"SD",
               "conference":"National League",
               "division":"West",
               "teamId":1189
            },
            "home":{
               "team":"San Francisco Giants",
               "location":"San Francisco",
               "mascot":"Giants",
               "abbreviation":"SF",
               "conference":"National League",
               "division":"West",
               "teamId":1109
            }
         },
         "lastUpdated":"2021-05-08T00:53:31.700Z",
         "gameId":260606,
         "odds":{
            "composite":{
               "source":"Composite",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":125,
                     "homeOdds":-145
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":120,
                     "homeOdds":-140
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-140,
                     "homeOdds":127
                  },
                  "current":{
                     "awayOdds":-143,
                     "homeOdds":128
                  }
               },
               "total":{
                  "open":{
                     "total":7,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":7,
                     "overOdds":-125,
                     "underOdds":105
                  }
               },
               "openDate":"2021-05-06T20:14:36.449Z",
               "lastUpdated":"2021-05-08T00:53:30.796Z"
            },
            "bovada":{
               "source":"Bovada",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":125,
                     "homeOdds":-145
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":120,
                     "homeOdds":-140
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-140,
                     "homeOdds":120
                  },
                  "current":{
                     "awayOdds":-145,
                     "homeOdds":125
                  }
               },
               "total":{
                  "open":{
                     "total":7,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":7,
                     "overOdds":-130,
                     "underOdds":110
                  }
               },
               "openDate":"2021-05-06T20:14:36.444Z",
               "lastUpdated":"2021-05-08T00:53:30.796Z"
            },
            "intertops":{
               "source":"Intertops",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":120,
                     "homeOdds":-140
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":115,
                     "homeOdds":-135
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-145,
                     "homeOdds":133
                  },
                  "current":{
                     "awayOdds":-141,
                     "homeOdds":130
                  }
               },
               "total":{
                  "open":{
                     "total":7,
                     "overOdds":-120,
                     "underOdds":100
                  },
                  "current":{
                     "total":7,
                     "overOdds":-125,
                     "underOdds":105
                  }
               },
               "openDate":"2021-05-07T04:37:17.354Z",
               "lastUpdated":"2021-05-08T00:53:30.796Z"
            },
            "betonline":{
               "source":"BetOnline",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":125,
                     "homeOdds":-145
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":120,
                     "homeOdds":-140
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-140,
                     "homeOdds":130
                  },
                  "current":{
                     "awayOdds":-140,
                     "homeOdds":120
                  }
               },
               "total":{
                  "open":{
                     "total":7,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":7,
                     "overOdds":-125,
                     "underOdds":105
                  }
               },
               "openDate":"2021-05-06T20:14:36.444Z",
               "lastUpdated":"2021-05-08T00:53:30.796Z"
            },
            "betnow":{
               "source":"BetNow",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":125,
                     "homeOdds":-145
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":115,
                     "homeOdds":-135
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-139,
                     "homeOdds":129
                  },
                  "current":{
                     "awayOdds":-141,
                     "homeOdds":130
                  }
               },
               "total":{
                  "open":{
                     "total":7,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":7,
                     "overOdds":-125,
                     "underOdds":105
                  }
               },
               "openDate":"2021-05-06T21:04:11.798Z",
               "lastUpdated":"2021-05-08T00:53:30.796Z"
            },
            "sportsbetting":{
               "source":"SportsBetting",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":125,
                     "homeOdds":-145
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":120,
                     "homeOdds":-140
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-140,
                     "homeOdds":130
                  },
                  "current":{
                     "awayOdds":-145,
                     "homeOdds":133
                  }
               },
               "total":{
                  "open":{
                     "total":7,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":7,
                     "overOdds":-120,
                     "underOdds":100
                  }
               },
               "openDate":"2021-05-06T20:14:36.444Z",
               "lastUpdated":"2021-05-08T00:53:30.796Z"
            }
         },
         "bestOdds":{
            "spread":{
               "away":{
                  "away":-1.5,
                  "home":1.5,
                  "awayOdds":120,
                  "homeOdds":-140
               },
               "home":{
                  "away":-1.5,
                  "home":1.5,
                  "awayOdds":115,
                  "homeOdds":-135
               }
            },
            "moneyline":{
               "away":{
                  "awayOdds":-140,
                  "homeOdds":120
               },
               "home":{
                  "awayOdds":-145,
                  "homeOdds":133
               }
            },
            "total":{
               "over":{
                  "total":7,
                  "overOdds":-120,
                  "underOdds":100
               },
               "under":{
                  "total":7,
                  "overOdds":-130,
                  "underOdds":110
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-08T17:05:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Washington Nationals @ New York Yankees",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":false,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Washington Nationals",
               "location":"Washington",
               "mascot":"Nationals",
               "abbreviation":"WSH",
               "conference":"National League",
               "division":"East",
               "teamId":1264
            },
            "home":{
               "team":"New York Yankees",
               "location":"New York",
               "mascot":"Yankees",
               "abbreviation":"NYY",
               "conference":"American League",
               "division":"East",
               "teamId":1188
            }
         },
         "lastUpdated":"2021-05-08T00:14:11.478Z",
         "gameId":260592,
         "odds":{
            "composite":{
               "source":"Composite",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-185,
                     "homeOdds":165
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-190,
                     "homeOdds":165
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":110,
                     "homeOdds":-123
                  },
                  "current":{
                     "awayOdds":104,
                     "homeOdds":-116
                  }
               },
               "total":{
                  "open":{
                     "total":8,
                     "overOdds":105,
                     "underOdds":-125
                  },
                  "current":{
                     "total":8,
                     "overOdds":105,
                     "underOdds":-125
                  }
               },
               "openDate":"2021-05-07T19:21:59.375Z",
               "lastUpdated":"2021-05-08T00:53:31.087Z"
            },
            "intertops":{
               "source":"Intertops",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-184,
                     "homeOdds":164
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-190,
                     "homeOdds":165
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":112,
                     "homeOdds":-122
                  },
                  "current":{
                     "awayOdds":105,
                     "homeOdds":-115
                  }
               },
               "total":{
                  "open":{
                     "total":8,
                     "overOdds":-103,
                     "underOdds":-117
                  },
                  "current":{
                     "total":8,
                     "overOdds":105,
                     "underOdds":-125
                  }
               },
               "openDate":"2021-05-07T19:16:00.380Z",
               "lastUpdated":"2021-05-08T00:53:31.087Z"
            },
            "betonline":{
               "source":"BetOnline",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-180,
                     "homeOdds":160
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-190,
                     "homeOdds":165
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":105,
                     "homeOdds":-125
                  },
                  "current":{
                     "awayOdds":100,
                     "homeOdds":-120
                  }
               },
               "total":{
                  "open":{
                     "total":8,
                     "overOdds":100,
                     "underOdds":-120
                  },
                  "current":{
                     "total":8,
                     "overOdds":100,
                     "underOdds":-120
                  }
               },
               "openDate":"2021-05-07T19:21:59.369Z",
               "lastUpdated":"2021-05-08T00:53:31.087Z"
            },
            "betnow":{
               "source":"BetNow",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-184,
                     "homeOdds":164
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-190,
                     "homeOdds":165
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":112,
                     "homeOdds":-122
                  },
                  "current":{
                     "awayOdds":105,
                     "homeOdds":-115
                  }
               },
               "total":{
                  "open":{
                     "total":8,
                     "overOdds":-103,
                     "underOdds":-117
                  },
                  "current":{
                     "total":8,
                     "overOdds":105,
                     "underOdds":-125
                  }
               },
               "openDate":"2021-05-07T19:16:00.380Z",
               "lastUpdated":"2021-05-08T00:53:31.087Z"
            }
         },
         "bestOdds":{
            "spread":{
               "away":{
                  "away":1.5,
                  "home":-1.5,
                  "awayOdds":-190,
                  "homeOdds":165
               },
               "home":{
                  "away":1.5,
                  "home":-1.5,
                  "awayOdds":-190,
                  "homeOdds":165
               }
            },
            "moneyline":{
               "away":{
                  "awayOdds":105,
                  "homeOdds":-115
               },
               "home":{
                  "awayOdds":105,
                  "homeOdds":-115
               }
            },
            "total":{
               "over":{
                  "total":8,
                  "overOdds":105,
                  "underOdds":-125
               },
               "under":{
                  "total":8,
                  "overOdds":100,
                  "underOdds":-120
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-08T18:15:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Colorado Rockies @ St. Louis Cardinals",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":true,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Colorado Rockies",
               "location":"Colorado",
               "mascot":"Rockies",
               "abbreviation":"COL",
               "conference":"National League",
               "division":"West",
               "teamId":1186
            },
            "home":{
               "team":"St. Louis Cardinals",
               "location":"St. Louis",
               "mascot":"Cardinals",
               "abbreviation":"STL",
               "conference":"National League",
               "division":"Central",
               "teamId":1348
            }
         },
         "lastUpdated":"2021-05-08T00:14:11.686Z",
         "gameId":260594,
         "odds":{
            "composite":{
               "source":"Composite",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-130,
                     "homeOdds":110
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-130,
                     "homeOdds":110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":154,
                     "homeOdds":-170
                  },
                  "current":{
                     "awayOdds":154,
                     "homeOdds":-170
                  }
               },
               "total":{
                  "open":{
                     "total":8.5,
                     "overOdds":-115,
                     "underOdds":-105
                  },
                  "current":{
                     "total":8.5,
                     "overOdds":-115,
                     "underOdds":-105
                  }
               },
               "openDate":"2021-05-07T19:21:59.647Z",
               "lastUpdated":"2021-05-08T00:53:31.375Z"
            },
            "intertops":{
               "source":"Intertops",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-130,
                     "homeOdds":110
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-130,
                     "homeOdds":110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":157,
                     "homeOdds":-170
                  },
                  "current":{
                     "awayOdds":157,
                     "homeOdds":-170
                  }
               },
               "total":{
                  "open":{
                     "total":8.5,
                     "overOdds":-115,
                     "underOdds":-105
                  },
                  "current":{
                     "total":8.5,
                     "overOdds":-115,
                     "underOdds":-105
                  }
               },
               "openDate":"2021-05-07T19:16:00.661Z",
               "lastUpdated":"2021-05-08T00:53:31.375Z"
            },
            "betonline":{
               "source":"BetOnline",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-130,
                     "homeOdds":110
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-130,
                     "homeOdds":110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":147,
                     "homeOdds":-172
                  },
                  "current":{
                     "awayOdds":147,
                     "homeOdds":-172
                  }
               },
               "total":{
                  "open":{
                     "total":8.5,
                     "overOdds":-115,
                     "underOdds":-105
                  },
                  "current":{
                     "total":8.5,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T19:21:59.641Z",
               "lastUpdated":"2021-05-08T00:53:31.375Z"
            },
            "betnow":{
               "source":"BetNow",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-130,
                     "homeOdds":110
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-130,
                     "homeOdds":110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":157,
                     "homeOdds":-170
                  },
                  "current":{
                     "awayOdds":157,
                     "homeOdds":-170
                  }
               },
               "total":{
                  "open":{
                     "total":8.5,
                     "overOdds":-115,
                     "underOdds":-105
                  },
                  "current":{
                     "total":8.5,
                     "overOdds":-115,
                     "underOdds":-105
                  }
               },
               "openDate":"2021-05-07T19:16:00.660Z",
               "lastUpdated":"2021-05-08T00:53:31.375Z"
            }
         },
         "bestOdds":{
            "spread":{
               "away":{
                  "away":1.5,
                  "home":-1.5,
                  "awayOdds":-130,
                  "homeOdds":110
               },
               "home":{
                  "away":1.5,
                  "home":-1.5,
                  "awayOdds":-130,
                  "homeOdds":110
               }
            },
            "moneyline":{
               "away":{
                  "awayOdds":157,
                  "homeOdds":-170
               },
               "home":{
                  "awayOdds":157,
                  "homeOdds":-170
               }
            },
            "total":{
               "over":{
                  "total":8.5,
                  "overOdds":-110,
                  "underOdds":-110
               },
               "under":{
                  "total":8.5,
                  "overOdds":-115,
                  "underOdds":-105
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-08T18:20:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Pittsburgh Pirates @ Chicago Cubs",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":true,
            "divisionGame":true
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Pittsburgh Pirates",
               "location":"Pittsburgh",
               "mascot":"Pirates",
               "abbreviation":"PIT",
               "conference":"National League",
               "division":"Central",
               "teamId":1032
            },
            "home":{
               "team":"Chicago Cubs",
               "location":"Chicago",
               "mascot":"Cubs",
               "abbreviation":"CHC",
               "conference":"National League",
               "division":"Central",
               "teamId":1344
            }
         },
         "lastUpdated":"2021-05-08T00:14:11.875Z",
         "gameId":260596,
         "odds":{
            "composite":{
               "source":"Composite",
               "moneyline":{
                  "open":{
                     "awayOdds":156,
                     "homeOdds":-173
                  },
                  "current":{
                     "awayOdds":156,
                     "homeOdds":-173
                  }
               },
               "openDate":"2021-05-07T21:46:48.791Z",
               "lastUpdated":"2021-05-08T00:53:31.704Z"
            },
            "intertops":{
               "source":"Intertops",
               "moneyline":{
                  "open":{
                     "awayOdds":158,
                     "homeOdds":-172
                  },
                  "current":{
                     "awayOdds":158,
                     "homeOdds":-172
                  }
               },
               "openDate":"2021-05-07T21:46:48.786Z",
               "lastUpdated":"2021-05-08T00:53:31.704Z"
            },
            "betonline":{
               "source":"BetOnline",
               "moneyline":{
                  "open":{
                     "awayOdds":150,
                     "homeOdds":-175
                  },
                  "current":{
                     "awayOdds":150,
                     "homeOdds":-175
                  }
               },
               "openDate":"2021-05-07T21:46:48.786Z",
               "lastUpdated":"2021-05-08T00:53:31.704Z"
            },
            "betnow":{
               "source":"BetNow",
               "moneyline":{
                  "open":{
                     "awayOdds":158,
                     "homeOdds":-172
                  },
                  "current":{
                     "awayOdds":158,
                     "homeOdds":-172
                  }
               },
               "openDate":"2021-05-07T21:46:48.786Z",
               "lastUpdated":"2021-05-08T00:53:31.704Z"
            }
         },
         "bestOdds":{
            "spread":{
               
            },
            "moneyline":{
               "away":{
                  "awayOdds":158,
                  "homeOdds":-172
               },
               "home":{
                  "awayOdds":158,
                  "homeOdds":-172
               }
            },
            "total":{
               
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-08T20:05:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"San Diego Padres @ San Francisco Giants",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":true,
            "divisionGame":true
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"San Diego Padres",
               "location":"San Diego",
               "mascot":"Padres",
               "abbreviation":"SD",
               "conference":"National League",
               "division":"West",
               "teamId":1189
            },
            "home":{
               "team":"San Francisco Giants",
               "location":"San Francisco",
               "mascot":"Giants",
               "abbreviation":"SF",
               "conference":"National League",
               "division":"West",
               "teamId":1109
            }
         },
         "lastUpdated":"2021-05-08T00:14:12.063Z",
         "gameId":260598,
         "odds":{
            "composite":{
               "source":"Composite",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":140,
                     "homeOdds":-160
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":130,
                     "homeOdds":-150
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-128,
                     "homeOdds":116
                  },
                  "current":{
                     "awayOdds":-136,
                     "homeOdds":124
                  }
               },
               "total":{
                  "open":{
                     "total":7,
                     "overOdds":-105,
                     "underOdds":-115
                  },
                  "current":{
                     "total":7,
                     "overOdds":-105,
                     "underOdds":-115
                  }
               },
               "openDate":"2021-05-07T19:21:59.917Z",
               "lastUpdated":"2021-05-08T00:53:31.986Z"
            },
            "intertops":{
               "source":"Intertops",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":137,
                     "homeOdds":-157
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":130,
                     "homeOdds":-150
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-128,
                     "homeOdds":118
                  },
                  "current":{
                     "awayOdds":-135,
                     "homeOdds":125
                  }
               },
               "total":{
                  "open":{
                     "total":7,
                     "overOdds":-105,
                     "underOdds":-115
                  },
                  "current":{
                     "total":7,
                     "overOdds":-105,
                     "underOdds":-115
                  }
               },
               "openDate":"2021-05-07T19:16:00.914Z",
               "lastUpdated":"2021-05-08T00:53:31.986Z"
            },
            "betonline":{
               "source":"BetOnline",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":135,
                     "homeOdds":-155
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":130,
                     "homeOdds":-150
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-130,
                     "homeOdds":110
                  },
                  "current":{
                     "awayOdds":-140,
                     "homeOdds":120
                  }
               },
               "total":{
                  "open":{
                     "total":7,
                     "overOdds":-105,
                     "underOdds":-115
                  },
                  "current":{
                     "total":7,
                     "overOdds":-105,
                     "underOdds":-115
                  }
               },
               "openDate":"2021-05-07T19:21:59.909Z",
               "lastUpdated":"2021-05-08T00:53:31.986Z"
            },
            "betnow":{
               "source":"BetNow",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":137,
                     "homeOdds":-157
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":130,
                     "homeOdds":-150
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-128,
                     "homeOdds":118
                  },
                  "current":{
                     "awayOdds":-135,
                     "homeOdds":125
                  }
               },
               "total":{
                  "open":{
                     "total":7,
                     "overOdds":-105,
                     "underOdds":-115
                  },
                  "current":{
                     "total":7,
                     "overOdds":-105,
                     "underOdds":-115
                  }
               },
               "openDate":"2021-05-07T19:16:00.913Z",
               "lastUpdated":"2021-05-08T00:53:31.986Z"
            }
         },
         "bestOdds":{
            "spread":{
               "away":{
                  "away":-1.5,
                  "home":1.5,
                  "awayOdds":130,
                  "homeOdds":-150
               },
               "home":{
                  "away":-1.5,
                  "home":1.5,
                  "awayOdds":130,
                  "homeOdds":-150
               }
            },
            "moneyline":{
               "away":{
                  "awayOdds":-135,
                  "homeOdds":125
               },
               "home":{
                  "awayOdds":-135,
                  "homeOdds":125
               }
            },
            "total":{
               "over":{
                  "total":7,
                  "overOdds":-105,
                  "underOdds":-115
               },
               "under":{
                  "total":7,
                  "overOdds":-105,
                  "underOdds":-115
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-08T20:07:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Tampa Bay Rays @ Oakland Athletics",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":true,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Tampa Bay Rays",
               "location":"Tampa Bay",
               "mascot":"Rays",
               "abbreviation":"TB",
               "conference":"American League",
               "division":"East",
               "teamId":1033
            },
            "home":{
               "team":"Oakland Athletics",
               "location":"Oakland",
               "mascot":"Athletics",
               "abbreviation":"OAK",
               "conference":"American League",
               "division":"West",
               "teamId":1262
            }
         },
         "lastUpdated":"2021-05-08T00:14:12.277Z",
         "gameId":260600,
         "odds":{
            "composite":{
               "source":"Composite",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":115,
                     "homeOdds":-135
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":115,
                     "homeOdds":-135
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-138,
                     "homeOdds":126
                  },
                  "current":{
                     "awayOdds":-138,
                     "homeOdds":126
                  }
               },
               "total":{
                  "open":{
                     "total":7.5,
                     "overOdds":-100,
                     "underOdds":-120
                  },
                  "current":{
                     "total":7.5,
                     "overOdds":-100,
                     "underOdds":-120
                  }
               },
               "openDate":"2021-05-07T19:22:00.175Z",
               "lastUpdated":"2021-05-08T00:53:32.274Z"
            },
            "intertops":{
               "source":"Intertops",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":115,
                     "homeOdds":-135
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":115,
                     "homeOdds":-135
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-137,
                     "homeOdds":127
                  },
                  "current":{
                     "awayOdds":-137,
                     "homeOdds":127
                  }
               },
               "total":{
                  "open":{
                     "total":7.5,
                     "overOdds":-103,
                     "underOdds":-117
                  },
                  "current":{
                     "total":7.5,
                     "overOdds":-103,
                     "underOdds":-117
                  }
               },
               "openDate":"2021-05-07T19:16:01.177Z",
               "lastUpdated":"2021-05-08T00:53:32.274Z"
            },
            "betonline":{
               "source":"BetOnline",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":115,
                     "homeOdds":-135
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":115,
                     "homeOdds":-135
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-142,
                     "homeOdds":122
                  },
                  "current":{
                     "awayOdds":-142,
                     "homeOdds":122
                  }
               },
               "total":{
                  "open":{
                     "total":7.5,
                     "overOdds":-105,
                     "underOdds":-115
                  },
                  "current":{
                     "total":7.5,
                     "overOdds":-105,
                     "underOdds":-115
                  }
               },
               "openDate":"2021-05-07T19:22:00.170Z",
               "lastUpdated":"2021-05-08T00:53:32.274Z"
            },
            "betnow":{
               "source":"BetNow",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":115,
                     "homeOdds":-135
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":115,
                     "homeOdds":-135
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-137,
                     "homeOdds":127
                  },
                  "current":{
                     "awayOdds":-137,
                     "homeOdds":127
                  }
               },
               "total":{
                  "open":{
                     "total":7.5,
                     "overOdds":-103,
                     "underOdds":-117
                  },
                  "current":{
                     "total":7.5,
                     "overOdds":-103,
                     "underOdds":-117
                  }
               },
               "openDate":"2021-05-07T19:16:01.177Z",
               "lastUpdated":"2021-05-08T00:53:32.274Z"
            }
         },
         "bestOdds":{
            "spread":{
               "away":{
                  "away":-1.5,
                  "home":1.5,
                  "awayOdds":115,
                  "homeOdds":-135
               },
               "home":{
                  "away":-1.5,
                  "home":1.5,
                  "awayOdds":115,
                  "homeOdds":-135
               }
            },
            "moneyline":{
               "away":{
                  "awayOdds":-137,
                  "homeOdds":127
               },
               "home":{
                  "awayOdds":-137,
                  "homeOdds":127
               }
            },
            "total":{
               "over":{
                  "total":7.5,
                  "overOdds":-103,
                  "underOdds":-117
               },
               "under":{
                  "total":7.5,
                  "overOdds":-105,
                  "underOdds":-115
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-08T20:10:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Minnesota Twins @ Detroit Tigers",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":true,
            "divisionGame":true
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Minnesota Twins",
               "location":"Minnesota",
               "mascot":"Twins",
               "abbreviation":"MIN",
               "conference":"American League",
               "division":"Central",
               "teamId":1031
            },
            "home":{
               "team":"Detroit Tigers",
               "location":"Detroit",
               "mascot":"Tigers",
               "abbreviation":"DET",
               "conference":"American League",
               "division":"Central",
               "teamId":1260
            }
         },
         "lastUpdated":"2021-05-08T00:14:12.466Z",
         "gameId":260602,
         "odds":{
            "composite":{
               "source":"Composite",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-175,
                     "homeOdds":158
                  },
                  "current":{
                     "awayOdds":-176,
                     "homeOdds":159
                  }
               },
               "total":{
                  "open":{
                     "total":8,
                     "overOdds":-105,
                     "underOdds":-115
                  },
                  "current":{
                     "total":8,
                     "overOdds":-105,
                     "underOdds":-115
                  }
               },
               "openDate":"2021-05-07T19:22:00.436Z",
               "lastUpdated":"2021-05-08T00:53:32.604Z"
            },
            "intertops":{
               "source":"Intertops",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-175,
                     "homeOdds":161
                  },
                  "current":{
                     "awayOdds":-175,
                     "homeOdds":161
                  }
               },
               "total":{
                  "open":{
                     "total":8,
                     "overOdds":-102,
                     "underOdds":-118
                  },
                  "current":{
                     "total":8,
                     "overOdds":-102,
                     "underOdds":-118
                  }
               },
               "openDate":"2021-05-07T19:16:01.442Z",
               "lastUpdated":"2021-05-08T00:53:32.604Z"
            },
            "betonline":{
               "source":"BetOnline",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-115,
                     "homeOdds":-105
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-177,
                     "homeOdds":152
                  },
                  "current":{
                     "awayOdds":-180,
                     "homeOdds":155
                  }
               },
               "total":{
                  "open":{
                     "total":8,
                     "overOdds":100,
                     "underOdds":-120
                  },
                  "current":{
                     "total":8,
                     "overOdds":100,
                     "underOdds":-120
                  }
               },
               "openDate":"2021-05-07T19:22:00.430Z",
               "lastUpdated":"2021-05-08T00:53:32.604Z"
            },
            "betnow":{
               "source":"BetNow",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-175,
                     "homeOdds":161
                  },
                  "current":{
                     "awayOdds":-175,
                     "homeOdds":161
                  }
               },
               "total":{
                  "open":{
                     "total":8,
                     "overOdds":-102,
                     "underOdds":-118
                  },
                  "current":{
                     "total":8,
                     "overOdds":-102,
                     "underOdds":-118
                  }
               },
               "openDate":"2021-05-07T19:16:01.442Z",
               "lastUpdated":"2021-05-08T00:53:32.604Z"
            }
         },
         "bestOdds":{
            "spread":{
               "away":{
                  "away":-1.5,
                  "home":1.5,
                  "awayOdds":-110,
                  "homeOdds":-110
               },
               "home":{
                  "away":-1.5,
                  "home":1.5,
                  "awayOdds":-115,
                  "homeOdds":-105
               }
            },
            "moneyline":{
               "away":{
                  "awayOdds":-175,
                  "homeOdds":161
               },
               "home":{
                  "awayOdds":-175,
                  "homeOdds":161
               }
            },
            "total":{
               "over":{
                  "total":8,
                  "overOdds":100,
                  "underOdds":-120
               },
               "under":{
                  "total":8,
                  "overOdds":-102,
                  "underOdds":-118
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-08T22:10:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Cincinnati Reds @ Cleveland Indians",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":false,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Cincinnati Reds",
               "location":"Cincinnati",
               "mascot":"Reds",
               "abbreviation":"CIN",
               "conference":"National League",
               "division":"Central",
               "teamId":1029
            },
            "home":{
               "team":"Cleveland Indians",
               "location":"Cleveland",
               "mascot":"Indians",
               "abbreviation":"CLE",
               "conference":"American League",
               "division":"Central",
               "teamId":1106
            }
         },
         "lastUpdated":"2021-05-08T00:14:12.840Z",
         "gameId":260604,
         "odds":{
            "composite":{
               "source":"Composite",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-205,
                     "homeOdds":175
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-205,
                     "homeOdds":175
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-104,
                     "homeOdds":-108
                  },
                  "current":{
                     "awayOdds":-104,
                     "homeOdds":-108
                  }
               },
               "total":{
                  "open":{
                     "total":8,
                     "overOdds":-120,
                     "underOdds":-100
                  },
                  "current":{
                     "total":8,
                     "overOdds":-120,
                     "underOdds":-100
                  }
               },
               "openDate":"2021-05-07T19:22:00.698Z",
               "lastUpdated":"2021-05-08T00:53:32.954Z"
            },
            "intertops":{
               "source":"Intertops",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-205,
                     "homeOdds":175
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-205,
                     "homeOdds":175
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-102,
                     "homeOdds":-108
                  },
                  "current":{
                     "awayOdds":-102,
                     "homeOdds":-108
                  }
               },
               "total":{
                  "open":{
                     "total":8,
                     "overOdds":-123,
                     "underOdds":103
                  },
                  "current":{
                     "total":8,
                     "overOdds":-123,
                     "underOdds":103
                  }
               },
               "openDate":"2021-05-07T19:22:00.693Z",
               "lastUpdated":"2021-05-08T00:53:32.954Z"
            },
            "betonline":{
               "source":"BetOnline",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-210,
                     "homeOdds":175
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-210,
                     "homeOdds":175
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "total":{
                  "open":{
                     "total":8.5,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":8.5,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T19:22:00.693Z",
               "lastUpdated":"2021-05-08T00:53:32.954Z"
            },
            "betnow":{
               "source":"BetNow",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-205,
                     "homeOdds":175
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-205,
                     "homeOdds":175
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-102,
                     "homeOdds":-108
                  },
                  "current":{
                     "awayOdds":-102,
                     "homeOdds":-108
                  }
               },
               "total":{
                  "open":{
                     "total":8,
                     "overOdds":-123,
                     "underOdds":103
                  },
                  "current":{
                     "total":8,
                     "overOdds":-123,
                     "underOdds":103
                  }
               },
               "openDate":"2021-05-07T19:22:00.693Z",
               "lastUpdated":"2021-05-08T00:53:32.954Z"
            }
         },
         "bestOdds":{
            "spread":{
               "away":{
                  "away":1.5,
                  "home":-1.5,
                  "awayOdds":-205,
                  "homeOdds":175
               },
               "home":{
                  "away":1.5,
                  "home":-1.5,
                  "awayOdds":-205,
                  "homeOdds":175
               }
            },
            "moneyline":{
               "away":{
                  "awayOdds":-102,
                  "homeOdds":-108
               },
               "home":{
                  "awayOdds":-102,
                  "homeOdds":-108
               }
            },
            "total":{
               "over":{
                  "total":8,
                  "overOdds":-123,
                  "underOdds":103
               },
               "under":{
                  "total":8.5,
                  "overOdds":-110,
                  "underOdds":-110
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-08T22:10:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Milwaukee Brewers @ Miami Marlins",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":true,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Milwaukee Brewers",
               "location":"Milwaukee",
               "mascot":"Brewers",
               "abbreviation":"MIL",
               "conference":"National League",
               "division":"Central",
               "teamId":1346
            },
            "home":{
               "team":"Miami Marlins",
               "location":"Miami",
               "mascot":"Marlins",
               "abbreviation":"MIA",
               "conference":"National League",
               "division":"East",
               "teamId":1261
            }
         },
         "lastUpdated":"2021-05-08T00:14:12.650Z",
         "gameId":260607
      },
      {
         "schedule":{
            "date":"2021-05-08T23:05:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Boston Red Sox @ Baltimore Orioles",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":true,
            "divisionGame":true
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Boston Red Sox",
               "location":"Boston",
               "mascot":"Red Sox",
               "abbreviation":"BOS",
               "conference":"American League",
               "division":"East",
               "teamId":1185
            },
            "home":{
               "team":"Baltimore Orioles",
               "location":"Baltimore",
               "mascot":"Orioles",
               "abbreviation":"BAL",
               "conference":"American League",
               "division":"East",
               "teamId":1105
            }
         },
         "lastUpdated":"2021-05-08T00:14:13.218Z",
         "gameId":260609
      },
      {
         "schedule":{
            "date":"2021-05-08T23:05:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Seattle Mariners @ Texas Rangers",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":true,
            "divisionGame":true
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Seattle Mariners",
               "location":"Seattle",
               "mascot":"Mariners",
               "abbreviation":"SEA",
               "conference":"American League",
               "division":"West",
               "teamId":1263
            },
            "home":{
               "team":"Texas Rangers",
               "location":"Texas",
               "mascot":"Rangers",
               "abbreviation":"TEX",
               "conference":"American League",
               "division":"West",
               "teamId":1110
            }
         },
         "lastUpdated":"2021-05-08T00:14:13.028Z",
         "gameId":260611
      },
      {
         "schedule":{
            "date":"2021-05-08T23:10:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Arizona Diamondbacks @ New York Mets",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":true,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Arizona Diamondbacks",
               "location":"Arizona",
               "mascot":"Diamondbacks",
               "abbreviation":"ARI",
               "conference":"National League",
               "division":"West",
               "teamId":1028
            },
            "home":{
               "team":"New York Mets",
               "location":"New York",
               "mascot":"Mets",
               "abbreviation":"NYM",
               "conference":"National League",
               "division":"East",
               "teamId":1108
            }
         },
         "lastUpdated":"2021-05-08T00:14:13.790Z",
         "gameId":260613
      },
      {
         "schedule":{
            "date":"2021-05-08T23:10:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Chicago White Sox @ Kansas City Royals",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":true,
            "divisionGame":true
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Chicago White Sox",
               "location":"Chicago",
               "mascot":"White Sox",
               "abbreviation":"CWS",
               "conference":"American League",
               "division":"Central",
               "teamId":1259
            },
            "home":{
               "team":"Kansas City Royals",
               "location":"Kansas City",
               "mascot":"Royals",
               "abbreviation":"KC",
               "conference":"American League",
               "division":"Central",
               "teamId":1030
            }
         },
         "lastUpdated":"2021-05-08T00:14:13.602Z",
         "gameId":260615,
         "odds":{
            "composite":{
               "source":"Composite",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-171,
                     "homeOdds":155
                  },
                  "current":{
                     "awayOdds":-171,
                     "homeOdds":155
                  }
               },
               "total":{
                  "open":{
                     "total":8.5,
                     "overOdds":-120,
                     "underOdds":-100
                  },
                  "current":{
                     "total":8.5,
                     "overOdds":-115,
                     "underOdds":-105
                  }
               },
               "openDate":"2021-05-07T19:22:01.235Z",
               "lastUpdated":"2021-05-08T00:53:33.518Z"
            },
            "intertops":{
               "source":"Intertops",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-174,
                     "homeOdds":160
                  },
                  "current":{
                     "awayOdds":-170,
                     "homeOdds":157
                  }
               },
               "total":{
                  "open":{
                     "total":8.5,
                     "overOdds":-116,
                     "underOdds":-104
                  },
                  "current":{
                     "total":8.5,
                     "overOdds":-116,
                     "underOdds":-104
                  }
               },
               "openDate":"2021-05-07T19:16:01.977Z",
               "lastUpdated":"2021-05-08T00:53:33.518Z"
            },
            "betonline":{
               "source":"BetOnline",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-175,
                     "homeOdds":150
                  },
                  "current":{
                     "awayOdds":-175,
                     "homeOdds":150
                  }
               },
               "total":{
                  "open":{
                     "total":8.5,
                     "overOdds":-115,
                     "underOdds":-105
                  },
                  "current":{
                     "total":8.5,
                     "overOdds":-120,
                     "underOdds":100
                  }
               },
               "openDate":"2021-05-07T19:22:01.228Z",
               "lastUpdated":"2021-05-08T00:53:33.518Z"
            },
            "betnow":{
               "source":"BetNow",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-174,
                     "homeOdds":160
                  },
                  "current":{
                     "awayOdds":-170,
                     "homeOdds":157
                  }
               },
               "total":{
                  "open":{
                     "total":8.5,
                     "overOdds":-116,
                     "underOdds":-104
                  },
                  "current":{
                     "total":8.5,
                     "overOdds":-116,
                     "underOdds":-104
                  }
               },
               "openDate":"2021-05-07T19:16:01.977Z",
               "lastUpdated":"2021-05-08T00:53:33.518Z"
            }
         },
         "bestOdds":{
            "spread":{
               "away":{
                  "away":-1.5,
                  "home":1.5,
                  "awayOdds":-110,
                  "homeOdds":-110
               },
               "home":{
                  "away":-1.5,
                  "home":1.5,
                  "awayOdds":-110,
                  "homeOdds":-110
               }
            },
            "moneyline":{
               "away":{
                  "awayOdds":-170,
                  "homeOdds":157
               },
               "home":{
                  "awayOdds":-170,
                  "homeOdds":157
               }
            },
            "total":{
               "over":{
                  "total":8.5,
                  "overOdds":-116,
                  "underOdds":-104
               },
               "under":{
                  "total":8.5,
                  "overOdds":-120,
                  "underOdds":100
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-08T23:10:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Toronto Blue Jays @ Houston Astros",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":true,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Toronto Blue Jays",
               "location":"Toronto",
               "mascot":"Blue Jays",
               "abbreviation":"TOR",
               "conference":"American League",
               "division":"East",
               "teamId":1190
            },
            "home":{
               "team":"Houston Astros",
               "location":"Houston",
               "mascot":"Astros",
               "abbreviation":"HOU",
               "conference":"American League",
               "division":"West",
               "teamId":1345
            }
         },
         "lastUpdated":"2021-05-08T00:14:13.418Z",
         "gameId":260617,
         "odds":{
            "composite":{
               "source":"Composite",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-150,
                     "homeOdds":130
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-150,
                     "homeOdds":130
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":129,
                     "homeOdds":-141
                  },
                  "current":{
                     "awayOdds":129,
                     "homeOdds":-141
                  }
               },
               "total":{
                  "open":{
                     "total":8.5,
                     "overOdds":-115,
                     "underOdds":-105
                  },
                  "current":{
                     "total":8.5,
                     "overOdds":-115,
                     "underOdds":-105
                  }
               },
               "openDate":"2021-05-07T19:22:00.958Z",
               "lastUpdated":"2021-05-08T00:53:33.253Z"
            },
            "intertops":{
               "source":"Intertops",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-150,
                     "homeOdds":130
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-150,
                     "homeOdds":130
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":130,
                     "homeOdds":-140
                  },
                  "current":{
                     "awayOdds":130,
                     "homeOdds":-140
                  }
               },
               "total":{
                  "open":{
                     "total":9,
                     "overOdds":-101,
                     "underOdds":-119
                  },
                  "current":{
                     "total":8.5,
                     "overOdds":-117,
                     "underOdds":-103
                  }
               },
               "openDate":"2021-05-07T19:16:01.704Z",
               "lastUpdated":"2021-05-08T00:53:33.253Z"
            },
            "betonline":{
               "source":"BetOnline",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-150,
                     "homeOdds":130
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-150,
                     "homeOdds":130
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":125,
                     "homeOdds":-145
                  },
                  "current":{
                     "awayOdds":125,
                     "homeOdds":-145
                  }
               },
               "total":{
                  "open":{
                     "total":9,
                     "overOdds":100,
                     "underOdds":-120
                  },
                  "current":{
                     "total":9,
                     "overOdds":100,
                     "underOdds":-120
                  }
               },
               "openDate":"2021-05-07T19:22:00.953Z",
               "lastUpdated":"2021-05-08T00:53:33.253Z"
            },
            "betnow":{
               "source":"BetNow",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-150,
                     "homeOdds":130
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-150,
                     "homeOdds":130
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":130,
                     "homeOdds":-140
                  },
                  "current":{
                     "awayOdds":130,
                     "homeOdds":-140
                  }
               },
               "total":{
                  "open":{
                     "total":9,
                     "overOdds":-101,
                     "underOdds":-119
                  },
                  "current":{
                     "total":8.5,
                     "overOdds":-117,
                     "underOdds":-103
                  }
               },
               "openDate":"2021-05-07T19:16:01.704Z",
               "lastUpdated":"2021-05-08T00:53:33.253Z"
            }
         },
         "bestOdds":{
            "spread":{
               "away":{
                  "away":1.5,
                  "home":-1.5,
                  "awayOdds":-150,
                  "homeOdds":130
               },
               "home":{
                  "away":1.5,
                  "home":-1.5,
                  "awayOdds":-150,
                  "homeOdds":130
               }
            },
            "moneyline":{
               "away":{
                  "awayOdds":130,
                  "homeOdds":-140
               },
               "home":{
                  "awayOdds":130,
                  "homeOdds":-140
               }
            },
            "total":{
               "over":{
                  "total":8.5,
                  "overOdds":-117,
                  "underOdds":-103
               },
               "under":{
                  "total":9,
                  "overOdds":100,
                  "underOdds":-120
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-08T23:20:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Philadelphia Phillies @ Atlanta Braves",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":true,
            "divisionGame":true
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Philadelphia Phillies",
               "location":"Philadelphia",
               "mascot":"Phillies",
               "abbreviation":"PHI",
               "conference":"National League",
               "division":"East",
               "teamId":1347
            },
            "home":{
               "team":"Atlanta Braves",
               "location":"Atlanta",
               "mascot":"Braves",
               "abbreviation":"ATL",
               "conference":"National League",
               "division":"East",
               "teamId":1343
            }
         },
         "lastUpdated":"2021-05-08T00:14:13.987Z",
         "gameId":260619,
         "odds":{
            "composite":{
               "source":"Composite",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-135,
                     "homeOdds":115
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-135,
                     "homeOdds":115
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":153,
                     "homeOdds":-169
                  },
                  "current":{
                     "awayOdds":158,
                     "homeOdds":-175
                  }
               },
               "total":{
                  "open":{
                     "total":8.5,
                     "overOdds":-115,
                     "underOdds":-105
                  },
                  "current":{
                     "total":8.5,
                     "overOdds":-115,
                     "underOdds":-105
                  }
               },
               "openDate":"2021-05-07T19:22:01.496Z",
               "lastUpdated":"2021-05-08T00:53:33.789Z"
            },
            "intertops":{
               "source":"Intertops",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-135,
                     "homeOdds":115
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-135,
                     "homeOdds":115
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":154,
                     "homeOdds":-167
                  },
                  "current":{
                     "awayOdds":161,
                     "homeOdds":-175
                  }
               },
               "total":{
                  "open":{
                     "total":8.5,
                     "overOdds":-118,
                     "underOdds":-102
                  },
                  "current":{
                     "total":8.5,
                     "overOdds":-118,
                     "underOdds":-102
                  }
               },
               "openDate":"2021-05-07T19:16:02.246Z",
               "lastUpdated":"2021-05-08T00:53:33.789Z"
            },
            "betonline":{
               "source":"BetOnline",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-135,
                     "homeOdds":115
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-135,
                     "homeOdds":115
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":150,
                     "homeOdds":-175
                  },
                  "current":{
                     "awayOdds":150,
                     "homeOdds":-175
                  }
               },
               "total":{
                  "open":{
                     "total":8.5,
                     "overOdds":-120,
                     "underOdds":100
                  },
                  "current":{
                     "total":8.5,
                     "overOdds":-120,
                     "underOdds":100
                  }
               },
               "openDate":"2021-05-07T19:22:01.491Z",
               "lastUpdated":"2021-05-08T00:53:33.789Z"
            },
            "betnow":{
               "source":"BetNow",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-135,
                     "homeOdds":115
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-135,
                     "homeOdds":115
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":154,
                     "homeOdds":-167
                  },
                  "current":{
                     "awayOdds":161,
                     "homeOdds":-175
                  }
               },
               "total":{
                  "open":{
                     "total":8.5,
                     "overOdds":-118,
                     "underOdds":-102
                  },
                  "current":{
                     "total":8.5,
                     "overOdds":-118,
                     "underOdds":-102
                  }
               },
               "openDate":"2021-05-07T19:16:02.246Z",
               "lastUpdated":"2021-05-08T00:53:33.789Z"
            }
         },
         "bestOdds":{
            "spread":{
               "away":{
                  "away":1.5,
                  "home":-1.5,
                  "awayOdds":-135,
                  "homeOdds":115
               },
               "home":{
                  "away":1.5,
                  "home":-1.5,
                  "awayOdds":-135,
                  "homeOdds":115
               }
            },
            "moneyline":{
               "away":{
                  "awayOdds":161,
                  "homeOdds":-175
               },
               "home":{
                  "awayOdds":161,
                  "homeOdds":-175
               }
            },
            "total":{
               "over":{
                  "total":8.5,
                  "overOdds":-118,
                  "underOdds":-102
               },
               "under":{
                  "total":8.5,
                  "overOdds":-120,
                  "underOdds":100
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-09T01:07:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Los Angeles Dodgers @ Los Angeles Angels",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":false,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Los Angeles Dodgers",
               "location":"Los Angeles",
               "mascot":"Dodgers",
               "abbreviation":"LAD",
               "conference":"National League",
               "division":"West",
               "teamId":1187
            },
            "home":{
               "team":"Los Angeles Angels",
               "location":"Los Angeles",
               "mascot":"Angels",
               "abbreviation":"LAA",
               "conference":"American League",
               "division":"West",
               "teamId":1107
            }
         },
         "lastUpdated":"2021-05-08T00:14:14.182Z",
         "gameId":260622
      },
      {
         "schedule":{
            "date":"2021-05-09T17:05:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Washington Nationals @ New York Yankees",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":false,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Washington Nationals",
               "location":"Washington",
               "mascot":"Nationals",
               "abbreviation":"WSH",
               "conference":"National League",
               "division":"East",
               "teamId":1264
            },
            "home":{
               "team":"New York Yankees",
               "location":"New York",
               "mascot":"Yankees",
               "abbreviation":"NYY",
               "conference":"American League",
               "division":"East",
               "teamId":1188
            }
         },
         "lastUpdated":"2021-05-08T00:14:15.570Z",
         "gameId":260605
      },
      {
         "schedule":{
            "date":"2021-05-09T17:05:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Boston Red Sox @ Baltimore Orioles",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":true,
            "divisionGame":true
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Boston Red Sox",
               "location":"Boston",
               "mascot":"Red Sox",
               "abbreviation":"BOS",
               "conference":"American League",
               "division":"East",
               "teamId":1185
            },
            "home":{
               "team":"Baltimore Orioles",
               "location":"Baltimore",
               "mascot":"Orioles",
               "abbreviation":"BAL",
               "conference":"American League",
               "division":"East",
               "teamId":1105
            }
         },
         "lastUpdated":"2021-05-08T00:14:15.800Z",
         "gameId":260608
      },
      {
         "schedule":{
            "date":"2021-05-09T17:10:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Arizona Diamondbacks @ New York Mets",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":true,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Arizona Diamondbacks",
               "location":"Arizona",
               "mascot":"Diamondbacks",
               "abbreviation":"ARI",
               "conference":"National League",
               "division":"West",
               "teamId":1028
            },
            "home":{
               "team":"New York Mets",
               "location":"New York",
               "mascot":"Mets",
               "abbreviation":"NYM",
               "conference":"National League",
               "division":"East",
               "teamId":1108
            }
         },
         "lastUpdated":"2021-05-08T00:14:16.600Z",
         "gameId":260610
      },
      {
         "schedule":{
            "date":"2021-05-09T17:10:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Cincinnati Reds @ Cleveland Indians",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":false,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Cincinnati Reds",
               "location":"Cincinnati",
               "mascot":"Reds",
               "abbreviation":"CIN",
               "conference":"National League",
               "division":"Central",
               "teamId":1029
            },
            "home":{
               "team":"Cleveland Indians",
               "location":"Cleveland",
               "mascot":"Indians",
               "abbreviation":"CLE",
               "conference":"American League",
               "division":"Central",
               "teamId":1106
            }
         },
         "lastUpdated":"2021-05-08T00:14:16.368Z",
         "gameId":260612
      },
      {
         "schedule":{
            "date":"2021-05-09T17:10:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Milwaukee Brewers @ Miami Marlins",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":true,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Milwaukee Brewers",
               "location":"Milwaukee",
               "mascot":"Brewers",
               "abbreviation":"MIL",
               "conference":"National League",
               "division":"Central",
               "teamId":1346
            },
            "home":{
               "team":"Miami Marlins",
               "location":"Miami",
               "mascot":"Marlins",
               "abbreviation":"MIA",
               "conference":"National League",
               "division":"East",
               "teamId":1261
            }
         },
         "lastUpdated":"2021-05-08T00:14:16.184Z",
         "gameId":260614
      },
      {
         "schedule":{
            "date":"2021-05-09T17:10:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Minnesota Twins @ Detroit Tigers",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":true,
            "divisionGame":true
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Minnesota Twins",
               "location":"Minnesota",
               "mascot":"Twins",
               "abbreviation":"MIN",
               "conference":"American League",
               "division":"Central",
               "teamId":1031
            },
            "home":{
               "team":"Detroit Tigers",
               "location":"Detroit",
               "mascot":"Tigers",
               "abbreviation":"DET",
               "conference":"American League",
               "division":"Central",
               "teamId":1260
            }
         },
         "lastUpdated":"2021-05-08T00:14:15.993Z",
         "gameId":260616
      },
      {
         "schedule":{
            "date":"2021-05-09T18:10:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Chicago White Sox @ Kansas City Royals",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":true,
            "divisionGame":true
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Chicago White Sox",
               "location":"Chicago",
               "mascot":"White Sox",
               "abbreviation":"CWS",
               "conference":"American League",
               "division":"Central",
               "teamId":1259
            },
            "home":{
               "team":"Kansas City Royals",
               "location":"Kansas City",
               "mascot":"Royals",
               "abbreviation":"KC",
               "conference":"American League",
               "division":"Central",
               "teamId":1030
            }
         },
         "lastUpdated":"2021-05-08T00:14:17.022Z",
         "gameId":260618
      },
      {
         "schedule":{
            "date":"2021-05-09T18:10:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Toronto Blue Jays @ Houston Astros",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":true,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Toronto Blue Jays",
               "location":"Toronto",
               "mascot":"Blue Jays",
               "abbreviation":"TOR",
               "conference":"American League",
               "division":"East",
               "teamId":1190
            },
            "home":{
               "team":"Houston Astros",
               "location":"Houston",
               "mascot":"Astros",
               "abbreviation":"HOU",
               "conference":"American League",
               "division":"West",
               "teamId":1345
            }
         },
         "lastUpdated":"2021-05-08T00:14:16.805Z",
         "gameId":260621
      },
      {
         "schedule":{
            "date":"2021-05-09T18:15:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Colorado Rockies @ St. Louis Cardinals",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":true,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Colorado Rockies",
               "location":"Colorado",
               "mascot":"Rockies",
               "abbreviation":"COL",
               "conference":"National League",
               "division":"West",
               "teamId":1186
            },
            "home":{
               "team":"St. Louis Cardinals",
               "location":"St. Louis",
               "mascot":"Cardinals",
               "abbreviation":"STL",
               "conference":"National League",
               "division":"Central",
               "teamId":1348
            }
         },
         "lastUpdated":"2021-05-08T00:14:17.229Z",
         "gameId":260624
      },
      {
         "schedule":{
            "date":"2021-05-09T18:20:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Pittsburgh Pirates @ Chicago Cubs",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":true,
            "divisionGame":true
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Pittsburgh Pirates",
               "location":"Pittsburgh",
               "mascot":"Pirates",
               "abbreviation":"PIT",
               "conference":"National League",
               "division":"Central",
               "teamId":1032
            },
            "home":{
               "team":"Chicago Cubs",
               "location":"Chicago",
               "mascot":"Cubs",
               "abbreviation":"CHC",
               "conference":"National League",
               "division":"Central",
               "teamId":1344
            }
         },
         "lastUpdated":"2021-05-08T00:14:17.435Z",
         "gameId":260626
      },
      {
         "schedule":{
            "date":"2021-05-09T18:35:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Seattle Mariners @ Texas Rangers",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":true,
            "divisionGame":true
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Seattle Mariners",
               "location":"Seattle",
               "mascot":"Mariners",
               "abbreviation":"SEA",
               "conference":"American League",
               "division":"West",
               "teamId":1263
            },
            "home":{
               "team":"Texas Rangers",
               "location":"Texas",
               "mascot":"Rangers",
               "abbreviation":"TEX",
               "conference":"American League",
               "division":"West",
               "teamId":1110
            }
         },
         "lastUpdated":"2021-05-08T00:14:17.632Z",
         "gameId":260628
      },
      {
         "schedule":{
            "date":"2021-05-09T20:05:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"San Diego Padres @ San Francisco Giants",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":true,
            "divisionGame":true
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"San Diego Padres",
               "location":"San Diego",
               "mascot":"Padres",
               "abbreviation":"SD",
               "conference":"National League",
               "division":"West",
               "teamId":1189
            },
            "home":{
               "team":"San Francisco Giants",
               "location":"San Francisco",
               "mascot":"Giants",
               "abbreviation":"SF",
               "conference":"National League",
               "division":"West",
               "teamId":1109
            }
         },
         "lastUpdated":"2021-05-08T00:14:17.867Z",
         "gameId":260630
      },
      {
         "schedule":{
            "date":"2021-05-09T20:07:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Los Angeles Dodgers @ Los Angeles Angels",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":false,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Los Angeles Dodgers",
               "location":"Los Angeles",
               "mascot":"Dodgers",
               "abbreviation":"LAD",
               "conference":"National League",
               "division":"West",
               "teamId":1187
            },
            "home":{
               "team":"Los Angeles Angels",
               "location":"Los Angeles",
               "mascot":"Angels",
               "abbreviation":"LAA",
               "conference":"American League",
               "division":"West",
               "teamId":1107
            }
         },
         "lastUpdated":"2021-05-08T00:14:18.270Z",
         "gameId":260632
      },
      {
         "schedule":{
            "date":"2021-05-09T20:07:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Tampa Bay Rays @ Oakland Athletics",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":true,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Tampa Bay Rays",
               "location":"Tampa Bay",
               "mascot":"Rays",
               "abbreviation":"TB",
               "conference":"American League",
               "division":"East",
               "teamId":1033
            },
            "home":{
               "team":"Oakland Athletics",
               "location":"Oakland",
               "mascot":"Athletics",
               "abbreviation":"OAK",
               "conference":"American League",
               "division":"West",
               "teamId":1262
            }
         },
         "lastUpdated":"2021-05-08T00:14:18.065Z",
         "gameId":260633
      },
      {
         "schedule":{
            "date":"2021-05-09T23:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Philadelphia Phillies @ Atlanta Braves",
         "details":{
            "league":"MLB",
            "seasonType":"regular",
            "season":2021,
            "special":false,
            "conferenceGame":true,
            "divisionGame":true
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Philadelphia Phillies",
               "location":"Philadelphia",
               "mascot":"Phillies",
               "abbreviation":"PHI",
               "conference":"National League",
               "division":"East",
               "teamId":1347
            },
            "home":{
               "team":"Atlanta Braves",
               "location":"Atlanta",
               "mascot":"Braves",
               "abbreviation":"ATL",
               "conference":"National League",
               "division":"East",
               "teamId":1343
            }
         },
         "lastUpdated":"2021-05-08T00:14:18.480Z",
         "gameId":260634
      }
   ],
   "NHL":[
      {
         "schedule":{
            "date":"2021-05-08T02:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"St. Louis Blues @ Vegas Golden Knights",
         "details":{
            "league":"NHL",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":true,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"St. Louis Blues",
               "location":"St. Louis",
               "mascot":"Blues",
               "abbreviation":"STL",
               "conference":"Western",
               "division":"Central",
               "teamId":1168
            },
            "home":{
               "team":"Vegas Golden Knights",
               "location":"Vegas",
               "mascot":"Golden Knights",
               "abbreviation":"VGK",
               "conference":"Western",
               "division":"Pacific",
               "teamId":1245
            }
         },
         "lastUpdated":"2021-05-08T00:54:04.288Z",
         "gameId":258252,
         "odds":{
            "composite":{
               "source":"Composite",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-170,
                     "homeOdds":150
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-165,
                     "homeOdds":145
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":144,
                     "homeOdds":-162
                  },
                  "current":{
                     "awayOdds":161,
                     "homeOdds":-183
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-120,
                     "underOdds":-100
                  }
               },
               "openDate":"2021-05-06T18:28:10.069Z",
               "lastUpdated":"2021-05-08T00:48:05.982Z"
            },
            "bovada":{
               "source":"Bovada",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-170,
                     "homeOdds":150
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-165,
                     "homeOdds":145
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":140,
                     "homeOdds":-160
                  },
                  "current":{
                     "awayOdds":155,
                     "homeOdds":-175
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-06T18:28:10.063Z",
               "lastUpdated":"2021-05-08T00:48:05.982Z"
            },
            "intertops":{
               "source":"Intertops",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-170,
                     "homeOdds":150
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-165,
                     "homeOdds":145
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":150,
                     "homeOdds":-170
                  },
                  "current":{
                     "awayOdds":160,
                     "homeOdds":-178
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-115,
                     "underOdds":104
                  }
               },
               "openDate":"2021-05-07T04:12:03.389Z",
               "lastUpdated":"2021-05-08T00:48:05.982Z"
            },
            "betonline":{
               "source":"BetOnline",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-170,
                     "homeOdds":150
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-160,
                     "homeOdds":140
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":149,
                     "homeOdds":-165
                  },
                  "current":{
                     "awayOdds":165,
                     "homeOdds":-190
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-105,
                     "underOdds":-105
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-120,
                     "underOdds":100
                  }
               },
               "openDate":"2021-05-06T18:28:10.063Z",
               "lastUpdated":"2021-05-08T00:48:05.982Z"
            },
            "betnow":{
               "source":"BetNow",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-175,
                     "homeOdds":155
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-165,
                     "homeOdds":145
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":135,
                     "homeOdds":-155
                  },
                  "current":{
                     "awayOdds":160,
                     "homeOdds":-178
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-115,
                     "underOdds":104
                  }
               },
               "openDate":"2021-05-06T18:28:10.063Z",
               "lastUpdated":"2021-05-08T00:48:05.982Z"
            },
            "sportsbetting":{
               "source":"SportsBetting",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-170,
                     "homeOdds":150
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-160,
                     "homeOdds":140
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":149,
                     "homeOdds":-165
                  },
                  "current":{
                     "awayOdds":165,
                     "homeOdds":-190
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-105,
                     "underOdds":-105
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-120,
                     "underOdds":100
                  }
               },
               "openDate":"2021-05-06T18:28:10.063Z",
               "lastUpdated":"2021-05-08T00:48:05.982Z"
            }
         },
         "bestOdds":{
            "spread":{
               "away":{
                  "away":1.5,
                  "home":-1.5,
                  "awayOdds":-160,
                  "homeOdds":140
               },
               "home":{
                  "away":1.5,
                  "home":-1.5,
                  "awayOdds":-165,
                  "homeOdds":145
               }
            },
            "moneyline":{
               "away":{
                  "awayOdds":165,
                  "homeOdds":-190
               },
               "home":{
                  "awayOdds":155,
                  "homeOdds":-175
               }
            },
            "total":{
               "over":{
                  "total":5.5,
                  "overOdds":-110,
                  "underOdds":-110
               },
               "under":{
                  "total":5.5,
                  "overOdds":-115,
                  "underOdds":104
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-08T02:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Colorado Avalanche @ Los Angeles Kings",
         "details":{
            "league":"NHL",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":true,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Colorado Avalanche",
               "location":"Colorado",
               "mascot":"Avalanche",
               "abbreviation":"COL",
               "conference":"Western",
               "division":"Central",
               "teamId":1005
            },
            "home":{
               "team":"Los Angeles Kings",
               "location":"Los Angeles",
               "mascot":"Kings",
               "abbreviation":"LAK",
               "conference":"Western",
               "division":"Pacific",
               "teamId":1009
            }
         },
         "lastUpdated":"2021-05-08T00:54:04.488Z",
         "gameId":258253,
         "odds":{
            "composite":{
               "source":"Composite",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-105,
                     "homeOdds":-115
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-258,
                     "homeOdds":222
                  },
                  "current":{
                     "awayOdds":-280,
                     "homeOdds":240
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-125,
                     "underOdds":110
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-125,
                     "underOdds":105
                  }
               },
               "openDate":"2021-05-06T18:28:09.815Z",
               "lastUpdated":"2021-05-08T00:48:05.681Z"
            },
            "bovada":{
               "source":"Bovada",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-105,
                     "homeOdds":-115
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-115,
                     "homeOdds":-105
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-260,
                     "homeOdds":215
                  },
                  "current":{
                     "awayOdds":-290,
                     "homeOdds":240
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-125,
                     "underOdds":105
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-125,
                     "underOdds":105
                  }
               },
               "openDate":"2021-05-06T18:28:09.807Z",
               "lastUpdated":"2021-05-08T00:48:05.681Z"
            },
            "intertops":{
               "source":"Intertops",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-105,
                     "homeOdds":-115
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-112,
                     "homeOdds":-108
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-260,
                     "homeOdds":220
                  },
                  "current":{
                     "awayOdds":-275,
                     "homeOdds":243
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-125,
                     "underOdds":105
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-121,
                     "underOdds":110
                  }
               },
               "openDate":"2021-05-07T04:12:03.145Z",
               "lastUpdated":"2021-05-08T00:48:05.681Z"
            },
            "betonline":{
               "source":"BetOnline",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-105,
                     "homeOdds":-115
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-120,
                     "homeOdds":100
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-255,
                     "homeOdds":226
                  },
                  "current":{
                     "awayOdds":-280,
                     "homeOdds":230
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-120,
                     "underOdds":109
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-125,
                     "underOdds":105
                  }
               },
               "openDate":"2021-05-06T18:28:09.807Z",
               "lastUpdated":"2021-05-08T00:48:05.681Z"
            },
            "betnow":{
               "source":"BetNow",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-102,
                     "homeOdds":-118
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-112,
                     "homeOdds":-108
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-260,
                     "homeOdds":220
                  },
                  "current":{
                     "awayOdds":-275,
                     "homeOdds":243
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-125,
                     "underOdds":105
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-121,
                     "underOdds":110
                  }
               },
               "openDate":"2021-05-06T18:28:09.806Z",
               "lastUpdated":"2021-05-08T00:48:05.681Z"
            },
            "sportsbetting":{
               "source":"SportsBetting",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-105,
                     "homeOdds":-115
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":-115,
                     "homeOdds":-105
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-255,
                     "homeOdds":226
                  },
                  "current":{
                     "awayOdds":-280,
                     "homeOdds":240
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-120,
                     "underOdds":109
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-125,
                     "underOdds":105
                  }
               },
               "openDate":"2021-05-06T18:28:09.806Z",
               "lastUpdated":"2021-05-08T00:48:05.681Z"
            }
         },
         "bestOdds":{
            "spread":{
               "away":{
                  "away":-1.5,
                  "home":1.5,
                  "awayOdds":-112,
                  "homeOdds":-108
               },
               "home":{
                  "away":-1.5,
                  "home":1.5,
                  "awayOdds":-120,
                  "homeOdds":100
               }
            },
            "moneyline":{
               "away":{
                  "awayOdds":-275,
                  "homeOdds":243
               },
               "home":{
                  "awayOdds":-275,
                  "homeOdds":243
               }
            },
            "total":{
               "over":{
                  "total":5.5,
                  "overOdds":-121,
                  "underOdds":110
               },
               "under":{
                  "total":5.5,
                  "overOdds":-121,
                  "underOdds":110
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-08T02:30:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Arizona Coyotes @ San Jose Sharks",
         "details":{
            "league":"NHL",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":true,
            "divisionGame":true
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Arizona Coyotes",
               "location":"Arizona",
               "mascot":"Coyotes",
               "abbreviation":"ARI",
               "conference":"Western",
               "division":"Pacific",
               "teamId":1000
            },
            "home":{
               "team":"San Jose Sharks",
               "location":"San Jose",
               "mascot":"Sharks",
               "abbreviation":"SJS",
               "conference":"Western",
               "division":"Pacific",
               "teamId":1088
            }
         },
         "lastUpdated":"2021-05-08T00:54:04.696Z",
         "gameId":258254,
         "odds":{
            "composite":{
               "source":"Composite",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":205,
                     "homeOdds":-245
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":215,
                     "homeOdds":-265
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-113,
                     "homeOdds":-102
                  },
                  "current":{
                     "awayOdds":-104,
                     "homeOdds":-110
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-120,
                     "underOdds":-100
                  }
               },
               "openDate":"2021-05-06T18:28:10.360Z",
               "lastUpdated":"2021-05-08T00:48:06.262Z"
            },
            "bovada":{
               "source":"Bovada",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":205,
                     "homeOdds":-245
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":215,
                     "homeOdds":-260
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-115,
                     "homeOdds":-105
                  },
                  "current":{
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-115,
                     "underOdds":-105
                  }
               },
               "openDate":"2021-05-06T18:28:10.354Z",
               "lastUpdated":"2021-05-08T00:48:06.262Z"
            },
            "intertops":{
               "source":"Intertops",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":200,
                     "homeOdds":-240
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":225,
                     "homeOdds":-265
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-110,
                     "homeOdds":100
                  },
                  "current":{
                     "awayOdds":101,
                     "homeOdds":-111
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-115,
                     "underOdds":-105
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-113,
                     "underOdds":102
                  }
               },
               "openDate":"2021-05-07T04:26:12.704Z",
               "lastUpdated":"2021-05-08T00:48:06.262Z"
            },
            "betonline":{
               "source":"BetOnline",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":205,
                     "homeOdds":-245
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":215,
                     "homeOdds":-260
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-111,
                     "homeOdds":101
                  },
                  "current":{
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-108,
                     "underOdds":-102
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-120,
                     "underOdds":100
                  }
               },
               "openDate":"2021-05-06T18:28:10.354Z",
               "lastUpdated":"2021-05-08T00:48:06.262Z"
            },
            "betnow":{
               "source":"BetNow",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":205,
                     "homeOdds":-245
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":225,
                     "homeOdds":-265
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-115,
                     "homeOdds":-105
                  },
                  "current":{
                     "awayOdds":101,
                     "homeOdds":-111
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-113,
                     "underOdds":102
                  }
               },
               "openDate":"2021-05-06T18:28:10.354Z",
               "lastUpdated":"2021-05-08T00:48:06.262Z"
            },
            "sportsbetting":{
               "source":"SportsBetting",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":205,
                     "homeOdds":-245
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":215,
                     "homeOdds":-255
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-111,
                     "homeOdds":101
                  },
                  "current":{
                     "awayOdds":100,
                     "homeOdds":-110
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-108,
                     "underOdds":-102
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-120,
                     "underOdds":100
                  }
               },
               "openDate":"2021-05-06T18:28:10.354Z",
               "lastUpdated":"2021-05-08T00:48:06.262Z"
            }
         },
         "bestOdds":{
            "spread":{
               "away":{
                  "away":-1.5,
                  "home":1.5,
                  "awayOdds":225,
                  "homeOdds":-265
               },
               "home":{
                  "away":-1.5,
                  "home":1.5,
                  "awayOdds":215,
                  "homeOdds":-255
               }
            },
            "moneyline":{
               "away":{
                  "awayOdds":101,
                  "homeOdds":-111
               },
               "home":{
                  "awayOdds":100,
                  "homeOdds":-110
               }
            },
            "total":{
               "over":{
                  "total":5.5,
                  "overOdds":-113,
                  "underOdds":102
               },
               "under":{
                  "total":5.5,
                  "overOdds":-113,
                  "underOdds":102
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-08T19:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"New York Rangers @ Boston Bruins",
         "details":{
            "league":"NHL",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":true,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"New York Rangers",
               "location":"New York",
               "mascot":"Rangers",
               "abbreviation":"NYR",
               "conference":"Eastern",
               "division":"Metropolitan",
               "teamId":1087
            },
            "home":{
               "team":"Boston Bruins",
               "location":"Boston",
               "mascot":"Bruins",
               "abbreviation":"BOS",
               "conference":"Eastern",
               "division":"Atlantic",
               "teamId":1003
            }
         },
         "lastUpdated":"2021-05-08T00:09:00.594Z",
         "gameId":258255,
         "odds":{
            "composite":{
               "source":"Composite",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-105,
                     "homeOdds":-115
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-105,
                     "homeOdds":-115
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":269,
                     "homeOdds":-313
                  },
                  "current":{
                     "awayOdds":231,
                     "homeOdds":-270
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-105,
                     "underOdds":-105
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-100,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T13:17:23.691Z",
               "lastUpdated":"2021-05-08T00:48:06.519Z"
            },
            "bovada":{
               "source":"Bovada",
               "moneyline":{
                  "open":{
                     "awayOdds":240,
                     "homeOdds":-290
                  },
                  "current":{
                     "awayOdds":225,
                     "homeOdds":-275
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T16:03:03.986Z",
               "lastUpdated":"2021-05-08T00:48:06.519Z"
            },
            "intertops":{
               "source":"Intertops",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-105,
                     "homeOdds":-115
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-105,
                     "homeOdds":-115
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":235,
                     "homeOdds":-265
                  },
                  "current":{
                     "awayOdds":237,
                     "homeOdds":-267
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-106,
                     "underOdds":-104
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":101,
                     "underOdds":-111
                  }
               },
               "openDate":"2021-05-07T17:35:34.894Z",
               "lastUpdated":"2021-05-08T00:48:06.519Z"
            },
            "betonline":{
               "source":"BetOnline",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-105,
                     "homeOdds":-115
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-105,
                     "homeOdds":-115
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":264,
                     "homeOdds":-300
                  },
                  "current":{
                     "awayOdds":225,
                     "homeOdds":-275
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-105,
                     "underOdds":-105
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T13:17:23.685Z",
               "lastUpdated":"2021-05-08T00:48:06.519Z"
            },
            "betnow":{
               "source":"BetNow",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-105,
                     "homeOdds":-115
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-105,
                     "homeOdds":-115
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":280,
                     "homeOdds":-340
                  },
                  "current":{
                     "awayOdds":237,
                     "homeOdds":-267
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":101,
                     "underOdds":-111
                  }
               },
               "openDate":"2021-05-07T12:35:13.857Z",
               "lastUpdated":"2021-05-08T00:48:06.519Z"
            },
            "sportsbetting":{
               "source":"SportsBetting",
               "moneyline":{
                  "open":{
                     "awayOdds":264,
                     "homeOdds":-300
                  },
                  "current":{
                     "awayOdds":235,
                     "homeOdds":-265
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-105,
                     "underOdds":-105
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-106,
                     "underOdds":-104
                  }
               },
               "openDate":"2021-05-07T13:17:23.685Z",
               "lastUpdated":"2021-05-08T00:48:06.519Z"
            }
         },
         "bestOdds":{
            "spread":{
               "away":{
                  "away":1.5,
                  "home":-1.5,
                  "awayOdds":-105,
                  "homeOdds":-115
               },
               "home":{
                  "away":1.5,
                  "home":-1.5,
                  "awayOdds":-105,
                  "homeOdds":-115
               }
            },
            "moneyline":{
               "away":{
                  "awayOdds":237,
                  "homeOdds":-267
               },
               "home":{
                  "awayOdds":235,
                  "homeOdds":-265
               }
            },
            "total":{
               "over":{
                  "total":5.5,
                  "overOdds":101,
                  "underOdds":-111
               },
               "under":{
                  "total":5.5,
                  "overOdds":-106,
                  "underOdds":-104
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-08T19:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Buffalo Sabres @ Pittsburgh Penguins",
         "details":{
            "league":"NHL",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":true,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Buffalo Sabres",
               "location":"Buffalo",
               "mascot":"Sabres",
               "abbreviation":"BUF",
               "conference":"Eastern",
               "division":"Atlantic",
               "teamId":1001
            },
            "home":{
               "team":"Pittsburgh Penguins",
               "location":"Pittsburgh",
               "mascot":"Penguins",
               "abbreviation":"PIT",
               "conference":"Eastern",
               "division":"Metropolitan",
               "teamId":1011
            }
         },
         "lastUpdated":"2021-05-08T00:09:00.785Z",
         "gameId":258259,
         "odds":{
            "composite":{
               "source":"Composite",
               "moneyline":{
                  "open":{
                     "awayOdds":300,
                     "homeOdds":-350
                  },
                  "current":{
                     "awayOdds":288,
                     "homeOdds":-344
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-145,
                     "underOdds":125
                  },
                  "current":{
                     "total":6,
                     "overOdds":-115,
                     "underOdds":105
                  }
               },
               "openDate":"2021-05-07T13:27:38.673Z",
               "lastUpdated":"2021-05-08T00:48:06.791Z"
            },
            "bovada":{
               "source":"Bovada",
               "moneyline":{
                  "open":{
                     "awayOdds":275,
                     "homeOdds":-350
                  },
                  "current":{
                     "awayOdds":275,
                     "homeOdds":-350
                  }
               },
               "openDate":"2021-05-07T16:03:04.284Z",
               "lastUpdated":"2021-05-08T00:48:06.791Z"
            },
            "intertops":{
               "source":"Intertops",
               "moneyline":{
                  "open":{
                     "awayOdds":305,
                     "homeOdds":-350
                  },
                  "current":{
                     "awayOdds":293,
                     "homeOdds":-335
                  }
               },
               "total":{
                  "open":{
                     "total":6,
                     "overOdds":-115,
                     "underOdds":104
                  },
                  "current":{
                     "total":6,
                     "overOdds":-115,
                     "underOdds":104
                  }
               },
               "openDate":"2021-05-07T17:35:35.156Z",
               "lastUpdated":"2021-05-08T00:48:06.791Z"
            },
            "betonline":{
               "source":"BetOnline",
               "moneyline":{
                  "open":{
                     "awayOdds":305,
                     "homeOdds":-350
                  },
                  "current":{
                     "awayOdds":275,
                     "homeOdds":-350
                  }
               },
               "total":{
                  "open":{
                     "total":6,
                     "overOdds":-113,
                     "underOdds":102
                  },
                  "current":{
                     "total":6,
                     "overOdds":-115,
                     "underOdds":104
                  }
               },
               "openDate":"2021-05-07T13:27:38.668Z",
               "lastUpdated":"2021-05-08T00:48:06.791Z"
            },
            "betnow":{
               "source":"BetNow",
               "moneyline":{
                  "open":{
                     "awayOdds":345,
                     "homeOdds":-430
                  },
                  "current":{
                     "awayOdds":293,
                     "homeOdds":-335
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":125,
                     "underOdds":-145
                  },
                  "current":{
                     "total":6,
                     "overOdds":-115,
                     "underOdds":104
                  }
               },
               "openDate":"2021-05-07T12:35:14.084Z",
               "lastUpdated":"2021-05-08T00:48:06.791Z"
            },
            "sportsbetting":{
               "source":"SportsBetting",
               "moneyline":{
                  "open":{
                     "awayOdds":305,
                     "homeOdds":-350
                  },
                  "current":{
                     "awayOdds":305,
                     "homeOdds":-350
                  }
               },
               "total":{
                  "open":{
                     "total":6,
                     "overOdds":-113,
                     "underOdds":102
                  },
                  "current":{
                     "total":6,
                     "overOdds":-115,
                     "underOdds":104
                  }
               },
               "openDate":"2021-05-07T13:27:38.668Z",
               "lastUpdated":"2021-05-08T00:48:06.791Z"
            }
         },
         "bestOdds":{
            "spread":{
               
            },
            "moneyline":{
               "away":{
                  "awayOdds":305,
                  "homeOdds":-350
               },
               "home":{
                  "awayOdds":293,
                  "homeOdds":-335
               }
            },
            "total":{
               "over":{
                  "total":6,
                  "overOdds":-115,
                  "underOdds":104
               },
               "under":{
                  "total":6,
                  "overOdds":-115,
                  "underOdds":104
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-08T23:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"New Jersey Devils @ New York Islanders",
         "details":{
            "league":"NHL",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":true,
            "divisionGame":true
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"New Jersey Devils",
               "location":"New Jersey",
               "mascot":"Devils",
               "abbreviation":"NJD",
               "conference":"Eastern",
               "division":"Metropolitan",
               "teamId":1325
            },
            "home":{
               "team":"New York Islanders",
               "location":"New York",
               "mascot":"Islanders",
               "abbreviation":"NYI",
               "conference":"Eastern",
               "division":"Metropolitan",
               "teamId":1010
            }
         },
         "lastUpdated":"2021-05-08T00:09:00.983Z",
         "gameId":258256,
         "odds":{
            "composite":{
               "source":"Composite",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-130,
                     "homeOdds":110
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-130,
                     "homeOdds":110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":220,
                     "homeOdds":-251
                  },
                  "current":{
                     "awayOdds":193,
                     "homeOdds":-222
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":105,
                     "underOdds":-115
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":100,
                     "underOdds":-115
                  }
               },
               "openDate":"2021-05-07T13:27:39.207Z",
               "lastUpdated":"2021-05-08T00:48:07.054Z"
            },
            "bovada":{
               "source":"Bovada",
               "moneyline":{
                  "open":{
                     "awayOdds":210,
                     "homeOdds":-250
                  },
                  "current":{
                     "awayOdds":185,
                     "homeOdds":-225
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-105,
                     "underOdds":-115
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-105,
                     "underOdds":-115
                  }
               },
               "openDate":"2021-05-07T16:03:04.863Z",
               "lastUpdated":"2021-05-08T00:48:07.054Z"
            },
            "intertops":{
               "source":"Intertops",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-130,
                     "homeOdds":110
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-130,
                     "homeOdds":110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":197,
                     "homeOdds":-220
                  },
                  "current":{
                     "awayOdds":197,
                     "homeOdds":-220
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":104,
                     "underOdds":-115
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":108,
                     "underOdds":-119
                  }
               },
               "openDate":"2021-05-07T17:35:35.420Z",
               "lastUpdated":"2021-05-08T00:48:07.054Z"
            },
            "betonline":{
               "source":"BetOnline",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-130,
                     "homeOdds":110
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-130,
                     "homeOdds":110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":222,
                     "homeOdds":-250
                  },
                  "current":{
                     "awayOdds":185,
                     "homeOdds":-225
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":104,
                     "underOdds":-115
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-105,
                     "underOdds":-115
                  }
               },
               "openDate":"2021-05-07T13:27:39.202Z",
               "lastUpdated":"2021-05-08T00:48:07.054Z"
            },
            "betnow":{
               "source":"BetNow",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-130,
                     "homeOdds":110
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-130,
                     "homeOdds":110
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":255,
                     "homeOdds":-310
                  },
                  "current":{
                     "awayOdds":197,
                     "homeOdds":-220
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-105,
                     "underOdds":-115
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":108,
                     "underOdds":-119
                  }
               },
               "openDate":"2021-05-07T12:46:52.982Z",
               "lastUpdated":"2021-05-08T00:48:07.054Z"
            },
            "sportsbetting":{
               "source":"SportsBetting",
               "moneyline":{
                  "open":{
                     "awayOdds":222,
                     "homeOdds":-250
                  },
                  "current":{
                     "awayOdds":197,
                     "homeOdds":-220
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":104,
                     "underOdds":-115
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":104,
                     "underOdds":-115
                  }
               },
               "openDate":"2021-05-07T13:27:39.202Z",
               "lastUpdated":"2021-05-08T00:48:07.054Z"
            }
         },
         "bestOdds":{
            "spread":{
               "away":{
                  "away":1.5,
                  "home":-1.5,
                  "awayOdds":-130,
                  "homeOdds":110
               },
               "home":{
                  "away":1.5,
                  "home":-1.5,
                  "awayOdds":-130,
                  "homeOdds":110
               }
            },
            "moneyline":{
               "away":{
                  "awayOdds":197,
                  "homeOdds":-220
               },
               "home":{
                  "awayOdds":197,
                  "homeOdds":-220
               }
            },
            "total":{
               "over":{
                  "total":5.5,
                  "overOdds":108,
                  "underOdds":-119
               },
               "under":{
                  "total":5.5,
                  "overOdds":104,
                  "underOdds":-115
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-08T23:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Philadelphia Flyers @ Washington Capitals",
         "details":{
            "league":"NHL",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":true,
            "divisionGame":true
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Philadelphia Flyers",
               "location":"Philadelphia",
               "mascot":"Flyers",
               "abbreviation":"PHI",
               "conference":"Eastern",
               "division":"Metropolitan",
               "teamId":1243
            },
            "home":{
               "team":"Washington Capitals",
               "location":"Washington",
               "mascot":"Capitals",
               "abbreviation":"WSH",
               "conference":"Eastern",
               "division":"Metropolitan",
               "teamId":1089
            }
         },
         "lastUpdated":"2021-05-08T00:09:01.203Z",
         "gameId":258257,
         "odds":{
            "composite":{
               "source":"Composite",
               "moneyline":{
                  "open":{
                     "awayOdds":164,
                     "homeOdds":-189
                  },
                  "current":{
                     "awayOdds":164,
                     "homeOdds":-189
                  }
               },
               "total":{
                  "open":{
                     "total":6.5,
                     "overOdds":105,
                     "underOdds":-125
                  },
                  "current":{
                     "total":6.5,
                     "overOdds":105,
                     "underOdds":-125
                  }
               },
               "openDate":"2021-05-07T17:35:35.675Z",
               "lastUpdated":"2021-05-08T00:48:07.318Z"
            },
            "bovada":{
               "source":"Bovada",
               "moneyline":{
                  "open":{
                     "awayOdds":165,
                     "homeOdds":-190
                  },
                  "current":{
                     "awayOdds":165,
                     "homeOdds":-190
                  }
               },
               "openDate":"2021-05-07T16:03:05.197Z",
               "lastUpdated":"2021-05-08T00:48:07.318Z"
            },
            "betonline":{
               "source":"BetOnline",
               "moneyline":{
                  "open":{
                     "awayOdds":165,
                     "homeOdds":-190
                  },
                  "current":{
                     "awayOdds":165,
                     "homeOdds":-190
                  }
               },
               "total":{
                  "open":{
                     "total":6.5,
                     "overOdds":105,
                     "underOdds":-125
                  },
                  "current":{
                     "total":6.5,
                     "overOdds":105,
                     "underOdds":-125
                  }
               },
               "openDate":"2021-05-07T17:35:35.669Z",
               "lastUpdated":"2021-05-08T00:48:07.318Z"
            },
            "betnow":{
               "source":"BetNow",
               "moneyline":{
                  "open":{
                     "awayOdds":168,
                     "homeOdds":-195
                  },
                  "current":{
                     "awayOdds":160,
                     "homeOdds":-185
                  }
               },
               "total":{
                  "open":{
                     "total":6.5,
                     "overOdds":105,
                     "underOdds":-125
                  },
                  "current":{
                     "total":6.5,
                     "overOdds":105,
                     "underOdds":-125
                  }
               },
               "openDate":"2021-05-07T12:35:14.538Z",
               "lastUpdated":"2021-05-08T00:48:07.318Z"
            }
         },
         "bestOdds":{
            "spread":{
               
            },
            "moneyline":{
               "away":{
                  "awayOdds":165,
                  "homeOdds":-190
               },
               "home":{
                  "awayOdds":160,
                  "homeOdds":-185
               }
            },
            "total":{
               "over":{
                  "total":6.5,
                  "overOdds":105,
                  "underOdds":-125
               },
               "under":{
                  "total":6.5,
                  "overOdds":105,
                  "underOdds":-125
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-08T23:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Tampa Bay Lightning @ Florida Panthers",
         "details":{
            "league":"NHL",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":true,
            "divisionGame":true
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Tampa Bay Lightning",
               "location":"Tampa Bay",
               "mascot":"Lightning",
               "abbreviation":"TBL",
               "conference":"Eastern",
               "division":"Atlantic",
               "teamId":1244
            },
            "home":{
               "team":"Florida Panthers",
               "location":"Florida",
               "mascot":"Panthers",
               "abbreviation":"FLA",
               "conference":"Eastern",
               "division":"Atlantic",
               "teamId":1241
            }
         },
         "lastUpdated":"2021-05-08T00:09:01.409Z",
         "gameId":258258,
         "odds":{
            "betnow":{
               "source":"BetNow",
               "moneyline":{
                  "open":{
                     "awayOdds":-140,
                     "homeOdds":120
                  },
                  "current":{
                     "awayOdds":-125,
                     "homeOdds":105
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-135,
                     "underOdds":115
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-135,
                     "underOdds":115
                  }
               },
               "openDate":"2021-05-07T12:24:45.201Z",
               "lastUpdated":"2021-05-07T17:23:16.247Z"
            }
         },
         "bestOdds":{
            "spread":{
               
            },
            "moneyline":{
               "away":{
                  "awayOdds":-125,
                  "homeOdds":105
               },
               "home":{
                  "awayOdds":-125,
                  "homeOdds":105
               }
            },
            "total":{
               "over":{
                  "total":5.5,
                  "overOdds":-135,
                  "underOdds":115
               },
               "under":{
                  "total":5.5,
                  "overOdds":-135,
                  "underOdds":115
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-08T23:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Detroit Red Wings @ Columbus Blue Jackets",
         "details":{
            "league":"NHL",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":true,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Detroit Red Wings",
               "location":"Detroit",
               "mascot":"Red Wings",
               "abbreviation":"DET",
               "conference":"Eastern",
               "division":"Atlantic",
               "teamId":1324
            },
            "home":{
               "team":"Columbus Blue Jackets",
               "location":"Columbus",
               "mascot":"Blue Jackets",
               "abbreviation":"CBJ",
               "conference":"Eastern",
               "division":"Metropolitan",
               "teamId":1008
            }
         },
         "lastUpdated":"2021-05-08T00:09:01.608Z",
         "gameId":258260,
         "odds":{
            "composite":{
               "source":"Composite",
               "moneyline":{
                  "open":{
                     "awayOdds":130,
                     "homeOdds":-150
                  },
                  "current":{
                     "awayOdds":130,
                     "homeOdds":-150
                  }
               },
               "total":{
                  "open":{
                     "total":5,
                     "overOdds":-125,
                     "underOdds":105
                  },
                  "current":{
                     "total":5,
                     "overOdds":-125,
                     "underOdds":105
                  }
               },
               "openDate":"2021-05-07T17:35:35.928Z",
               "lastUpdated":"2021-05-08T00:48:07.581Z"
            },
            "bovada":{
               "source":"Bovada",
               "moneyline":{
                  "open":{
                     "awayOdds":130,
                     "homeOdds":-150
                  },
                  "current":{
                     "awayOdds":130,
                     "homeOdds":-150
                  }
               },
               "openDate":"2021-05-07T16:03:05.456Z",
               "lastUpdated":"2021-05-08T00:48:07.581Z"
            },
            "betonline":{
               "source":"BetOnline",
               "moneyline":{
                  "open":{
                     "awayOdds":130,
                     "homeOdds":-150
                  },
                  "current":{
                     "awayOdds":130,
                     "homeOdds":-150
                  }
               },
               "openDate":"2021-05-07T17:35:35.923Z",
               "lastUpdated":"2021-05-08T00:48:07.581Z"
            },
            "betnow":{
               "source":"BetNow",
               "moneyline":{
                  "open":{
                     "awayOdds":130,
                     "homeOdds":-150
                  },
                  "current":{
                     "awayOdds":130,
                     "homeOdds":-150
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":125,
                     "underOdds":-145
                  },
                  "current":{
                     "total":5,
                     "overOdds":-125,
                     "underOdds":105
                  }
               },
               "openDate":"2021-05-07T12:35:15.909Z",
               "lastUpdated":"2021-05-08T00:48:07.581Z"
            }
         },
         "bestOdds":{
            "spread":{
               
            },
            "moneyline":{
               "away":{
                  "awayOdds":130,
                  "homeOdds":-150
               },
               "home":{
                  "awayOdds":130,
                  "homeOdds":-150
               }
            },
            "total":{
               "over":{
                  "total":5,
                  "overOdds":-125,
                  "underOdds":105
               },
               "under":{
                  "total":5,
                  "overOdds":-125,
                  "underOdds":105
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-08T23:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Montreal Canadiens @ Toronto Maple Leafs",
         "details":{
            "league":"NHL",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":true,
            "divisionGame":true
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Montreal Canadiens",
               "location":"Montreal",
               "mascot":"Canadiens",
               "abbreviation":"MTL",
               "conference":"Eastern",
               "division":"Atlantic",
               "teamId":1166
            },
            "home":{
               "team":"Toronto Maple Leafs",
               "location":"Toronto",
               "mascot":"Maple Leafs",
               "abbreviation":"TOR",
               "conference":"Eastern",
               "division":"Atlantic",
               "teamId":1326
            }
         },
         "lastUpdated":"2021-05-08T00:09:01.838Z",
         "gameId":258263,
         "odds":{
            "composite":{
               "source":"Composite",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-145,
                     "homeOdds":125
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-150,
                     "homeOdds":130
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":177,
                     "homeOdds":-201
                  },
                  "current":{
                     "awayOdds":173,
                     "homeOdds":-197
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-125,
                     "underOdds":115
                  },
                  "current":{
                     "total":6,
                     "overOdds":110,
                     "underOdds":-120
                  }
               },
               "openDate":"2021-05-07T13:17:25.095Z",
               "lastUpdated":"2021-05-08T00:48:07.865Z"
            },
            "bovada":{
               "source":"Bovada",
               "moneyline":{
                  "open":{
                     "awayOdds":170,
                     "homeOdds":-200
                  },
                  "current":{
                     "awayOdds":170,
                     "homeOdds":-200
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-125,
                     "underOdds":105
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-125,
                     "underOdds":105
                  }
               },
               "openDate":"2021-05-07T16:03:05.740Z",
               "lastUpdated":"2021-05-08T00:48:07.865Z"
            },
            "intertops":{
               "source":"Intertops",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-145,
                     "homeOdds":125
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-150,
                     "homeOdds":130
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":185,
                     "homeOdds":-206
                  },
                  "current":{
                     "awayOdds":171,
                     "homeOdds":-190
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-125,
                     "underOdds":113
                  },
                  "current":{
                     "total":6,
                     "overOdds":112,
                     "underOdds":-123
                  }
               },
               "openDate":"2021-05-07T17:35:36.364Z",
               "lastUpdated":"2021-05-08T00:48:07.865Z"
            },
            "betonline":{
               "source":"BetOnline",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-145,
                     "homeOdds":125
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-145,
                     "homeOdds":125
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":177,
                     "homeOdds":-197
                  },
                  "current":{
                     "awayOdds":170,
                     "homeOdds":-200
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-125,
                     "underOdds":113
                  },
                  "current":{
                     "total":6,
                     "overOdds":105,
                     "underOdds":-125
                  }
               },
               "openDate":"2021-05-07T13:17:25.090Z",
               "lastUpdated":"2021-05-08T00:48:07.865Z"
            },
            "betnow":{
               "source":"BetNow",
               "spread":{
                  "open":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-145,
                     "homeOdds":125
                  },
                  "current":{
                     "away":1.5,
                     "home":-1.5,
                     "awayOdds":-150,
                     "homeOdds":130
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":175,
                     "homeOdds":-210
                  },
                  "current":{
                     "awayOdds":171,
                     "homeOdds":-190
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-130,
                     "underOdds":110
                  },
                  "current":{
                     "total":6,
                     "overOdds":112,
                     "underOdds":-123
                  }
               },
               "openDate":"2021-05-07T12:35:16.163Z",
               "lastUpdated":"2021-05-08T00:48:07.865Z"
            },
            "sportsbetting":{
               "source":"SportsBetting",
               "moneyline":{
                  "open":{
                     "awayOdds":177,
                     "homeOdds":-197
                  },
                  "current":{
                     "awayOdds":185,
                     "homeOdds":-206
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-125,
                     "underOdds":113
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-125,
                     "underOdds":113
                  }
               },
               "openDate":"2021-05-07T13:17:25.090Z",
               "lastUpdated":"2021-05-08T00:48:07.865Z"
            }
         },
         "bestOdds":{
            "spread":{
               "away":{
                  "away":1.5,
                  "home":-1.5,
                  "awayOdds":-145,
                  "homeOdds":125
               },
               "home":{
                  "away":1.5,
                  "home":-1.5,
                  "awayOdds":-150,
                  "homeOdds":130
               }
            },
            "moneyline":{
               "away":{
                  "awayOdds":185,
                  "homeOdds":-206
               },
               "home":{
                  "awayOdds":171,
                  "homeOdds":-190
               }
            },
            "total":{
               "over":{
                  "total":5.5,
                  "overOdds":-125,
                  "underOdds":113
               },
               "under":{
                  "total":6,
                  "overOdds":112,
                  "underOdds":-123
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-08T23:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Ottawa Senators @ Winnipeg Jets",
         "details":{
            "league":"NHL",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":false,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Ottawa Senators",
               "location":"Ottawa",
               "mascot":"Senators",
               "abbreviation":"OTT",
               "conference":"Eastern",
               "division":"Atlantic",
               "teamId":1167
            },
            "home":{
               "team":"Winnipeg Jets",
               "location":"Winnipeg",
               "mascot":"Jets",
               "abbreviation":"WPG",
               "conference":"Western",
               "division":"Central",
               "teamId":1169
            }
         },
         "lastUpdated":"2021-05-08T00:09:02.046Z",
         "gameId":258264,
         "odds":{
            "composite":{
               "source":"Composite",
               "moneyline":{
                  "open":{
                     "awayOdds":160,
                     "homeOdds":-180
                  },
                  "current":{
                     "awayOdds":160,
                     "homeOdds":-180
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-120,
                     "underOdds":110
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-120,
                     "underOdds":110
                  }
               },
               "openDate":"2021-05-07T22:00:43.064Z",
               "lastUpdated":"2021-05-08T00:48:08.135Z"
            },
            "intertops":{
               "source":"Intertops",
               "moneyline":{
                  "open":{
                     "awayOdds":158,
                     "homeOdds":-175
                  },
                  "current":{
                     "awayOdds":158,
                     "homeOdds":-175
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-120,
                     "underOdds":109
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-120,
                     "underOdds":109
                  }
               },
               "openDate":"2021-05-07T22:00:43.059Z",
               "lastUpdated":"2021-05-08T00:48:08.135Z"
            },
            "betonline":{
               "source":"BetOnline",
               "moneyline":{
                  "open":{
                     "awayOdds":165,
                     "homeOdds":-190
                  },
                  "current":{
                     "awayOdds":165,
                     "homeOdds":-190
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-125,
                     "underOdds":105
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-125,
                     "underOdds":105
                  }
               },
               "openDate":"2021-05-07T22:00:43.059Z",
               "lastUpdated":"2021-05-08T00:48:08.135Z"
            },
            "betnow":{
               "source":"BetNow",
               "moneyline":{
                  "open":{
                     "awayOdds":158,
                     "homeOdds":-175
                  },
                  "current":{
                     "awayOdds":158,
                     "homeOdds":-175
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-120,
                     "underOdds":109
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-120,
                     "underOdds":109
                  }
               },
               "openDate":"2021-05-07T22:00:43.059Z",
               "lastUpdated":"2021-05-08T00:48:08.135Z"
            }
         },
         "bestOdds":{
            "spread":{
               
            },
            "moneyline":{
               "away":{
                  "awayOdds":165,
                  "homeOdds":-190
               },
               "home":{
                  "awayOdds":158,
                  "homeOdds":-175
               }
            },
            "total":{
               "over":{
                  "total":5.5,
                  "overOdds":-120,
                  "underOdds":109
               },
               "under":{
                  "total":5.5,
                  "overOdds":-120,
                  "underOdds":109
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-09T00:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Carolina Hurricanes @ Nashville Predators",
         "details":{
            "league":"NHL",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":false,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Carolina Hurricanes",
               "location":"Carolina",
               "mascot":"Hurricanes",
               "abbreviation":"CAR",
               "conference":"Eastern",
               "division":"Metropolitan",
               "teamId":1007
            },
            "home":{
               "team":"Nashville Predators",
               "location":"Nashville",
               "mascot":"Predators",
               "abbreviation":"NSH",
               "conference":"Western",
               "division":"Central",
               "teamId":1242
            }
         },
         "lastUpdated":"2021-05-08T00:09:02.247Z",
         "gameId":258262,
         "odds":{
            "composite":{
               "source":"Composite",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":175,
                     "homeOdds":-205
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":175,
                     "homeOdds":-205
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-127,
                     "homeOdds":113
                  },
                  "current":{
                     "awayOdds":-140,
                     "homeOdds":125
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":105,
                     "underOdds":-120
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-100,
                     "underOdds":-105
                  }
               },
               "openDate":"2021-05-07T13:27:40.403Z",
               "lastUpdated":"2021-05-08T00:48:08.653Z"
            },
            "bovada":{
               "source":"Bovada",
               "moneyline":{
                  "open":{
                     "awayOdds":-135,
                     "homeOdds":115
                  },
                  "current":{
                     "awayOdds":-140,
                     "homeOdds":120
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-105,
                     "underOdds":-115
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-105,
                     "underOdds":-115
                  }
               },
               "openDate":"2021-05-07T16:03:06.334Z",
               "lastUpdated":"2021-05-08T00:48:08.653Z"
            },
            "intertops":{
               "source":"Intertops",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":175,
                     "homeOdds":-205
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":175,
                     "homeOdds":-205
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-140,
                     "homeOdds":127
                  },
                  "current":{
                     "awayOdds":-140,
                     "homeOdds":127
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-104,
                     "underOdds":-106
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-104,
                     "underOdds":-106
                  }
               },
               "openDate":"2021-05-07T17:35:37.007Z",
               "lastUpdated":"2021-05-08T00:48:08.653Z"
            },
            "betonline":{
               "source":"BetOnline",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":170,
                     "homeOdds":-200
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":170,
                     "homeOdds":-200
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-125,
                     "homeOdds":113
                  },
                  "current":{
                     "awayOdds":-140,
                     "homeOdds":120
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":105,
                     "underOdds":-116
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-105,
                     "underOdds":-115
                  }
               },
               "openDate":"2021-05-07T13:27:40.399Z",
               "lastUpdated":"2021-05-08T00:48:08.653Z"
            },
            "betnow":{
               "source":"BetNow",
               "spread":{
                  "open":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":175,
                     "homeOdds":-205
                  },
                  "current":{
                     "away":-1.5,
                     "home":1.5,
                     "awayOdds":175,
                     "homeOdds":-205
                  }
               },
               "moneyline":{
                  "open":{
                     "awayOdds":-150,
                     "homeOdds":130
                  },
                  "current":{
                     "awayOdds":-140,
                     "homeOdds":127
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-105,
                     "underOdds":-115
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-104,
                     "underOdds":-106
                  }
               },
               "openDate":"2021-05-07T12:46:54.102Z",
               "lastUpdated":"2021-05-08T00:48:08.653Z"
            },
            "sportsbetting":{
               "source":"SportsBetting",
               "moneyline":{
                  "open":{
                     "awayOdds":-125,
                     "homeOdds":113
                  },
                  "current":{
                     "awayOdds":-140,
                     "homeOdds":127
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":105,
                     "underOdds":-116
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-104,
                     "underOdds":-106
                  }
               },
               "openDate":"2021-05-07T13:27:40.399Z",
               "lastUpdated":"2021-05-08T00:48:08.653Z"
            }
         },
         "bestOdds":{
            "spread":{
               "away":{
                  "away":-1.5,
                  "home":1.5,
                  "awayOdds":175,
                  "homeOdds":-205
               },
               "home":{
                  "away":-1.5,
                  "home":1.5,
                  "awayOdds":170,
                  "homeOdds":-200
               }
            },
            "moneyline":{
               "away":{
                  "awayOdds":-140,
                  "homeOdds":127
               },
               "home":{
                  "awayOdds":-140,
                  "homeOdds":127
               }
            },
            "total":{
               "over":{
                  "total":5.5,
                  "overOdds":-104,
                  "underOdds":-106
               },
               "under":{
                  "total":5.5,
                  "overOdds":-104,
                  "underOdds":-106
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-09T00:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Anaheim Ducks @ Minnesota Wild",
         "details":{
            "league":"NHL",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":true,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Anaheim Ducks",
               "location":"Anaheim",
               "mascot":"Ducks",
               "abbreviation":"ANA",
               "conference":"Western",
               "division":"Pacific",
               "teamId":1002
            },
            "home":{
               "team":"Minnesota Wild",
               "location":"Minnesota",
               "mascot":"Wild",
               "abbreviation":"MIN",
               "conference":"Western",
               "division":"Central",
               "teamId":1086
            }
         },
         "lastUpdated":"2021-05-08T00:09:02.444Z",
         "gameId":258266,
         "odds":{
            "composite":{
               "source":"Composite",
               "moneyline":{
                  "open":{
                     "awayOdds":233,
                     "homeOdds":-280
                  },
                  "current":{
                     "awayOdds":233,
                     "homeOdds":-280
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T17:35:36.729Z",
               "lastUpdated":"2021-05-08T00:48:08.402Z"
            },
            "bovada":{
               "source":"Bovada",
               "moneyline":{
                  "open":{
                     "awayOdds":230,
                     "homeOdds":-280
                  },
                  "current":{
                     "awayOdds":230,
                     "homeOdds":-280
                  }
               },
               "openDate":"2021-05-07T16:03:06.068Z",
               "lastUpdated":"2021-05-08T00:48:08.402Z"
            },
            "betonline":{
               "source":"BetOnline",
               "moneyline":{
                  "open":{
                     "awayOdds":230,
                     "homeOdds":-280
                  },
                  "current":{
                     "awayOdds":230,
                     "homeOdds":-280
                  }
               },
               "openDate":"2021-05-07T17:35:36.724Z",
               "lastUpdated":"2021-05-08T00:48:08.402Z"
            },
            "betnow":{
               "source":"BetNow",
               "moneyline":{
                  "open":{
                     "awayOdds":270,
                     "homeOdds":-330
                  },
                  "current":{
                     "awayOdds":240,
                     "homeOdds":-280
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T12:46:53.880Z",
               "lastUpdated":"2021-05-08T00:48:08.402Z"
            }
         },
         "bestOdds":{
            "spread":{
               
            },
            "moneyline":{
               "away":{
                  "awayOdds":240,
                  "homeOdds":-280
               },
               "home":{
                  "awayOdds":240,
                  "homeOdds":-280
               }
            },
            "total":{
               "over":{
                  "total":5.5,
                  "overOdds":-110,
                  "underOdds":-110
               },
               "under":{
                  "total":5.5,
                  "overOdds":-110,
                  "underOdds":-110
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-09T02:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Vancouver Canucks @ Edmonton Oilers",
         "details":{
            "league":"NHL",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":true,
            "divisionGame":true
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Vancouver Canucks",
               "location":"Vancouver",
               "mascot":"Canucks",
               "abbreviation":"VAN",
               "conference":"Western",
               "division":"Pacific",
               "teamId":1012
            },
            "home":{
               "team":"Edmonton Oilers",
               "location":"Edmonton",
               "mascot":"Oilers",
               "abbreviation":"EDM",
               "conference":"Western",
               "division":"Pacific",
               "teamId":1165
            }
         },
         "lastUpdated":"2021-05-08T00:09:02.639Z",
         "gameId":258063,
         "odds":{
            "composite":{
               "source":"Composite",
               "moneyline":{
                  "open":{
                     "awayOdds":241,
                     "homeOdds":-275
                  },
                  "current":{
                     "awayOdds":199,
                     "homeOdds":-230
                  }
               },
               "total":{
                  "open":{
                     "total":6,
                     "overOdds":-120,
                     "underOdds":110
                  },
                  "current":{
                     "total":6,
                     "overOdds":-120,
                     "underOdds":100
                  }
               },
               "openDate":"2021-05-07T13:27:41.159Z",
               "lastUpdated":"2021-05-08T00:48:09.408Z"
            },
            "bovada":{
               "source":"Bovada",
               "moneyline":{
                  "open":{
                     "awayOdds":210,
                     "homeOdds":-250
                  },
                  "current":{
                     "awayOdds":200,
                     "homeOdds":-240
                  }
               },
               "total":{
                  "open":{
                     "total":6,
                     "overOdds":-120,
                     "underOdds":100
                  },
                  "current":{
                     "total":6,
                     "overOdds":-120,
                     "underOdds":100
                  }
               },
               "openDate":"2021-05-07T16:03:08.342Z",
               "lastUpdated":"2021-05-08T00:48:09.408Z"
            },
            "intertops":{
               "source":"Intertops",
               "moneyline":{
                  "open":{
                     "awayOdds":214,
                     "homeOdds":-240
                  },
                  "current":{
                     "awayOdds":197,
                     "homeOdds":-220
                  }
               },
               "total":{
                  "open":{
                     "total":6,
                     "overOdds":-120,
                     "underOdds":109
                  },
                  "current":{
                     "total":6,
                     "overOdds":-115,
                     "underOdds":104
                  }
               },
               "openDate":"2021-05-07T17:35:37.879Z",
               "lastUpdated":"2021-05-08T00:48:09.408Z"
            },
            "betonline":{
               "source":"BetOnline",
               "moneyline":{
                  "open":{
                     "awayOdds":243,
                     "homeOdds":-275
                  },
                  "current":{
                     "awayOdds":200,
                     "homeOdds":-240
                  }
               },
               "total":{
                  "open":{
                     "total":6,
                     "overOdds":-120,
                     "underOdds":109
                  },
                  "current":{
                     "total":6,
                     "overOdds":-120,
                     "underOdds":100
                  }
               },
               "openDate":"2021-05-07T13:27:41.153Z",
               "lastUpdated":"2021-05-08T00:48:09.408Z"
            },
            "betnow":{
               "source":"BetNow",
               "moneyline":{
                  "open":{
                     "awayOdds":258,
                     "homeOdds":-315
                  },
                  "current":{
                     "awayOdds":197,
                     "homeOdds":-220
                  }
               },
               "total":{
                  "open":{
                     "total":6,
                     "overOdds":-118,
                     "underOdds":-102
                  },
                  "current":{
                     "total":6,
                     "overOdds":-115,
                     "underOdds":104
                  }
               },
               "openDate":"2021-05-07T12:54:54.989Z",
               "lastUpdated":"2021-05-08T00:48:09.408Z"
            },
            "sportsbetting":{
               "source":"SportsBetting",
               "moneyline":{
                  "open":{
                     "awayOdds":243,
                     "homeOdds":-275
                  },
                  "current":{
                     "awayOdds":205,
                     "homeOdds":-229
                  }
               },
               "total":{
                  "open":{
                     "total":6,
                     "overOdds":-120,
                     "underOdds":109
                  },
                  "current":{
                     "total":6,
                     "overOdds":-120,
                     "underOdds":109
                  }
               },
               "openDate":"2021-05-07T13:27:41.153Z",
               "lastUpdated":"2021-05-08T00:48:09.408Z"
            }
         },
         "bestOdds":{
            "spread":{
               
            },
            "moneyline":{
               "away":{
                  "awayOdds":205,
                  "homeOdds":-229
               },
               "home":{
                  "awayOdds":197,
                  "homeOdds":-220
               }
            },
            "total":{
               "over":{
                  "total":6,
                  "overOdds":-115,
                  "underOdds":104
               },
               "under":{
                  "total":6,
                  "overOdds":-120,
                  "underOdds":109
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-09T02:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"St. Louis Blues @ Vegas Golden Knights",
         "details":{
            "league":"NHL",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":true,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"St. Louis Blues",
               "location":"St. Louis",
               "mascot":"Blues",
               "abbreviation":"STL",
               "conference":"Western",
               "division":"Central",
               "teamId":1168
            },
            "home":{
               "team":"Vegas Golden Knights",
               "location":"Vegas",
               "mascot":"Golden Knights",
               "abbreviation":"VGK",
               "conference":"Western",
               "division":"Pacific",
               "teamId":1245
            }
         },
         "lastUpdated":"2021-05-08T00:09:02.856Z",
         "gameId":258267,
         "odds":{
            "composite":{
               "source":"Composite",
               "moneyline":{
                  "open":{
                     "awayOdds":140,
                     "homeOdds":-160
                  },
                  "current":{
                     "awayOdds":143,
                     "homeOdds":-163
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T17:35:37.619Z",
               "lastUpdated":"2021-05-08T00:48:09.145Z"
            },
            "bovada":{
               "source":"Bovada",
               "moneyline":{
                  "open":{
                     "awayOdds":140,
                     "homeOdds":-160
                  },
                  "current":{
                     "awayOdds":140,
                     "homeOdds":-160
                  }
               },
               "openDate":"2021-05-07T16:03:06.844Z",
               "lastUpdated":"2021-05-08T00:48:09.145Z"
            },
            "betonline":{
               "source":"BetOnline",
               "moneyline":{
                  "open":{
                     "awayOdds":140,
                     "homeOdds":-160
                  },
                  "current":{
                     "awayOdds":150,
                     "homeOdds":-170
                  }
               },
               "openDate":"2021-05-07T17:35:37.614Z",
               "lastUpdated":"2021-05-08T00:48:09.145Z"
            },
            "betnow":{
               "source":"BetNow",
               "moneyline":{
                  "open":{
                     "awayOdds":135,
                     "homeOdds":-155
                  },
                  "current":{
                     "awayOdds":140,
                     "homeOdds":-160
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-110,
                     "underOdds":-110
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-110,
                     "underOdds":-110
                  }
               },
               "openDate":"2021-05-07T12:46:54.545Z",
               "lastUpdated":"2021-05-08T00:48:09.145Z"
            }
         },
         "bestOdds":{
            "spread":{
               
            },
            "moneyline":{
               "away":{
                  "awayOdds":150,
                  "homeOdds":-170
               },
               "home":{
                  "awayOdds":140,
                  "homeOdds":-160
               }
            },
            "total":{
               "over":{
                  "total":5.5,
                  "overOdds":-110,
                  "underOdds":-110
               },
               "under":{
                  "total":5.5,
                  "overOdds":-110,
                  "underOdds":-110
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-09T02:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Colorado Avalanche @ Los Angeles Kings",
         "details":{
            "league":"NHL",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":true,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Colorado Avalanche",
               "location":"Colorado",
               "mascot":"Avalanche",
               "abbreviation":"COL",
               "conference":"Western",
               "division":"Central",
               "teamId":1005
            },
            "home":{
               "team":"Los Angeles Kings",
               "location":"Los Angeles",
               "mascot":"Kings",
               "abbreviation":"LAK",
               "conference":"Western",
               "division":"Pacific",
               "teamId":1009
            }
         },
         "lastUpdated":"2021-05-08T00:09:03.076Z",
         "gameId":258268,
         "odds":{
            "composite":{
               "source":"Composite",
               "moneyline":{
                  "open":{
                     "awayOdds":-279,
                     "homeOdds":231
                  },
                  "current":{
                     "awayOdds":-279,
                     "homeOdds":231
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-125,
                     "underOdds":105
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-125,
                     "underOdds":105
                  }
               },
               "openDate":"2021-05-07T17:35:37.322Z",
               "lastUpdated":"2021-05-08T00:48:08.893Z"
            },
            "bovada":{
               "source":"Bovada",
               "moneyline":{
                  "open":{
                     "awayOdds":-280,
                     "homeOdds":230
                  },
                  "current":{
                     "awayOdds":-280,
                     "homeOdds":230
                  }
               },
               "openDate":"2021-05-07T16:03:06.604Z",
               "lastUpdated":"2021-05-08T00:48:08.893Z"
            },
            "betonline":{
               "source":"BetOnline",
               "moneyline":{
                  "open":{
                     "awayOdds":-280,
                     "homeOdds":230
                  },
                  "current":{
                     "awayOdds":-280,
                     "homeOdds":230
                  }
               },
               "openDate":"2021-05-07T17:35:37.317Z",
               "lastUpdated":"2021-05-08T00:48:08.893Z"
            },
            "betnow":{
               "source":"BetNow",
               "moneyline":{
                  "open":{
                     "awayOdds":-320,
                     "homeOdds":260
                  },
                  "current":{
                     "awayOdds":-275,
                     "homeOdds":235
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-130,
                     "underOdds":110
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-125,
                     "underOdds":105
                  }
               },
               "openDate":"2021-05-07T12:46:54.324Z",
               "lastUpdated":"2021-05-08T00:48:08.893Z"
            }
         },
         "bestOdds":{
            "spread":{
               
            },
            "moneyline":{
               "away":{
                  "awayOdds":-275,
                  "homeOdds":235
               },
               "home":{
                  "awayOdds":-275,
                  "homeOdds":235
               }
            },
            "total":{
               "over":{
                  "total":5.5,
                  "overOdds":-125,
                  "underOdds":105
               },
               "under":{
                  "total":5.5,
                  "overOdds":-125,
                  "underOdds":105
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-09T02:30:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Arizona Coyotes @ San Jose Sharks",
         "details":{
            "league":"NHL",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":true,
            "divisionGame":true
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Arizona Coyotes",
               "location":"Arizona",
               "mascot":"Coyotes",
               "abbreviation":"ARI",
               "conference":"Western",
               "division":"Pacific",
               "teamId":1000
            },
            "home":{
               "team":"San Jose Sharks",
               "location":"San Jose",
               "mascot":"Sharks",
               "abbreviation":"SJS",
               "conference":"Western",
               "division":"Pacific",
               "teamId":1088
            }
         },
         "lastUpdated":"2021-05-08T00:09:03.279Z",
         "gameId":258269,
         "odds":{
            "betonline":{
               "source":"BetOnline",
               "moneyline":{
                  "open":{
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-120,
                     "underOdds":100
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-120,
                     "underOdds":100
                  }
               },
               "openDate":"2021-05-07T23:07:12.158Z",
               "lastUpdated":"2021-05-08T00:48:09.662Z"
            },
            "betnow":{
               "source":"BetNow",
               "moneyline":{
                  "open":{
                     "awayOdds":-110,
                     "homeOdds":-110
                  },
                  "current":{
                     "awayOdds":-110,
                     "homeOdds":-110
                  }
               },
               "total":{
                  "open":{
                     "total":5.5,
                     "overOdds":-120,
                     "underOdds":100
                  },
                  "current":{
                     "total":5.5,
                     "overOdds":-117,
                     "underOdds":-103
                  }
               },
               "openDate":"2021-05-07T12:54:55.242Z",
               "lastUpdated":"2021-05-08T00:48:09.662Z"
            }
         },
         "bestOdds":{
            "spread":{
               
            },
            "moneyline":{
               "away":{
                  "awayOdds":-110,
                  "homeOdds":-110
               },
               "home":{
                  "awayOdds":-110,
                  "homeOdds":-110
               }
            },
            "total":{
               "over":{
                  "total":5.5,
                  "overOdds":-117,
                  "underOdds":-103
               },
               "under":{
                  "total":5.5,
                  "overOdds":-120,
                  "underOdds":100
               }
            }
         }
      },
      {
         "schedule":{
            "date":"2021-05-09T23:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Dallas Stars @ Chicago Blackhawks",
         "details":{
            "league":"NHL",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":true,
            "divisionGame":true
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Dallas Stars",
               "location":"Dallas",
               "mascot":"Stars",
               "abbreviation":"DAL",
               "conference":"Western",
               "division":"Central",
               "teamId":1085
            },
            "home":{
               "team":"Chicago Blackhawks",
               "location":"Chicago",
               "mascot":"Blackhawks",
               "abbreviation":"CHI",
               "conference":"Western",
               "division":"Central",
               "teamId":1004
            }
         },
         "lastUpdated":"2021-05-08T00:09:05.338Z",
         "gameId":258243
      },
      {
         "schedule":{
            "date":"2021-05-10T00:00:00.000Z",
            "tbaTime":false,
            "doubleheader":false,
            "confirmed":true,
            "tentative":false
         },
         "summary":"Ottawa Senators @ Calgary Flames",
         "details":{
            "league":"NHL",
            "seasonType":"regular",
            "season":2020,
            "special":false,
            "conferenceGame":false,
            "divisionGame":false
         },
         "status":"scheduled",
         "teams":{
            "away":{
               "team":"Ottawa Senators",
               "location":"Ottawa",
               "mascot":"Senators",
               "abbreviation":"OTT",
               "conference":"Eastern",
               "division":"Atlantic",
               "teamId":1167
            },
            "home":{
               "team":"Calgary Flames",
               "location":"Calgary",
               "mascot":"Flames",
               "abbreviation":"CGY",
               "conference":"Western",
               "division":"Pacific",
               "teamId":1006
            }
         },
         "lastUpdated":"2021-05-08T00:09:05.570Z",
         "gameId":258122
      }
   ]
}

module.exports = gameData;