import React from 'react';
import { connect } from 'react-redux';
import { setModalType } from '../../actions';
import './Compare.css';
import titanLogo from '../../images/odds-titan-logo.png';


export default props => {

	return <div className="compare-page">
	  	<div className="titan-odds-logo" onClick={() => props.history.push('/')}>
			<img src={titanLogo} />
		</div>
		<div className="compare-page-body">
			Coming Soon
		</div>
    </div>
}

