import React from 'react';
import './Terms.css';
import titanLogo from '../../images/odds-titan-logo.png';


export default (props) => {

  	return (
	    <div className="terms-page">
	    	<div className="titan-odds-logo" onClick={() => props.history.push('/')}>
				<img src={titanLogo} />
			</div>
		  	<div className="terms-page-body">
				<div className="terms-header">Terms and Conditions</div>
		    	<TermsHeader title="1. Use of This Site"/>
		    	<p className="terms-body">
		      		By using this website, you agree to all Terms and Conditions provided on this page.  These Terms and Condition may be 
		      		updated periodically and without notice elsewhere on the site.  Your continued use of this website indicates 
		      		your agreement with the most current Terms and Conditions.
		      	</p>
		    	<TermsHeader title="2. Overview"/>
		    	<p className="terms-body">
		      		OddsTitan.com displays betting odds for major US sports leagues as offered by a number of prominent sportsbooks.  We maintain 
		      		affiliate relationships with such sportsbooks, however, we are not a sportsbook and do not deal directly with bets in any way.
		      	</p>
		      	<TermsHeader title="3. Limitation of Liability"/>
		      	<p className="terms-body">
		      		The information provided on this website is updated periodically and we make best efforts to ensure that it is accurate 
		      		and timely, however, by using this site, you agree to hold OddsTitan.com, its officers, employees, affiliates 
		      		(including sportsbooks), and all related persons and entities harmless to the extent that such information is inaccurate, 
		      		incomplete, or untimely or otherwise from your use of this site.  This includes, but is not limited to, available 
		      		betting odds, game/event information and outcomes, and any news or commentary displayed on the site.
		      	</p>
		      	<p className="terms-body">
		      		To the extent that you place reliance or make decisions based on the information contained on this website, you agree 
		      		that you do so at your own risk and bear full responsibility for such reliance or decisions.
		      	</p>
		      	<p className="terms-body">
		      		As noted above, we are not a sportsbook, and all odds should be confirmed with affiliate sportsbooks before placing bets.  
		      		Additionally, game/event details can generally be confirmed on the various league websites.
		      	</p>
			  	<TermsHeader title="4. Legal Requirements"/>
			  	<p className="terms-body">
			  		No one under the age of 21 is permitted to use this site.  By accessing this site, you affirm that your doing so  
			  		is not in violation of any relevant laws in your jurisdiction.
			  	</p>		  		
		  	</div>
	    </div>
  	)
}


const TermsHeader = (props) => <div className="terms-subheader">{props.title}</div>




