import { combineReducers } from 'redux';
import SubscriptionTypeReducer from './subscription-type-reducer';
import ModalTypeReducer from './modal-reducer';
import UserReducer from './user-reducer';
import OddsboardReducer from './oddsboard-reducer';


const rootReducer = combineReducers({
	user: UserReducer,
	subscriptionType: SubscriptionTypeReducer,
	modalType: ModalTypeReducer,
	oddsboardReducer: OddsboardReducer
});

export default rootReducer;