import React from 'react';
import './404.css';

export default props => {
	// props.history.push("/")
	return <div className="_404">
		<div className="_404-header">Not Found</div>
		<div className="_404-body">Sorry, that page doesn't exist</div>
		<button className="_404-button" onClick={() => props.history.push("/")}>HOME</button>
	</div>
}