import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { CookiesProvider } from "react-cookie";
import { Elements, StripeProvider } from 'react-stripe-elements';
import NavBar from './components/NavBar/NavBar';
import Home from './components/Home/Home';
import OddsBoard from './components/OddsBoard/OddsBoard';
import SportspageOdds from './components/SportspageOdds/SportspageOdds';
import Compare from './components/Compare/Compare';
import Dashboard from './components/Dashboard/Dashboard';
// import FormModal from './components/FormModal/FormModal';
import Footer from './components/Footer/Footer';
import Terms from './components/Terms/Terms';
import ContactPage from './components/ContactPage/ContactPage';
import _404 from './components/404/404';
import ScrollToTop from './components/ScrollToTop';
import { connect } from 'react-redux';
import { setUser, logout } from './actions';
import axios from 'axios';
import './App.css';
import utils from './utils';



class App extends Component {



  componentDidMount() {
    // this.getUserData();
  }

  getUserData = () => {

    if (utils.signupLocked) {
      return this.props.logout();
    }

    axios.get("/getUserData").then(response => {

      if (response.data.success) {
        this.props.setUser(response.data);
      } else {
        this.props.logout();
      }
    }).catch(err => this.props.setUser())
  }


  render() {



    return (
      <div className="App">
        <BrowserRouter>
          <ScrollToTop>
            <Route path="/" render={(props) => <Container {...props}>
              <Route path="/" component={NavBar} />  
              <div className='page-holder'>   
                <Switch>
                  <Route exact path="/" component={SportspageOdds} />
                  <Route exact path="/odds-board" component={OddsBoard} />
                  <Route exact path="/odds-board-demo" component={OddsBoard} />
                  <Route exact path="/sportspage-odds" component={SportspageOdds} />
                  <Route exact path="/compare-sportsbooks" component={Compare} />
                  <Route exact path="/dashboard" component={Dashboard} />
                  <Route exact path="/terms" component={Terms} />
                  {/*<Route exact path="/contact" component={Contact} title="Contact" />*/}
                  <Route exact path="/contact" component={ContactPage} />
                  <Route component={_404} />
                </Switch>
              </div>
              <Route component={Footer} />
              
              {/*<StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
                <Elements>
                  {this.props.modalType !== "closed" ? 
                    <Route render={(props) => <FormModal {...props} 
                      type={this.props.modalType}
                      getUserData={this.getUserData}
                    />}/>:
                    <div/>
                  }
                </Elements>
              </StripeProvider>*/}

            </Container>}/>
          </ScrollToTop>  
        </BrowserRouter>
      </div>
    );
  }
}

const Container = props => {

  const holderClass = (() => {
    switch(props.location.pathname) {
      case "/dashboard": return "dash";
      case "/pricing": return "pric";
      default: return "";
    }
  })();

  return <div id="react-container" className={holderClass}>{props.children}</div>
}


const mapStateToProps = state => ({
  user: state.user,
  subscriptionType: state.subscriptionType,
  modalType: state.modalType,
})

const mapActionsToProps = {
  setUser: setUser,
  logout: logout,
}


export default connect(mapStateToProps, mapActionsToProps)(App);

