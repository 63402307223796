import React, { Component } from 'react';
import { Link } from "react-router-dom";
import navLogo from '../../images/nav-logo.png';
import navLogoLight from '../../images/nav-logo-light.png';
import './NavBar.css';
import { connect } from 'react-redux';
import { setSubscriptionType, setModalType } from '../../actions';
import utils from '../../utils';


class NavBar extends Component {

  render() {

    const showBottom = this.props.location.pathname !== "/" && this.props.location.pathname.indexOf("/pricing") < 0;
    const navClass = showBottom ? "navbar nav-bottom": "navbar";
    const signupLocked = utils.signupLocked || utils.rapidOnly;
    

    if (true || ["/documentation","/odds-board","/odds-board-demo","/sportspage-odds","/compare-sportsbooks"].includes(this.props.location.pathname)) {
      return (<div></div>)
    }

    return (
      <div className="nav-outer">
        <div id="navbar" className={navClass}>
        <div className="nav-top">
          <div className="navbar-inner">
            <div className="nav-logo" onClick={() => this.props.history.push("/")}>
              <img id="navLogo" src={navLogo} alt="Navigation bar logo" />
            </div>
            <div className="nav-top-header">SCORES | SCHEDULES | ODDS</div>
          </div>
        </div>
          <div className="navbar-inner links">
            {this.props.user.loginChecked && <div className="navbar-links">
              {this.props.user.loggedIn && <Link className="navbar-link" to="/dashboard" >DASHBOARD</Link>}
              <Link className="navbar-link" to="/pricing" >{this.props.user.loggedIn ? "PLANS": "PRICING"}</Link>
              <Link className="navbar-link" to="/documentation" >DOCS</Link>
              <Link className="navbar-link" to="/faq" >FAQ</Link>     
              {!this.props.user.loggedIn && !signupLocked && <MenuButton onClick={(e) => this.props.setModalType("login")} content="Login" isSolid={true} />}
            </div>}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  subscriptionType: state.subscriptionType,
  modalType: state.modalType
})

const mapActionsToProps = {
  setSubscriptionType: setSubscriptionType,
  setModalType: setModalType
}

export default connect(mapStateToProps, mapActionsToProps)(NavBar);



const MenuButton = props => (

  <button 
    className={props.isSolid ? "menu-button": "menu-button-basic"}
    onClick={(e) => props.onClick(e)}>{props.content}
  </button>
)


const Logo = (props) => (

  <div className="nav-logo" onClick={() => props.history.push("/")}>
      <img id="navLogo" src={navLogoLight} alt="Navigation bar logo" />
  </div>
)







