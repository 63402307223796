import React, { Component, Fragment, useState, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import { Link } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import {XYPlot, LineSeries, LineMarkSeries, LabelSeries, AreaSeries, XAxis, YAxis, DiscreteColorLegend} from 'react-vis';
import Scrollchor from 'react-scrollchor';
import { connect } from 'react-redux';
import axios from 'axios';
import crypto from 'crypto';
import fs from 'fs';
import utils from '../../utils';
import './SportspageOdds.css';
import spLogo from "../../images/sp-white.png";
import oddsCompare from "../../images/oddsboard-odds-compare.png";
import data from './data';
import affiliateLogo from "../../images/affiliate-sportsbook-demo-logo.png";
import titanLogo from '../../images/odds-titan-logo.png';

const useDemo = true;

const leagues = ["NFL","NBA","MLB","NHL","NCAAF","NCAAB"];
const books = ["bovada","barstool","fanduel","draftkings","bet365"];
const currentBooks = ["bovada","intertops","betonline","betnow","sportsbetting"];
// (() => {
// 	for (let league in gameData) {
// 		console.log(league)
// 		for (let game of gameData[league]) {
// 			game.odds = (game.odds || []).reduce((obj,b) => ({...obj, [b.source.toLowerCase()]: b}), {})
// 		}
// 	}
// })();

// console.log(gameData)

export default class SportspageOdds extends Component {

	state = {
		oddsType: undefined,
		league: undefined,
		gameData: undefined
	}

	setPageState = newState => {
		this.setState(newState);
	}

	componentDidMount() {
		this.getOddsData()
	}

	getOddsData = (n=3) => {

		axios.get("/odds", {
			// params: {
			// 	apiKey: "123"
			// }
		}).then(response => {

			const encryptedData = response.data;
			// const gameData = response.data; 
			const gameData = JSON.parse(utils.decrypt(response.data));
			// console.log(gameData)
			const league = leagues.find(league => !!gameData[league] && gameData[league].length > 0);
			const oddsType = ["MLB","NHL"].includes(league) ? "moneyline": "spread";
			this.setState({ gameData, league, oddsType });
			
		}).catch(async err => {
			console.log(err)
			if (n>0) {
				await utils.pause(2000);
				this.getOddsData(n-1);
			} else {
				// const elem = document.getElementById("sportspage-odds");
				// elem.innerHTML += `<div class="odds-compare-no-games">
				// 	There was a problem.  Try again shortly.
				// </div>`;
			}
		});
	}


	render() {

		// console.log(this.props)
		// console.log(this.state)

		const { league, oddsType } = this.state;
		const setOddsType = oddsType => this.setPageState({ oddsType });
		const setLeague = league => this.setPageState({ league });

		return <div id="sportspage-odds">
			<div className="sportspage-odds-background" />
			<OddsHeader history={this.props.history} />
			<div className="titan-odds-logo-holder">
				<div className="titan-odds-logo">
					<img src={titanLogo} />
				</div>
			</div>
			{this.state.gameData && <OddsCompareBody 
				league={league} 
				gameData={this.state.gameData} 
				setLeague={setLeague} 
				oddsType={oddsType} 
				setOddsType={setOddsType} />}
		</div>
	}
}


const OddsHeader = props => {
	return <div className="sportspage-odds-page-header">
		<div className="sportspage-odds-header-inner">
			{books.concat("all").map((b,i) => <OddsHeaderItem book={b} num={i} history={props.history} key={i} />)}
		</div>
	</div>
}

const OddsHeaderItem = props => {

	const src = (() => {
		if (useDemo) return affiliateLogo;
		switch(props.book) {
			case "bovada": return "https://www.pokersites.io/wp-content/uploads/2018/06/bovada-logo.png";
			case "barstool": return "https://upload.wikimedia.org/wikipedia/en/4/43/Barstool_Sports_logo.png";
			case "fanduel": return "https://cdn.mos.cms.futurecdn.net/hhmNYFRUUgAENQUKfi3LjY.jpg";
			case "draftkings": return "https://cdn.mos.cms.futurecdn.net/qv5mcyvVZU9985LhgsZff9-480-80.jpg";
			case "bet365": return "https://www.mmanytt.com/wp-content/uploads/2020/05/bet365-logo.png";
		}
	})();

	const compareBooks = () => props.history.push("/compare-sportsbooks");


	return <div className="odds-header-logo">
		{props.book === "all" ? 
			<div className="odds-header-logo-view-all" onClick={compareBooks}><div>View all sportsbooks</div></div>:
			<div className="odds-header-logo-book">
				<div>
					<div>
						<div>Book #{props.num+1} promo details</div>
					</div>
					<img src={src} />
				</div>
				<div><div>VIEW OFFER</div></div>
			</div>
		}
	</div>
}


const OddsCompareBody = props => {

	return <div className="odds-compare-body">
		<OddsCompareBodyHeader league={props.league} setLeague={props.setLeague} oddsType={props.oddsType} setOddsType={props.setOddsType} />
		<OddsCompareBodyGames league={props.league} gameData={props.gameData} oddsType={props.oddsType} />
		{/*<OddsCompareBodyRight league={props.league} oddsType={props.oddsType} />*/}
	</div>
}


// const OddsCompareBodyHeader = props => {
// 	const [top,setTop] = useState(0);
// 	const ref = useRef(null);
// 	const handleScroll = () => {
// 	    if (!ref.current) return;
// 	    const classList = ref.current.classList
// 	    setTop(ref.current.getBoundingClientRect().top)
// 	    console.log(ref.current.getBoundingClientRect().top)
// 	    if (ref.current.getBoundingClientRect().top < 1) {
// 			if (!classList.contains("sticky-header")) {
// 				classList.add("sticky-header");
// 			}
// 		} else {
// 			classList.remove("sticky-header");
// 		}
// 	};

// 	useEffect(() => {
// 	    window.addEventListener('scroll', handleScroll);

// 	    return () => {
// 	    	window.removeEventListener('scroll', () => handleScroll);
// 	    };
// 	}, []);
	
// 	return <div id="odds-compare-body-header" ref={ref}>
// 		<div id="odds-compare-header-inner">
// 			<div style={{color: "red"}}>{top}</div>
// 		</div>
// 	</div>
// }

const OddsCompareBodyHeader = props => {

	const [top,setTop] = useState(0);

	const oddsTypes = ["Spread","Moneyline","Total"];
	const leagueHandler = event => {
	    const value = event.target.value;
	    props.setLeague(value)
	}
	const oddsTypeHandler = event => {
	    const value = event.target.value;
	    props.setOddsType(value)
	}
	// const currentLeague = props.league.toLowerCase();
	const currentOddsType = props.oddsType.toLowerCase();

	const ref = useRef(null);
	const ref2 = useRef(null);
	const handleScroll = () => {
	    if (!ref.current) return;
	    // console.log(ref.current.classList)
	    // console.log(ref.current.classList.contains("sticky-header"))
	    const classList = ref.current.classList // document.getElementById("odds-compare-body-header").classList;
	    // console.log("scrolling")
	    // setTop(ref2.current.getBoundingClientRect().top)
	    // setTop(document.documentElement.scrollTop)
	    // console.log(ref.current.getBoundingClientRect().top)
	    // console.log(document.documentElement.scrollTop)
	    if (ref.current.getBoundingClientRect().top <= 0) {
	    // if (document.documentElement.scrollTop > 220) {
			// setSticky(ref.current.getBoundingClientRect().top <= 0);
			if (!classList.contains("sticky-header")) {
				classList.add("sticky-header");
			}
		} else {
			classList.remove("sticky-header");
		}
	};

	useEffect(() => {
	    window.addEventListener('scroll', handleScroll);

	    return () => {
	    	window.removeEventListener('scroll', () => handleScroll);
	    };
	}, []);
	
	
	return <div className="odds-compare-body-header-wrapper" ref={ref}>
		<div id="odds-compare-body-header">
			<div id="odds-compare-header-background">
				<div className="odds-compare-nav">
					<select name="leagues" id="leagues" value={props.league} onChange={leagueHandler}>
						{leagues.map(league => <option value={league} key={league}>{league}</option>)}
					</select>
					<select name="oddstypes" id="oddstypes" value={currentOddsType} onChange={oddsTypeHandler}>
						{oddsTypes.map(o => <option value={o.toLowerCase()} key={o}>{o}</option>)}
					</select>
				</div>
				<div className="odds-compare-body-header-books">
					{["open"].concat(books).map(b => <OddsCompareBodyHeaderBook book={b} key={b} />)}
				</div>
			</div>
		</div>
	</div>
}

const OddsCompareBodyHeaderBook = props => {

	const src = (() => {
		if (useDemo) return affiliateLogo;
		switch(props.book) {
			case "open": return "https://www.oddsshark.com/sites/default/files/images/sportsbook-reviews/logos/sblogo_opening_eng.png";
			case "bovada": return "https://www.pokersites.io/wp-content/uploads/2018/06/bovada-logo.png";
			case "barstool": return "https://upload.wikimedia.org/wikipedia/en/4/43/Barstool_Sports_logo.png";
			case "fanduel": return "https://cdn.mos.cms.futurecdn.net/hhmNYFRUUgAENQUKfi3LjY.jpg";
			case "draftkings": return "https://cdn.mos.cms.futurecdn.net/qv5mcyvVZU9985LhgsZff9-480-80.jpg";
			case "bet365": return "https://www.mmanytt.com/wp-content/uploads/2020/05/bet365-logo.png";
		}
	})();
	
	return <div className="odds-compare-body-header-book">
		{props.book === "open" ? 
			<div>OPEN</div>:
			<img src={src} />
		}
	</div>
}


const OddsCompareBodyGames = props => {

	const gameData = props.gameData
	const gameDates = ((gameData && gameData[props.league]) || []).reduce((obj,g) => {
		let date = new Date(g.schedule.date).toLocaleString('default', { weekday: 'long', month: 'long', day: 'numeric' })
		obj[date] = (obj[date] || []).concat(g)
		return obj;
	}, {});
	const hasDates = Object.keys(gameDates).length > 0;

	// console.log(gameDates)
	
	return <div className="odds-compare-body-games">
		{Object.keys(gameDates).map(d => <OddsCompareBodyGamesSection date={d} games={gameDates[d]} oddsType={props.oddsType} key={d} />)}
		{!hasDates && <div className="odds-compare-no-games">No upcoming games</div>}
	</div>
}


// Don't delete this - will need to transition to having data held in separate container to facilitate horizontal scrolling

// const OddsCompareBodyRight = props => {

// 	console.log(props)
	
// 	return <div className="odds-compare-right">
// 		<div className="odds-compare-body-header-books">
// 			{["open"].concat(books).map(b => <OddsCompareBodyHeaderBook book={b} />)}
// 		</div>
// 	</div>
// }

const OddsCompareBodyGamesSection = props => {
	
	return <div className="odds-compare-body-games-section">
		<div className="odds-compare-section-date">
			{props.date}
			<div className="best-odds-explainer">
				
				<div>Best odds</div>
				<div></div>
			</div>
		</div>
		{props.games.map(g => <OddsCompareGame game={g} oddsType={props.oddsType} key={g.gameId} />)}
	</div>
}

const OddsCompareGame = props => {

	// console.log(props.game)
	const game = props.game;
	const league = game.details.league;
	game.odds = game.odds || [];
	game.bestOdds = game.bestOdds || { spread: {}, moneyline: {}, total: {} };
	const oddsBooks = !!game.odds ? Object.keys(game.odds).filter(o => o !== "open"): [];
	const oddsKey = oddsType === "moneyline" ? "Odds": "";
	const oddsType = props.oddsType;

	// function getOdds() {
	// 	const lines = {}
	// 	for (let book of currentBooks) {
	// 		try {

	// 		} else {
	// 			push
	// 		}
	// 	} 
	// }

	// function getOdds(book) {
	// 	if (book.source)
	// }

	// function findBest(a,b,side) {

	// 	const isSpread = oddsType === "spread";
	// 	const useSideOdds = (oddsType === "moneyline") || (isSpread && a[side] === b[side])
	// 	if (useSideOdds) return a[`${side}Odds`] > a[`${side}Odds`] ? 1: -1;
	// 	else if (isSpread) return a[side] > b[side] ? 1: -1;
	// 	else if (oddsType === "total") return
	// }

	
	// const availableOdds = oddsBooks.filter(b => (props.game.odds[b] || {} )[oddsType]);
	// const availableAway = availableOdds.map(b => props.game.odds[b][oddsType].current).sort((a,b) => findBest(a,b,"away"));
	// const availableHome = availableOdds.map(b => props.game.odds[b][oddsType].current).sort((a,b) => findBest(a,b,"away"));
	// const bestAway = Math.max(...availableAway);
	// const bestHome = Math.max(...availableHome);

	// console.log(oddsBooks)
	// console.log(availableOdds)
	// console.log(availableAway)
	// // console.log(oddsBooks.map(b => props.game.odds[b][oddsType].current))
	// // console.log(bestHome)

	// // throw Error()

	// // return <div></div>

	// console.log("best")
	// console.log(bestHome)
	// console.log(bestAway)


	function getOdds(side, book="best") {
		if (!game.odds || game.odds.length === 0) return "";
		
		const keys = {
			spread: [["away","awayOdds"], ["home","homeOdds"]],
			moneyline: [["awayOdds"],["homeOdds"]],
			total: [["total","overOdds"],["total","underOdds"]]
		}[oddsType][side];
		// console.log(book)

		if (book === "best") {
			const sideName = (oddsType === "total" ? ["over","under"]: ["away","home"])[side];
			if (!game.bestOdds[oddsType] || !game.bestOdds[oddsType][sideName]) return "";
			const oddsObj = keys.reduce((obj,k) => ({ ...obj, [k]: game.bestOdds[oddsType][sideName][k]}), {});
			return JSON.stringify(oddsObj);
		} else {
			if (!game.odds[book] || !game.odds[book][oddsType]) return "";
			const oddsBase = game.odds[book][oddsType] || { current: undefined };
			const oddsObj = keys.reduce((obj,k) => ({ ...obj, [k]: oddsBase.current[k]}), {});
			return JSON.stringify(oddsObj);
		}
	}

	const gameTime = new Date(game.schedule.date).toLocaleTimeString([], {timeStyle: 'short'});
	const allBooks = useDemo ? []: ["composite"].concat(currentBooks);
	const bestFirst = getOdds(0);
	const bestSecond = getOdds(1)

	// console.log(allBooks)
	// console.log(game.odds)
	// console.log(game.bestOdds)
	// console.log(getOdds(1))
	// console.log(game)
	// console.log(allBooks)

	const oddsCount = allBooks.reduce((sum,b) => sum + (!!(game.odds[b] || {})[oddsType] ? 1: 0), 0);
	// console.log(oddsCount)

	const isBest = (side,book) => getOdds(side,book) === (side === 0 ? bestFirst: bestSecond) && !["composite","open"].includes(book);

	return <div className="odds-compare-body-game">
		<div className="odds-compare-body-game-details">
			<div className="odds-compare-body-game-time">{gameTime}</div>
			<div className="odds-compare-game-teams">
				<div>{utils.teamTranslator(game.teams.away, league)}</div>
				<div>{utils.teamTranslator(game.teams.home, league)}</div>
			</div>
		</div>
		<div className="odds-compare-game-odds">
			<div className="odds-compare-game-odds-side first">
				{allBooks.map((b,i) => <OddsCompareItem bookName={b} book={game.odds[b]} oddsType={oddsType} side={0} best={isBest(0,b)} key={i} />)}
			</div>
			<div className="odds-compare-game-odds-side second">
				{allBooks.map((b,i) => <OddsCompareItem bookName={b} book={game.odds[b]} oddsType={oddsType} side={1} best={isBest(1,b)} key={i} />)}
			</div>
			{oddsCount === 0 && <div className="odds-compare-none"><div>
				{useDemo ? "Coming soon": "Odds not available"}
				</div></div>}
		</div>
	</div>
}

const OddsCompareItem = props => {

	// console.log(props.oddsType)

	function getOddsValue() {
		
		try {
			const isComposite = props.bookName === "composite";
			if (isComposite) {
				// console.log("IS COMPOSITE ------------")
			}
			const currentOdds = props.book[props.oddsType][isComposite ? "open": "current"];
			let side = props.side === 0 ? "away": "home";

			// console.log(currentOdds)

			switch(props.oddsType) {
				case "spread": 
					return {
						first: utils.formatOdds(currentOdds[side], "spread"),
						second: utils.formatOdds(currentOdds[side+"Odds"])
					}
				case "moneyline": return {
					first: utils.formatOdds(currentOdds[side+"Odds"]),
					second: "ML"
				}
				case "total":
					side = props.side === 0 ? "overOdds": "underOdds";
					return {
						first: utils.formatOdds(currentOdds.total, "total"),
						second: utils.formatOdds(currentOdds[side], "total")
					}
				default: throw Error()
			}
			return [props.side];
		} catch(err) {
			return {first: ""};
		}
	}

	const value = getOddsValue();
	// console.log(props)
	// console.log(value)
	// const isBest = value === props.best && !["composite","open"].includes(props.book);
	const isBest = value.first !== "" && props.best// Math.floor(Math.random() * 10) === 0;
	const bestClass = isBest ? "best-odds": "";
	// console.log(props.best)
	return <div className={`odds-compare-item ${bestClass}`}>
		<div>
			<div className={`odds-compare-${props.oddsType}`}>
				{(() => {
					if (!value.first) return "";
					switch(props.oddsType) {
						// case "moneyline":
						// 	return <Fragment>
						// 		{value.first}
						//		<span>ML</span>
						//	</Fragment>
						case "total":
							return <Fragment>
								<span>{props.side === 0 ? "o": "u"}</span>
								{value.first}
							</Fragment>
						default: return value.first
					}
				})()}
			</div>
			{/*props.oddsType === "total" ? <div className={`odds-compare-${props.oddsType}`}>
				<span>{props.side === 0 ? "o": "u"}</span>
				{value.first}
			</div>: <div>{value.first}</div>*/}
			{value.second && <div>{value.second}</div>}
		</div>
	</div>
}






