import React, { Component, Fragment, useState } from 'react';
import Cookies from 'js-cookie';
import { Link } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import {XYPlot, LineSeries, LineMarkSeries, LabelSeries, AreaSeries, XAxis, YAxis, DiscreteColorLegend} from 'react-vis';
import Scrollchor from 'react-scrollchor';
import { connect } from 'react-redux';
import Contact from '../Contact/Contact';
import axios from 'axios';
import crypto from 'crypto';
import fs from 'fs';
import utils from '../../utils';
import './OddsBoard.css';
import spLogo from "../../images/sp-white.png";
import oddsCompare from "../../images/oddsboard-odds-compare.png";
import gameData from './data'





const scrollDuration = 100;
const apiUrl = "https://sportspage-feeds.p.rapidapi.com/"
// const apiUrl = "https://api.sportspagefeeds.com/";
const leagueOrder = [
	"NFL",
	"NBA",
	"NCAAF",
	"MLB",
	"NCAAB",
	"NHL"
]

const background = "https://wallpaper.dog/large/10861237.png";
// const apiKey = "2b3010befdmsh4cc17910accc522p139908jsn28561a2de929";

// Sportsbooks
// - Barstool
// - Fanduel
// - DraftKings
// - Bovada
// - Bet365
// - William Hill
// - PointsBet
// - BetOnline
// - Intertops

const rapidApiRequest = async (endpoint="games", apiKey="", params={}) => {
	return await axios.get(`${apiUrl}${endpoint}`, {
		params,
		headers: {
			'x-rapidapi-key': apiKey,
			'x-rapidapi-host': 'sportspage-feeds.p.rapidapi.com'
		}
	});
}

const getUncanceledGames = games => {
	return games.filter(g => ["scheduled","in progress","final"].includes(g.status));
}

const getPageRange = (items, page, n) => {
	const maxPage = items.length / n;

	if (items.length > n) {
		const start = page * n;
		const end = (page+1) * n
		items = items.slice(start,end);
	}

	return items;
}

const fade = async (elem="oddsboard-body", callback) => {
	console.log("fading", elem)
	const content = document.getElementById(elem);
	const isHidden = content.classList.contains("hide");

	if (isHidden) {
		content.classList.remove("hide");
	} else {
		content.classList.add("hide");
		await utils.pause(500)
		if (!!callback) {
			callback()
		}
	}
}


export default class OddsBoard extends Component {

	state = {
		demo: undefined,
		mode: undefined,
		apiKey: undefined,
		remainingCalls: -1,
		lastPromo: new Date(),
		gameData: gameData,
		gatorText: Math.random()
	}

	componentWillMount = () => {
		const isDemo = this.props.location.pathname.includes("/odds-board-demo");
		const gameData = isDemo ? gameData: undefined;
		this.setState({
			demo: isDemo,
			mode: isDemo ? "games": "login", // [login,games,promo]
		});
	}

	// start with login
	// get game and odds data, oddsboard metadata go to games/schedule mode
	// cycle through leagues
	//   - show schedules
	//   - show odds movements
	// decide whether to loop back through leagues or show promo
	// continue to update scores/odds in background

	// odds metadata:
	//   - top games for the day (to determine which odds movements to show)
	//   - determine if in NFL/NCAAF seasons to know whether to pull full week data
	// 		- possibly show those less often

	// items to track:
	//   - remaining API calls
	//   - time between loop steps
	//   - frequency that promo screen is shown
	//   - background data (do full update each time)
	// 	 	- maybe track 
	//   - address stale/multiple games


	// timed functions:
	//   - update metadata
	//   - update games/odds
	//   - cycle through games and promo
	//      - within games, cycle through schedule and odds movements

	
	setKey = (apiKey, callback) => {
		this.setState({ apiKey }, callback);
	}

	metadataUpdate = async () => {
		try {
			const response = await axios.get("api.sportspagefeeds.com/oddsboard-data");
			this.props.setMetadata(response.data);
			const timeoutDuration = Date.hour + Math.random() * 5 * Date.minute
			setTimeout(this.metadataUpdate, timeoutDuration);
		} catch(err) {
			const timeoutDuration = Math.random() * 10 * Date.minute
			setTimeout(this.metadataUpdate, timeoutDuration);
		}
	}

	gameDataUpdate = async (apiKey, isInitial) => {

		console.log("UDPATING GAME DATA-----------")

		let response;
		apiKey = apiKey || this.state.apiKey;
		try {
			const now = new Date()
			const useToday = now.getHours() > 3;
			let date = useToday ? now: new Date(now + Date.day);
			// console.log(useToday)
			// console.log(now)
			// console.log(date)
			// console.log(date.toISOString())
			// console.log(date.toString())
			// console.log(date.toLocaleTimeString())
			// date = date.toString().slice(4,14);
			date = date.simpleStringDateLocal()
			console.log(date)
			// date = "2021-05-03"
			const params = {date: date}
			response = await rapidApiRequest("games", apiKey, params);
		} catch(err) {
			//
		}
		
		console.log(response)
		const planType = response.data.plan;
		const remaining = response.headers["x-ratelimit-sportspage-remaining"]*1;

		const games = {};
		let leagues = []
		for (let game of response.data.results) {
			const league = game.details.league;
			games[league] = (games[league] || []).concat(game);
			leagues.push(league);
		}
		leagues = [ ...new Set(leagues) ].sort((a,b) => leagueOrder.indexOf(a) > leagueOrder.indexOf(b) ? 1:-1);
		const gameData = {
			leagues,
			games,
			odds: {}
		}

		if (!isInitial) {
			this.setState({ gameData });
		}
		// setTimeout(() => this.gameDataUpdate(apiKey), Date.min/10)

		return { gameData, planType, remaining }
	}

	oddsDataUpdate = async () => {

	}


	updateMode = async (mode, state={}, elem) => {
		await fade();
		this.setState({mode: mode, ...state}, () => {
			fade(elem);
			console.log(mode)
			if (mode === "games") {
				console.log("STARTING GAMES--------")
				// run games cycle
			} else if (mode === "promo") {
				const timeoutDuration = Date.sec * 30;
				setTimeout(() => this.updateMode("games"), timeoutDuration);
			}
		});
	}


	getJSON = async () => {

		const type = "odds"

		if (type === "games") {
			const filename = "game.json"
			const games = await this.rapidApiRequest("games", this.state.apiKey, {date: "2020-11-26"})
				.then(response => {
					console.log(response)
					return response.data.results
				});
			let gameObj = leagueOrder.reduce((obj,l) => ({...obj,[l]:[]}),{});
			for (let game of games) {
				const league = game.details.league
				gameObj[league] = (gameObj[league] || []).concat(game);
			}
			gameObj = leagueOrder.reduce((obj,l) => gameObj[l].length === 0 ? obj: {...obj,[l]: gameObj[l]}, {});
			// for (let league of leagueOrder) {
			// 	console.log(league)
			// 	if (gameObj[league].length == 0) {
			// 		delete gameObj[league];
			// 	}
			// }
			// window.open(JSON.stringify(gameObj),"_blank");
			// const something = window.open(`data:text/json;charset=utf-8,${encodeURIComponent(
   //            JSON.stringify(gameObj)
   //          )}`);
			// something.focus();
			// fs.writeFileSync(filename, JSON.stringify(gameObj));
			// const gameLeagues = leagueOrder.filter(l => Object.keys(gameObj).includes(l));
			// gameObj[]
			console.log(JSON.stringify(gameObj))
			console.log(gameObj)
		} else if (type === "odds") {
			const filename = "odds.json"
			const gameIds = {
				NFL: [242985,242986],
				NCAAF: [249643],
				NCAAB: [250401,255066,250410,255345]
			}
			const oddsLeagues = Object.keys(gameIds);
			// const allIds = oddsLeagues.reduce((arr,l) => arr.concat(gameIds[l]),[]);
			const odds = oddsLeagues.reduce((obj,l) => ({...obj, [l]: {}}), {})
			for (let league in gameIds) {
				console.log(league)
				for (let id of gameIds[league]) {

					console.log(id)
					const gameOdds = await this.rapidApiRequest("odds", this.state.apiKey, {gameId: id})
						.then(response => response.data.results);
					// console.log(gameOdds)
					odds[league][id] = gameOdds
					// console.log(odds)
					// throw Error()
				}
			}

			console.log(JSON.stringify(odds))
			
			// fs.writeFileSync(filename, JSON.stringify(odds));
		}
		
	}

	// changeLeagues = () => {
	// 	const gameData = JSON.parse(JSON.stringify(this.state.gameData));
	// 	gameData.leagues = ["","NCAAF","NCAAB"]
	// 	gameData.date = new Date;
	// 	console.log(gameData)
	// 	this.setState({gameData})
	// }

	changeDate = () => {
		this.setState({date: new Date().toISOString()})
	}


	render() {

		// console.log(this.props)
		// console.log(document.cookie)
		// console.log(Cookies.get("apiKey"))

		// this.getJSON()

		return <div className="oddsboard">
			<FlashUpdater flashKey={this.state.gatorText}>
				<div style={{position: "absolute", top: 0}}>{this.state.gatorText}</div>
			</FlashUpdater>
			<div className="oddsboard-background"></div>			
			<div id="oddsboard-body" onClick={() => this.setState({gatorText: Math.random()})}>
				{(() => {
					switch(this.state.mode) {
						case "login": return <OddsBoardLogin setKey={this.setKey} gameDataUpdate={this.gameDataUpdate} updateMode={this.updateMode} />
						case "games": return this.state.gameData && <OddsBoardData league={"NFL"} gameData={this.state.gameData} usePromo={false} />
						case "promo": return <Promo />
					}
				})()}
			</div>
			{this.state.demo && <DemoHeader />}
			{this.state.demo && <DemoControls/>}
			{!["login","promo"].includes(this.state.mode) && <PoweredBy/>}
		</div>
	}
}


class OddsBoardData extends Component {
	state = {
		mode: "schedule",
		page: 0,
		paused: false,
		gamePageLen: 14,
		oddsPageLen: 2,	
		leagueIndex: 1,
		// league: leagueOrder.find(league => this.props.gameData.leagues.includes(league)),
		leagues: this.props.gameData.leagues,
	}

	componentDidUpdate = (prevProps,prevState) => {

		console.log("UPDATING ------------------------	")
		// console.log(prevProps.gameData.games["NBA"].slice(-1)[0].scoreboard)
		// console.log(this.props.gameData.games["NBA"].slice(-1)[0].scoreboard)

		// // const prevLeagues = JSON.stringify(prevProps.gameData.leagues)
		// // const currLeagues = JSON.stringify(this.props.gameData.leagues)
		// // console.log(prevLeagues === currLeagues)
		// this.cycleLeagues()
		// this.forceUpdate()
	}

	componentDidMount = () => {
		// this.setState({
		// 	league: leagueOrder.find(league => this.props.gameData.leagues.includes(league)),
		// 	leagues: this.props.gameData.leagues,
		// 	gameData: this.props.gameData,
		// });
		const league = this.state.leagues[this.state.leagueIndex];
		const games = this.props.gameData.games[league];
		const pages = Math.floor(games.length / 14);
		// this.cyclePage();
	}


	// component mounts
	//   - starts on highest priority league (e.g., NFL)
	// 	 - process starts at bottom (cyclePage method) and triggers upward as necessary
	//   	- cyclePage -> cycleScreen -> cycleLeagues
	// for each league:
	//   - start with schedule/scores
	//		- cycle through all games (could be multiple pages)
	//   - check whether odds exist and transition
	// 		- cycle through all top odds (could be multiple pages)
	// after all leagues with games have been shown:
	//   - check whether promo should be shown
	//   - if not, start with first league

	cycleLeagues = async () => {
		console.log("cycling LEAGUE")
		console.log(this.props.usePromo)

		const leagues = this.state.leagues;
		// const lastLeague = this.state.league === leagues.slice(-1)[0];
		const lastLeague = this.state.leagueIndex === (this.state.leagues.length-1);

		if (lastLeague && this.props.usePromo) {
			return this.props.updateMode("games", {}, "oddsboard-body")
		}

		// const nextLeague = lastLeague ? leagues[0]: leagues[leagues.indexOf(this.state.league)+1];
		const nextLeague = lastLeague ? 0: this.state.leagueIndex+1;
		console.log(this.state.leagueIndex)
		console.log(nextLeague)
		console.log(lastLeague)
		this.setState({mode: "schedule", leagueIndex: nextLeague, page: 0}, this.cyclePage);
	}

	cycleScreen = async () => {

		// add timer
		console.log("CYCLING SCREEN")
		const onOdds = this.state.mode === "odds-moves";
		const league = this.state.leagues[this.state.leagueIndex];
		console.log(onOdds)
		
		this.setState({page: 0}, () => {
			console.log("callback")
			console.log("on odds", onOdds)
			if (onOdds) {

				// need to reset
				this.cycleLeagues();
			} else {
				console.log()
				// check if odds moves exists
				const oddsExist = this.props.gameData.odds[league]
				console.log(oddsExist)
				if (oddsExist) {
					this.switchMode("odds-moves");
				} else {
					this.cycleLeagues()
				}
			}
		});
	}

	getPages = (data, isOdds) => {
		return
	}

	cyclePage = async (skipDelay=false) => {

		// if (!!this.state.looped) return
		console.log("cycling")
		if (!skipDelay) await utils.pause(20*Date.sec);
		if (this.state.paused) return
		
		const league = this.state.leagues[this.state.leagueIndex];
		const onOdds = this.state.mode === "odds-moves";
		let pages;
		// round down so that p.1 -> p.0
		if (onOdds) {
			const dataLength = Object.keys(this.props.gameData.odds[league]).length
			pages = Math.ceil(dataLength / 2) - 1;
		} else {
			const dataLength = getUncanceledGames(this.props.gameData.games[league]).length
			pages = Math.ceil(dataLength / 14) - 1;
		}
		
		console.log(this.state.page, "of", pages, league, onOdds)
		console.log(this.props.gameData.games[league].length)
		// return
		if (this.state.page === pages) {
			this.cycleScreen();
		} else {
			this.setState({page: this.state.page+1}, this.cyclePage)
		}
	}


	switchMode = mode => {
		console.log("switching")
		const onSchedule = this.state.mode === "schedule";
		const nextMode = onSchedule ? "odds-moves": "schedule";
		// fade("oddsboard-data-body", () => {
		// 	this.setState({mode: nextMode}, this.cyclePage)
		// });
		this.setState({mode: nextMode}, this.cyclePage)
		
	}

	

	render() {



		// pages might need to be managed from OddsBoardSchedule or OddsBoardChartHolder


		console.log(this.state)
		const league = this.state.leagues[this.state.leagueIndex];
		const onOdds = this.state.mode === "odds-moves";
		const page = this.state.page;
		const pageLen = onOdds ? this.state.oddsPageLen: this.state.gamePageLen;
		const startIndex = pageLen * this.state.page;
		const endIndex = (pageLen+1) * this.state.page;
		return <div>
			{this.props.gameData && <Fragment>
				<div className="oddsboard-league">{league}</div>
				<div id="oddsboard-data-body">
					{onOdds ?
						<OddsBoardChartHolder league={league} gameData={this.props.gameData} page={page}/>:
						<OddsBoardSchedule league={league} games={this.props.gameData.games[league]} page={page}/>
					}
				</div>
			</Fragment>}
		</div>
	}
}


const OddsBoardSchedule = props => {

	console.log(props)

	let toShow = getUncanceledGames(props.games);
	toShow = getPageRange(toShow, props.page, 14)

	console.log("PAGE---------")
	console.log(props.page)

	// if(window.innerHeight > window.innerWidth){
	//     alert("Please use Landscape!");
	// }

	return <div>
		<div id="oddsboard-schedule">
			<div>
				{toShow.map((g,i) => <OddsBoardGame game={g} key={g.gameId}/>)}
			</div>
		</div>
	</div>
}


function getPeriod(game) {
	const normalPeriods = {
		NFL: 4,
		NBA: 4,
		MLB: 9,
		NHL: 3,
		NCAAF: 4,
		NCAAB: 2
	}
	const period = game.scoreboard.currentPeriod;
	const strPeriod = (() => {
		switch(period) {
			case 1: return "1st";
			case 2: return "2nd";
			case 3: return "3rd";
			default: return `${period}th`
		}
	})();
	
	if (game.details.league === "MLB") {
		const awayPeriods = game.scoreboard.score.awayPeriods;
		const homePeriods = game.scoreboard.score.homePeriods;
		console.log(game.scoreboard)
		// let side = 1
		console.log(game)
		if (!awayPeriods || !homePeriods) {
			return "Top 1st"
		}
		const side = awayPeriods.length > homePeriods.length ? "Top": "Bot";
		// const side = awayPeriods.length > homePeriods.length ? "△": "▽";
		return `${side} ${strPeriod}`
	} else {
		const time = game.scoreboard.periodTimeRemaining || "";
		const displayTime = time.replace(":",".")*1 === 0 ? "End": time;
		const excessPeriods = period - normalPeriods[game.details.league];
		const displayPeriod = excessPeriods > 0 ? `${excessPeriods}OT`: strPeriod;
		return `${displayTime} ${displayPeriod}`;
	}
}

function calcStatus(game) {
	if (game.status == "final") {
		return "Final";
	} else if (!!game.scoreboard) {
		return getPeriod(game)
	} else {
		// const date = 
		return new Date(game.schedule.date).toLocaleTimeString([], {timeStyle: 'short'})
	}
}



// class FlashUpdater extends Component {

// 	// const [ visible, setVisibility ] = useState(true);

// 	state = {
// 		visible: true
// 	}

// 	componentWillUpdate = () => {
// 		// setVisibility(false)
// 		this.setState({visible: false});
// 	}

// 	componentDidUpdate = () => {
// 		// setVisibility(true)
// 		this.setState({visible: true});
// 	}

// 	render = () => {
// 		return <CSSTransition
// 			unmountOnExit
// 			in={this.state.visible}
// 			timeout={{ appear: 0, enter: 0, exit: 300 }}
// 			classNames='roll'
// 			appear
// 		>
//       	{this.props.children}
//     </CSSTransition>

// 	}
// }



// <Gator in={true} text={this.state.gatorText}>
// 	<div style={{position: "absolute", top: 0}}>{this.state.gatorText}</div>
// </Gator>

// const Gator = props => {
//   return (
//   	<TransitionGroup>
// 	    <CSSTransition
// 	    	key={props.text}
// 			// unmountOnExit
// 			timeout={{ appear: 1000, enter: 1000, exit: 1000 }}
// 			classNames='flash'
// 			// appear
// 	    >
// 			{props.children}
// 	    </CSSTransition>
// 	</TransitionGroup>
//   );
// };


// const Fuck = props => {
// 	const [ scoreVal, updateScore ] = useState(0);

// 	return <div></div>
// }

const FlashUpdater = props => {
	console.log(props.flashKey)
	return <TransitionGroup>
		<CSSTransition
			key={props.flashKey}
			// unmountOnExit
			// in={inProp}
			timeout={1000}
			classNames='flash'
			// appear
		>
			{props.children}
		</CSSTransition>
	</TransitionGroup>
};

const OddsBoardGame = props => {

	const game = props.game;
	const league = game.details.league
	const teams = game.teams;
	const hasScore = !!game.scoreboard;
	const hasOdds = !!game.odds;
	const useML = ["MLB","NHL"].includes(league);
	const oddsType = useML ? "moneyline": "spread";
	const hasSpread = hasOdds && game.odds[0][oddsType];
	const hasTotal = hasOdds && game.odds[0].total;
	const isCollege = league.includes("NCAA");
	

	// const Team = props => {

		

	// 	const isHome = props.side === "home";
	// 	const oddsCheck = props.oddsType === "total" ? hasTotal: hasSpread;
	// 	const flashKey = Math.random() //hasScore ? `${game.gameId}-${props.side}-${props.score}`: `${game.gameId}-odds`
	// 	return <div className="oddsgame-team" onClick={() => updateScore(scoreVal+1)}>
	// 		<div>
	// 			{teamTranslator(teams[props.side],league)}
	// 			{isHome && hasScore && hasOdds && <span>({utils.formatOdds(game.odds,"",league)})</span>}
	// 		</div>
	// 		<FlashUpdater flashKey={flashKey}>
	// 			<Fragment>
	// 				{hasScore && <div className="odds-game-score">{props.score}</div>}
	// 				{!hasScore && oddsCheck && <div><span>O/U:</span> {utils.formatOdds(game.odds,props.oddsType,league)}</div>}
	// 			</Fragment>
	// 		</FlashUpdater>
	// 	</div>
	// }

	const [ scoreVal, updateScore ] = useState(0);
	const awayFlashKey = hasScore ? `${game.gameId}-away-${game.scoreboard.score.away}`: `${game.gameId}-odds`;
	const homeFlashKey = hasScore ? `${game.gameId}-away-${game.scoreboard.score.home}`: `${game.gameId}-odds`;
	const statusFlashKey = `${game.gameId}-${hasScore ? calcStatus(game): "schedule"}`

	return <div className="oddsboard-game" onClick={() => updateScore(scoreVal+1)}>
		<FlashUpdater flashKey={statusFlashKey}>
			<div className="odds-game-status">
				<div>{calcStatus(game)}</div>
			</div>
		</FlashUpdater>
		
		<div className="odds-teams">
		{/*<Team side="away" oddsType="total"/>
		<Team side="home" oddsType=""/>*/}
			<div className="oddsgame-team" >
				<div>
					{utils.teamTranslator(teams.away,league)}
				</div>
				<FlashUpdater flashKey={awayFlashKey}>
					<Fragment>
						{hasScore && <div className="odds-game-score">{game.scoreboard.score.away}</div>}
						{!hasScore && hasTotal && <div className="odds-game-odds"><span>O/U:</span> {utils.formatOdds(game.odds,"total",league)}</div>}
					</Fragment>
				</FlashUpdater>
			</div>
			<div className="oddsgame-team">
				<div>
					{utils.teamTranslator(teams.home,league)}
					{hasScore && hasOdds && <span>({utils.formatOdds(game.odds,"",league)})</span>}
				</div>
				<FlashUpdater flashKey={homeFlashKey}>
					<Fragment>
						{hasScore && <div className="odds-game-score">{game.scoreboard.score.home}</div>}
						{!hasScore && hasSpread && <div className="odds-game-odds">{utils.formatOdds(game.odds,"",league)}</div>}
					</Fragment>
				</FlashUpdater>
			</div>
			
			{/*<div className="oddsgame-team">
				<div>
					{teamTranslator(teams.home,league)}
					{hasScore && hasOdds && <span>({utils.formatOdds(game.odds,"",league)})</span>}
				</div>
				<FlashUpdater flashKey={`${game.gameId}-home`}>
					<Fragment>
						{hasScore && <div className="odds-game-score">{game.scoreboard.score.home}</div>}
						{!hasScore && hasSpread && <div>{utils.formatOdds(game.odds,"",league)}</div>}
					</Fragment>
				</FlashUpdater>
			</div>*/}
			{/*<div>
				{teamTranslator(teams.home,league)}
				{hasScore && hasOdds && <span>({utils.formatOdds(game.odds,"",league)})</span>}
			</div>*/}
		</div>
		{/*<OddsGameRight game={game} />*/}
	</div>
}

// const OddsGameRight = props => {
// 	const game = props.game
// 	const league = game.details.league;
// 	const hasScore = !!game.scoreboard;
// 	const hasOdds = !!game.odds;
// 	const useML = ["MLB","NHL"].includes(league);
// 	const oddsType = useML ? "moneyline": "spread";
// 	const hasSpread = hasOdds && game.odds[0][oddsType];
// 	const hasTotal = hasOdds && game.odds[0].total;
// 	// console.log(game.summary)
// 	// console.log(hasSpread && game.odds[0])
// 	// console.log(hasSpread)
// 	console.log(game.summary)
// 	console.log(game)
// 	console.log(hasScore)

// 	return <div className="odds-game-right">
// 		{hasScore ? <div className="odds-game-score">
// 			<FlashUpdater flashKey={`${game.gameId}-away-score-${game.scoreboard.score.away}`}>
// 				<div>{game.scoreboard.score.away}</div>
// 			</FlashUpdater>
// 			<FlashUpdater flashKey={`${game.gameId}-home-score-${game.scoreboard.score.home}`}>
// 				<div>{game.scoreboard.score.home}</div>
// 			</FlashUpdater>
// 		</div>:
// 		<div>
// 			{hasTotal && <div><span>O/U:</span> {utils.formatOdds(game.odds,"total",league)}</div>}
// 			{hasSpread && <div>{utils.formatOdds(game.odds,"",league)}</div>}
// 		</div>
// 		}
// 	</div>
// }


const OddsBoardChartHolder = props => {
	let oddsData = props.gameData.odds[props.league];
	console.log(oddsData)
	oddsData = Object.keys(oddsData).map((k) => oddsData[k]);
	oddsData = getPageRange(oddsData, props.page, 2)

	console.log(oddsData)
    return <div className="oddsboard-chart">
    	<div className="oddsboard-chart-holder">
    		{oddsData.map((game, i) => <OddsBoardChartGame odds={oddsData[i]} key={game.gameId} num={i}/>)}
    	</div>
	</div>
}

const OddsBoardChartGame = props => {
	// console.log(props)
	const mlLeague = ["MLB","NHL"].includes("NFL");
	const oddsTypes = {spread:[],moneyline:[],total:[]};
	for (let o of props.odds) {
		oddsTypes[o.type].push(o)
	}
	for (let oddsType in oddsTypes) {
		const insufficientOdds = oddsTypes[oddsType].length < 2;
		const blockOdds = (mlLeague && oddsType === "spread") || (!mlLeague && oddsType === "moneyline")
		// console.log(oddsType)
		// console.log(insufficientOdds)
		// console.log("block", blockOdds)
		// console.log(mlLeague)
		// console.log(mlLeague && oddsType === "spread")
		// console.log(!mlLeague && oddsType === "moneyline")
		if (insufficientOdds || blockOdds) {
			delete oddsTypes[oddsType]
		}
	}
	// let oddsTypes = props.odds.reduce((obj,o) => ({...obj,[o.type]: [...obj[o.type],o]}), {spread:[],moneyline:[],total:[]});
	// oddsTypes = 
	// console.log(oddsTypes)
	return <div className="oddsboard-chart-game">
		<div className="oddsboard-chart-header">{props.odds[0].summary}</div>
		<div className="oddsboard-chart-body">
			{Object.keys(oddsTypes).map((oddsType,i) => <OddsBoardChart oddsType={oddsType} odds={oddsTypes[oddsType]} key={i} num={i}/>)}
		</div>
		
	</div>
}

const OddsBoardChart = props => {

	console.log(props)
	const oddsKey = {
		total: "total",
		spread: "home",
		moneyline: "homeOdds"
	}[props.oddsType];

	let data = props.odds.map((o,i) => ({x: i, y: o[oddsKey]}))
	const toFilter = {};

	for (let d = 1; d < data.length - 1; d++) {
		// console.log(d)
		// console.log(data[d])
		// console.log(typeof data)
		// console.log(data)
		// console.log(data[d+1])
		const last = data[d-1].y;
		const current = data[d].y;
		const next = data[d+1].y;
		if (last === current && current === next) {
			toFilter[d] = true;
		}
	}

	data = data.filter((d,i) => !toFilter[i])
	// data format -> [{x: 0, y: 1}]

	// const data = [];
	// let lastVal;
	// let valCount = 0;

	// for (let o of props.odds) {
	// 	const newVal = o[oddsKey] !== lastVal;
	// 	if (newVal || valCount < 3) {
	// 		data.push({x: data.length, y: o[oddsKey]});
	// 		lastVal = o[oddsKey];
	// 		if (newVal) {
	// 			valCount = 1;
	// 		} else {
	// 			valCount++;
	// 		}
	// 	}
	// }
	// console.log(props.odds[0].summary, props.oddsType)
	// console.log(data)

    const chartWidth = window.innerWidth * 0.35;
    const chartHeight = window.innerWidth * 0.15;
    // const isFirst = props.oddsType !== "total"
    const isFirst = props.num === 0;
    const color = isFirst ? "#0077e0" /*"#6ae000"*/: "#ee1140";
    const isTotal = props.oddsType === "total";
    const yValues = data.map(d => d.y)

    let chartMax = Math.max(...yValues);
    let chartMin = Math.min(...yValues);
    const yRange = chartMax - chartMin;
    let tickStep = Math.ceil(2 * yRange / 5) / 2; // isTotal ? 1: 0.5; //  
    const tickValues = []
    let i = chartMin;
    while (i < chartMax+tickStep) {
    	tickValues.push(i);
    	i += tickStep;
    }
    chartMax = tickValues.slice(-1)[0];
    // for (let i = 0; i <= yRange; i += tickStep) {
    // 	tickValues.push(i+chartMin)
    // }
    // chartMax = 
    // if (yRange / tickStep > 4) {

    // }
    // y ticks should start with lowest value
    
    // console.log(chartMax - chartMin)
    // const yDiff = Math.round((1/tickStep) * (chartMax - chartMin),0)
    // const tickValues = [...Array(yDiff+1).keys()].map(y => y*tickStep + chartMin);
    const yBuffer = tickStep / 2;
    const yDomain = [chartMin-yBuffer,chartMax+yBuffer]
    console.log(tickValues)
    console.log(yValues)
    console.log(chartMax)
    console.log(yRange)
    console.log(yRange / 4)
    console.log(yDomain)
    // console.log("ticks", tickValues)
    // console.log(data.map(x => x.y))
    // console.log(chartMin)
    // console.log(chartMax)
    // console.log(tickValues)
    // console.log(data.slice(-1))
    // const color = "#6ae000";

    const capitalize = str => str[0].toUpperCase() + str.slice(1);

	return <div className="oddsboard-chart-single">
		<div className="oddsboard-plot-header">{capitalize(props.oddsType)}</div>
		<XYPlot className="oddschart" margin={{left:50}} width={chartWidth} height={chartHeight} xDomain={[0,data.slice(-1)[0].x]} yDomain={yDomain}>
			
			{/*<DiscreteColorLegend
	            style={{position: 'absolute', left: '50px', top: '10px', strokeWidth: 20}}
	            orientation="horizontal"
	            items={[
	              {
	                title: 'Spread',
	                color: '#6ae000',
	              },
	            ]}
            />*/}
    		<XAxis tickSize={0} style={{
				line: {stroke: 'white'},
				ticks: {stroke: 'white'},
				text: {stroke: 'none', fill: 'white', fontSize: "1em", opacity: 0}
			}}/>
      		<YAxis tickValues={tickValues} style={{
				line: {stroke: 'white'},
				ticks: {stroke: 'white'},
				text: {stroke: 'none', fill: 'white', fontSize: "1em"}
			}}/>
			<AreaSeries data={data} style={{
				fill: color,
				opacity: 0.6,
			}}/>
	        <LineSeries data={data} curve={"linear"} markStyle={{fill: color}} style={{
	        	stroke: color,
	        	strokeWidth: 6,
	        	fill: "none",
	        }} />
	        <LabelSeries data={data.slice(-1)} curve={"linear"} style={{
	        	stroke: "white",
	        	strokeWidth: 1,
	        }} />
	    </XYPlot>
	</div>

}


class OddsBoardLogin extends Component {


	login = async e => {
		// 	this.setPending(e);
		e.preventDefault();

		// set access key in redux
		// save to cookie
		//    - check cookie to see if user already logged in
		// make first call to games endpoint
		// 	  - check remaining calls for the day -> if low, halt usage for day
		//    - make multiple calls as necessary to get all games
		// if successful:
		//    - respond to sportspage server to set user info, hash RapidAPI key, etc.
		//    - pull odds data
		// navigate to odds schedule page and begin to cycle through leagues with games

		// document.getElementById("login-error").classList.remove("show");
		const rapidKey = document.getElementById("rapid-api-user-key").value;
		const keyHash = crypto.createHash('sha256').update(rapidKey).digest('hex');
		let response;
		try {
			response = await this.props.gameDataUpdate(rapidKey, true);
		} catch(err) {
			const errMessage = `Invalid access key.  Please see the instructions below or contact support to get access.`;
			document.getElementById("login-error").innerHTML = errMessage;
			document.getElementById("login-error").classList.add("show");
			return
		}

		if (["BASIC"].includes(response.planType)) {
			console.log("setting")
			document.getElementById("login-error").innerHTML = "Odds Board requires Ultra plan or higher";
			document.getElementById("login-error").classList.add("show");
		} else {
			// go to odds
			const saveKey = document.getElementById("form-save-login").checked;
			if (saveKey) {
				Cookies.set("apiKey", rapidKey, { expires: 30 })
			} else {
				Cookies.remove("apiKey");
			}

			this.props.setKey(rapidKey, () => {
				this.props.updateMode("games", response)
			});
		}

	}

	clearCookie = () => {
		Cookies.remove("apiKey");
		document.getElementById("rapid-api-user-key").value = "";
		document.getElementById("form-save-login").checked = false;
	}

	setPending = (e) => {
		e.preventDefault();
		// this.setState({pending: true})
	}

	componentDidMount = () => {
		const apiKey = Cookies.get("apiKey")
		if (!!apiKey) {
			document.getElementById("rapid-api-user-key").value = apiKey;
			document.getElementById("form-save-login").checked = true;
		}
	}
	

	render() {

		const errClass = this.props.loginError ? "show": "";
		const apiKey = Cookies.get("apiKey");
		const loggedIn = !!Cookies.get("apiKey");
		const cookie = document.cookie;
		// console.log(loggedIn)
		// console.log(apiKey)


		return <div className="oddsboard-login">
			<div className="oddsboard-login-header">Sportspage Feeds Odds Board</div>
			<form>
				<label for="rapid-key">Enter RapidAPI key</label><br></br>
				<div className="oddsboard-password-holder">
					<input type="password" id="rapid-api-user-key"></input>
					{loggedIn && <div onClick={this.clearCookie}>Logout</div>}
				</div>
				<input type="submit" onClick={this.login} value="Submit"></input>
				{/*<input type="submit" onClick={this.logout} value="Logout"></input>*/}
				<div className="oddsboard-login-form-lower">
					<label id="login-error" className={errClass}>Invalid access key</label>
					<div className="checkbox-holder">
						<label for="checkbox" className="" >Stay logged in?</label>
						<input type="checkbox" id="form-save-login" value="save-login"></input>
					</div>
				</div>
			</form>
			<OddsBoardLoginInstructions />
			<Contact title="Have Questions?" />
		</div>
	}
}


const OddsBoardLoginInstructions = props => {
	return <div className="oddsboard-login-instructions">
		<div className="oddsboard-instructions-section-header">How to get started</div>
		<OddsBoardInstructionsSection num={1} header={"Get a data plan"}>
			Sportspage Feeds uses a third-party user management service, RapidAPI, to handle signups, payments, etc.  
			To sign up for a data plan, please visit the following page:  
			<a href="https://rapidapi.com/SportspageFeeds/api/sportspage-feeds/pricing" target="_blank">Sportspage Feeds - RapidAPI</a>.
			<br/><br/>
			To access the Odds Board, you must sign up for either the ULTRA or MEGA plan through RapidAPI.
		</OddsBoardInstructionsSection>
		<OddsBoardInstructionsSection num={2} header={"Get your access key"}>
			Once you have signed up for a data plan, you will be assigned an access key, which can be found under 
			<span>Header Parameters > X-Rapid-API-Key</span>
			<a href="https://rapidapi.com/SportspageFeeds/api/sportspage-feeds" target="_blank">here</a>.   
		</OddsBoardInstructionsSection>
		<OddsBoardInstructionsSection num={3} header={"Get started"}>
			Now that you have your key, enter it above to start viewing schedules, scores, and odds for the day.
			<br/><br/>
			You will not need to enter your access key every time you use the Odds Board, however, you can always retrieve your 
			key by following Step #2 above.
		</OddsBoardInstructionsSection>
		<div className="oddsboard-data-reminder">
			<div>Reminder:</div>
			<div>Your data subscription has usage limitations.  The Oddsboard will refresh at a rate that is consistent with your data plan, 
				however you should: 1) refrain from repeatedly logging in and  
				2) only use the Oddsboard on one computer at a time.
			</div>
			{/*<ul>
				<li>Only use the Oddsboard on one computer at a time</li>
				<li>Do not repeatedly refresh</li>
			</ul>*/}
		</div>
		{/*<ol>
			<li>
				Obtain an access key from RapidAPI.  If you have already signed up for a plan, your key can be found under 
				<span>Header Parameters > X-Rapid-API-Key</span>
				<a href="https://rapidapi.com/SportspageFeeds/api/sportspage-feeds">here</a>.  
				If you need to sign up for a plan, you can choose one on the Sportspage Feeds RapidAPI 
				<a href="https://rapidapi.com/SportspageFeeds/api/sportspage-feeds/pricing">signup page</a>.
				<br/><br/>
				The Odds Board is intended to work with the Ultra plan or higher and will not work with the Basic plan.
			</li>
			<li>
				Enter your access key in the box above and hit "Submit".  The Odds Board will load and you will start receiving 
				scores and odds for the day.
			</li>
		</ol>*/}
	</div>
}

const OddsBoardInstructionsSection = props => {
	return <div className="oddsboard-instructions-section">
		<div className="oddsboard-instructions-num">{props.num + "."}</div>
		<div className="oddsboard-instructions-section-body">
			<div>{props.header}</div>
			{props.children}
		</div>
	</div>
}


const Promo = props => {
	return <div className="oddsboard-promo">
		<div>Compare odds for major sportsbooks</div>
		<img src={oddsCompare}></img>
		<div>visit</div>
		<div>SportspageOdds.com</div>
		<img src={spLogo}></img>
	</div>
}

const DemoHeader = props => {
	return <div className="oddsboard-demo-header">
		<div>DEMO</div>
		<div>11-26-2020</div>
		<a className="oddsboard-demo-login-return" href="/odds-board">Return to Login</a>
	</div>
}

const DemoControls = props => {
	return <div className="oddsboard-demo-controls">
		<div className="demo-buttons">
			<i className="fast backward icon"></i>
			{props.playing ? 
				<i className="pause icon"></i>:
				<i className="play icon"></i>
			}
			<i className="fast forward icon"></i>
		</div>
		<a className="oddsboard-demo-login-return" href="/odds-board">Return to Login</a>
		{/*<button className="demo-play">Return to Login</button>*/}
	</div>
}


const PoweredBy = props => {
	return <div className="oddsboard-logo">
		<img src={spLogo}></img>
		<div>
			<div>Powered by</div>
			<div>SportspageFeeds.com</div>
		</div>
	</div>
}







