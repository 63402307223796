import React from 'react';
import { connect } from 'react-redux';
import { setModalType } from '../../actions';
import Contact from '../Contact/Contact';
import './ContactPage.css';
import titanLogo from '../../images/odds-titan-logo.png';


export default props => {

  return <div className="contact-page">
  	{/*<div className="contact-page-go-home" onClick={() => props.history.push('/')}>
  		HOME
  	</div>*/}
  	<div className="titan-odds-logo" onClick={() => props.history.push('/')}>
		<img src={titanLogo} />
	</div>
    <Contact title="Contact" hideBusiness={true} history={props.history} />
    <p>Have a question?  Let us know.</p>
  </div>
}