
const crypto = require('crypto');



Date.prototype.getDayStart = function() {
    let target = this;
    return new Date(new Date(target).setHours(0,0,0,0));
};
Date.prototype.getNextDayStart = function() {
    let target = this;
    return new Date(new Date(target).setDate(target.getDate() + 1)).getDayStart();
};
Date.prototype.getPriorDayStart = function() {
    let target = this;
    return new Date(new Date(target).setDate(target.getDate() - 1)).getDayStart();
};
Date.prototype.simpleStringDate = function() {
    let target = this;
    return target.getDayStart().toISOString().substring(0,10);
};
Date.prototype.simpleStringDateLocal = function() {
    let target = this;
    let month = target.getMonth()+1;
    let date = target.getDate();
    const toStr = d => d < 10 ? `0${d}`: d;

    return `${target.getFullYear()}-${toStr(month)}-${toStr(date)}`;
};
Date.prototype.addTimezoneOffset = function() {
    let target = this;
    return new Date(target.getTime() + target.getTimezoneOffset()*Date.min);
};
Date.prototype.getFractionalHours = function() {
    let target = this;
    const dayStart = target.getDayStart();
    const ms = target - dayStart;

    return ms / Date.hour;
};


Object.defineProperty(Date, "sec", { value: 1000 });
Object.defineProperty(Date, "min", { value: Date.sec*60 });
Object.defineProperty(Date, "hour", { value: Date.min*60 });
Object.defineProperty(Date, "hr", { value: Date.hour });
Object.defineProperty(Date, "day", { value: Date.hour*24 });


const teamTranslatorOld = (team, isCollege) => {
    let baseTeam;
    if (isCollege) {
        return team.location;
    } else {
        baseTeam = `${team.abbreviation} ${team.mascot}`;
    }

    switch(baseTeam) {
        // NFL
        case "LAC Chargers": return "LA Chargers";
        case "LAR Rams": return "LA Rams";
        case "NYG Giants": return "NY Giants";
        case "NYJ Jets": return "NY Jets";
        // MLB
        case "LAA Angels": return "LA Angels";
        case "LAD Dodgers": return "LA Dodgers";
        case "NYM Mets": return "NY Mets";
        case "NYY Yankees": return "NY Yankees";
        case "CHC Cubs": return "CHI Cubs";
        case "CWS White Sox": return "CHI White Sox";
        // NBA
        case "LAL Lakers": return "LA Lakers";
        case "LAC Clippers": return "LA Clippers";
        case "NYK Knicks": return "NY Knicks";
        case "GSW Warriors": return "GS Warriors";
        case "NOP Pelicans": return "NO Pelicans";
        case "SAS Spurs": return "SA Spurs";
        // NHL
        case "NYI Islanders": return "NY Islanders";
        case "NYR Rangers": return "NY Rangers";
        case "NJD Devils": return "NJ Devils";

        // case "CBJ Blue Jackets": return "CB Blue Jackets";
        case "LAK Kings": return "LA Kings";
        case "SJS Sharks": return "SJ Sharks";
        case "TBL Lightning": return "TB Lightning";
        case "VGK Golden Knights": return "VGS Golden Knights";
        default: return baseTeam;
    }
}

const teamTranslator = (team, league) => {

    switch(league) {
        case "NFL":
            switch(team.location) {
                case "Los Angeles": return `LA ${team.mascot}`;
                case "New York": return `NY ${team.mascot}`;
                default: return team.location;
            }
        case "NBA":
            switch(team.location) {
                case "Los Angeles": return `LA ${team.mascot}`;
                default: return team.location;
            }
        case "MLB":
            switch(team.location) {
                case "Los Angeles": return `LA ${team.mascot}`;
                case "New York": return `NY ${team.mascot}`;
                case "Chicago": return `Chi. ${team.mascot}`;
                default: return team.location;
            }
        case "NHL":
            switch(team.location) {
                case "New York": return `NY ${team.mascot}`;
                default: return team.location;
            }
        default: return team.location;
    }
}


function formatOdds(odds,type) {
    if (type == "total") {
        return odds
    } else {
        if (odds === 0) return "EVEN";
        if (odds < 0) return odds + "";
        return "+" + odds;
    }
}


// function formatOdds(odds,type,league) {
//     if (type == "total") {
//         return odds[0].total.current.total
//     } else {
//         const useML = ["MLB","NHL"].includes(league);
//         const lineType = useML ? "moneyline": "spread";
//         const oddsType = useML ? "homeOdds": "home"
//         const value = odds[0][lineType].current[oddsType];
//         if (value == 0) return "EVEN"
//         if (value < 0) return value + "";
//         return "+" + value
//     }
// }

const algorithm = 'aes-256-ctr';
const secretKey = "pZhYmucincgJemuoOEUccMRgokcZ5x9t" // crypto.randomBytes(32).toString('base64').slice(0,32);
// const secretKey = "https://sportspageodds.com/books"
const randomHex = "b2c41dafcc265dd5683588ac8f5b1f23" // Buffer.from(iv.toString('hex'), 'hex')
// const iv = crypto.randomBytes(16);

const encrypt = (text) => {
    // https://attacomsian.com/blog/nodejs-encrypt-decrypt-data
    const iv = Buffer.from(randomHex, 'hex');
    const cipher = crypto.createCipheriv(algorithm, secretKey, iv);
    const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);
    return encrypted.toString('hex');
    // return {
    //     iv: iv.toString('hex'),
    //     content: encrypted.toString('hex')
    // };
};

const decrypt = (hash) => {
    const iv = Buffer.from(randomHex, 'hex');
    const decipher = crypto.createDecipheriv(algorithm, secretKey, iv);
    const decrpyted = Buffer.concat([decipher.update(Buffer.from(hash, 'hex')), decipher.final()]);
    return decrpyted.toString();
};


function pause(t, unit=1) {
    return new Promise(resolve => setTimeout(resolve, t*unit))
}


export default {
    subscriptionTypes: {
        basic: {
            name: "Basic",
            price: 29,
            index: 1,
            rpm: 3000,
            rps: 50
        },
        standard: {
            name: "Standard",
            price: 69,
            index: 2,
            rpm: 100000,
            rps: 50
        },
        premium: {
            name: "Premium",
            price: 119,
            index: 3,
            rpm: 1000000,
            rps: 50
        },
    },
    signupLocked: process.env.REACT_APP_SIGNUP_LOCKED === "true",
    noStandings: process.env.REACT_APP_STANDINGS !== "true",
    rapidOnly: true, // process.env.REACT_APP_RAPID_ONLY === "true"
    // mailTo: "mailto:sportspagefeeds@gmail.com"
    decrypt,
    teamTranslator,
    formatOdds,
    pause
}
