import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Line } from 'react-chartjs-2';
import tablet from '../../images/tablet.png';
import { setModalType } from '../../actions';
import './Home.css';



const yOptions = {
  ticks: {
    id: 'left-y-axis',
    stepSize: 0.5,
    type: "linear",
    position: "left"
  }
}
const yOptionsRight = JSON.parse(JSON.stringify(yOptions));
yOptionsRight.ticks.position = 'right-y-axis';
yOptionsRight.ticks.position = "right";

const chartOptions = {
  legend: {
      display: true,
      labels: {
        usePointStyle: true,
        boxWidth: 5,
        fill: false,
      }  
  },
  scales: {
    xAxes: [{
      type: 'time',
      time: {
          distribution: "series",
          unit: "hour"
      }
    }],
    yAxes: [ yOptions, yOptionsRight ]
  }
}

const lineData = [
  {
      x: Date.now() - 1000 * 60 * 60 * 20,
      y: 3
  }, {
      x: Date.now() - 1000 * 60 * 60 * 4,
      y: 4
  }, {
      x: Date.now() - 1000 * 60 * 60 * 3,
      y: 3.5
  }, {
      x: Date.now() - 1000 * 60 * 60 * 2,
      y: 4
  }, {
      x: Date.now() - 1000 * 60 * 60,
      y: 4.5
  }, {
      x: Date.now(),
      y: 5
  }
]
const lineData_2 = JSON.parse(JSON.stringify(lineData)).map(item => ({
  x: item.x,
  y: -item.y
}))

const baseDataset = {
    label: "Line",
    fill: false,
    backgroundColor: 'rgb(255,255,255,0)',
    pointBackgroundColor: '#c97922',
    borderColor: '#c97922',
    // steppedLine: true,
    pointStyle: 'rect',
    pointRadius: 5,
    pointBorderWidth: 2,
    data: [],
    yAxisID: 'left-y-axis'
}

const lineTemplate = {
  type: "line",
  data: {
      datasets: [{
      ...baseDataset,
      data: lineData,
      yAxisID: 'left-y-axis'
  }, {
      ...baseDataset,
      data: lineData_2,
      yAxisID: 'right-y-axis'
  }]
  },
  // height: 70,
  options: chartOptions
}




export default class Home extends Component {


  render() {

    const games = [
      {
        date: 'January 1, 2019 5:00PM',
        venue: "Rose Bowl",
        location: "Pasadena, CA",
        away: {
          team: "Washington",
          mascot: "Huskies",
          img: "https://a.espncdn.com/i/teamlogos/ncaa/500/264.png",
          score: 23,
          record: "10-4",
          spread: "+4.5"
        },
        home: {
          team: "Ohio State",
          mascot: "Buckeyes",
          img: "https://a.espncdn.com/i/teamlogos/ncaa/500/194.png",
          score: 28,
          record: "13-1",
          spread: "-4.5"
        },
        total: 58.5,
      },
      {
        date: 'January 1, 2019 8:45PM',
        venue: "Mercedes-Benz Superdome",
        location: "New Orleans, LA",
        away: {
          team: "Texas",
          mascot: "Longhorns",
          img: "https://a.espncdn.com/i/teamlogos/ncaa/500/251.png",
          score: 28,
          record: "9-4",
          spread: "+13.5"
        },
        home: {
          team: "Georgia",
          mascot: "Bulldogs",
          img: "https://a.espncdn.com/i/teamlogos/ncaa/500/61.png",
          score: 21,
          record: "11-2",
          spread: "-13.5"
        },
        total: 60.0,
      },
      {
        date: 'January 7, 2019 8:00PM',
        venue: "Levi's Stadium",
        location: "Santa Clara, CA",
        away: {
          team: "Clemson",
          mascot: "Tigers",
          img: "https://a.espncdn.com/i/teamlogos/ncaa/500/228.png",
          score: 44,
          record: "15-0",
          spread: "+6.0"
        },
        home: {
          team: "Alabama",
          mascot: "Crimson Tide",
          img: "https://a.espncdn.com/i/teamlogos/ncaa/500/333.png",
          score: 16,
          record: "14-1",
          spread: "-6.0"
        },
        total: 57.5,
      }
    ]


    return (
      <div className="home">
        <div className="home-banner">
          <div className="home-banner-inner"></div>
          <div className="banner-content loaded">
            <div className="home-banner-left-holder">
              <div className="home-banner-left">AFFORDABLE</div>
              <div className="home-banner-left">SPORTS DATA,</div>
              <div className="home-banner-left">PERIOD.</div>
            </div>
            <div className="home-banner-right-holder">
              <div className="home-banner-right">We provide essential, robust sports data at the best value on the market.</div>
              <button onClick={() => this.props.history.push("/pricing")}>
                View Plans
              </button>
            </div>
            
          </div>
        </div>
        <WhySportsfeed />
        <div className="home-inner">
          <div className="home-section">
            <div className="home-section-background">
              <div className="home-section-background-left"></div>
              {/*<div className="home-section-background-right"></div>*/}
            </div>
            <div className="home-section-foreground">
              <HomeDetails 
                header="Build Custom Widgets"
                body="Need to provide game information to your users?  We provide all high level schedule, score, team, and venue data, which can be used for a wide variety of custom widgets on your web pages and apps."
                buttonText="Pricing"
                to="/pricing"
                history={this.props.history}
              />
              <div className="widgets-holder">
                <ScoreWidget game={games[2]} />
              </div>
              {/*<div className="widgets-holder">
                <ScoreWidget game={games[0]} />
                <ScoreWidget game={games[1]} shouldHide />
                
              </div>*/}
            </div>
          </div>
          
          <div className="home-section">
            <div className="home-section-background">
              <div className="home-section-background-left"></div>
              {/*<div className="home-section-background-right yellow"></div>*/}
            </div>
            <div className="home-section-foreground lower">
              <HomeDetails 
                header="Track Odds Movements" 
                body="All plans come with opening and current betting lines and odds for games where public odds are available.  With the Standard and Premium plans, you can track full odds histories that include all line and odds movements."
                buttonText="Pricing"
                to="/pricing"
                history={this.props.history}
              />
              <img className="tablet" alt="spread chart" src={tablet} />
            </div>
          </div>
          
           
          <div className="home-superfooter">

            <HomeDetails 
              header="Need a Custom Solution?" 
              body="We can provide high-volume access or help you integrate our data for whatever your needs.  Send us an email and we'll help you get started today."
              buttonText="Pricing"
              to="/pricing"
              history={this.props.history}
              setModalType={this.props.setModalType}
              isSuper
            />
            {/*<div>Need a Custom Solution?</div>
            <div>We can provide high-volume access or help you integrate our data for whatever your needs.  Send us an email and we'll help you get started today.</div>
            */}
          </div>
        </div>
        <div></div>
      </div>
        
    )
  }
}

const Chart = () => (
  <div className="home-line-chart-holder">
    <div className="home-line-chart">
      <div className="home-chart-top">
        <div className="home-chart-header">New England Patriots vs Los Angeles Rams</div>
        <div className="home-chart-subheader">3/13/2019 - 12:00PM</div>
      </div>
      <Line 
        
        data={{
          //labels: [4, 5, 6, 7, 8, 9, 10],
          datasets: [{
            label: "Spread - New England",
            backgroundColor: 'rgb(0,0,0,0)',
            pointBackgroundColor: "#39678f",//'#c97922',//'#e02841',  
            borderColor: "#39678f",//'#c97922',,
            // steppedLine: true,
            pointStyle: 'rect',
            pointRadius: 5,
            pointBorderWidth: 2,
            data: [
              {
                x: new Date(2019,0,21,0).getTime(),
                y: -1.5
              }, {
                x: new Date(2019,0,21,10).getTime(),
                y: -2
              }, {
                x: new Date(2019,0,22,12).getTime(),
                y: -2.5
              }, {
                x: new Date(2019,0,23,12).getTime(),
                y: -2.5
              }, {
                x: new Date(2019,0,25,12).getTime(),
                y: -3
              }, {
                x: new Date(2019,0,26,12).getTime(),
                y: -2.5
              }, {
                x: new Date(2019,0,27,12).getTime(),
                y: -2.5
              }, {
                x: new Date(2019,0,28,12).getTime(),
                y: -2.5
              }, {
                x: new Date(2019,0,29,12).getTime(),
                y: -2.5
              }, {
                x: new Date(2019,0,30,12).getTime(),
                y: -2
              }, {
                x: new Date(2019,0,31,12).getTime(),
                y: -2.5
              }, {
                x: new Date(2019,1,1,12).getTime(),
                y: -2.5
              }, {
                x: new Date(2019,1,2,12).getTime(),
                y: -2.5
              }, {
                x: new Date(2019,1,3,12).getTime(),
                y: -2.5
              }

            ]
          }]
        }}
        //width={50}
        height={170}
        options={{
          legend: {
            display: true,
            labels: {
              usePointStyle: true,
              boxWidth: 5,
            }
            
          },
          scales: {
            xAxes: [{
              type: 'time',
              time: {
                distribution: "series",
                unit: "day"
              }
            }],
            yAxes: [{
              stepSize: 1
            }]
          }
        }}
      />
    </div>
  </div>
)



export const WhySportsfeed = props => (

  <div className="why-sportsfeed-outer">
    <div className="why-sportsfeed-holder">
      <WhySportsfeedItem text="Top US Leagues Supported" icon={"football ball"} content={`Get data for top US professional sports leagues 
        (NFL, NBA, MLB, and NHL) and college football and basketball`}/>
      <WhySportsfeedItem text="Scores, Schedules, and Betting Odds" icon={"tv"} content={`Essential sports data for betting analytics, general sports
        applications, and beyond`}/>
      <WhySportsfeedItem text="Industry-Leading Affordability" icon={"dollar sign"} content={`Cost-effective plans to get small projects up and running or 
        power higher volume applications`}/>
    </div>
  </div>
)

const WhySportsfeedItem = (props) => (

  <div className="why-sportsfeed">
    <div className="why-sportsfeed-inner">
      <i className={`${props.icon} icon`}></i>
    </div>
    <div className="why-sportsfeed-text">{props.text}</div> 
    <div className="why-sportsfeed-content">{props.content}</div> 
  </div>
)

const ScoreWidget = (props) => (

  

  <div className={`score-widget ${props.shouldHide ? " squeeze": ""}`}>
    <div className="score-widget-header">
      <div className="score-widget-header-date">{props.game.date}</div>
      <div className="score-widget-header-venue">{props.game.venue}, {props.game.location}</div>
    {/*<div className="score-widget-header-venue">{props.game.location}</div>*/}
    </div>
    <div className="score-widget-matchup">
      <div className="score-widget-team">
        <div className="score-widget-team-left">
          <img alt="team logo" src={props.game.away.img} />
          <div>
            <div className="score-widget-team-name">{props.game.away.team}<span>{props.game.away.record}</span></div>
            <div className="score-widget-team-mascot">{props.game.away.mascot}</div>
            <div className="score-widget-team-odds">{props.game.away.spread}</div>
          </div>
        </div>
        <div className="score-widget-team-score">{props.game.away.score}</div>
      </div>
      <div className="score-widget-team">
        <div className="score-widget-team-left">
          <img alt="team logo" src={props.game.home.img} />
          <div>
            <div className="score-widget-team-name">{props.game.home.team}<span>{props.game.home.record}</span></div>
            <div className="score-widget-team-mascot">{props.game.home.mascot}</div>
            <div className="score-widget-team-odds">{props.game.home.spread}</div>
          </div>
        </div>
        <div className="score-widget-team-score">{props.game.home.score}</div>
      </div>
      <div className="score-widget-total">Total O/U: {props.game.total}</div>
    </div>
    <div className="score-widget-footer">
      <img alt="ncaaf logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/dd/NCAA_logo.svg/100px-NCAA_logo.svg.png" />
      <div className="score-widget-footer-status">Final</div>
      
    </div>
  </div>
)


const mapActionsToProps = {
  setModalType: setModalType
}

const HomeDetails = connect(() => ({}), mapActionsToProps)(props => {

  const goToPricing = () => props.history.push(props.to);
  const sendMessage = () => props.setModalType("contact");
  const onClick = props.isSuper ? sendMessage: goToPricing;
  const buttonText = props.isSuper ? "CONTACT": "VIEW PRICING"
  return (
    <div className="home-details">
      <div className="details-header">{props.header}</div>
      <div className="details-body">{props.body}</div>
      {props.hasLink && <div className="home-contact-link" onClick={() => props.setModalType("contact")} >Contact</div>}
      <button onClick={onClick}>
        {buttonText}
      </button>
    </div>
  )
})






